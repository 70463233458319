import { KEY_USER, KEY_TOKEN } from '../localStorageKeys'

export default function () {
  return {
    appleUser: null,
    authType: localStorage.getItem('authType') || 'phone',
    facebookUser: null,
    freeCallUser: null,
    googleUser: null,
    socialRegistering: false,
    user: localStorage.getItem(KEY_USER) ? JSON.parse(localStorage.getItem(KEY_USER)) : null,
    tempUser: null,
    tempUser2: null,
    token: localStorage.getItem(KEY_TOKEN) ? localStorage.getItem(KEY_TOKEN) : null,
  }
}
