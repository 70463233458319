import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from "@/auth/jwt/useJwt";
import { initialAbility } from '@/libs/acl/config';
import i18n from '../libs/i18n'

/**
 * Gelen string'in ilk 2 karakterini döndürür
 * @param str
 * @returns {string}
 */
export const getFirstTwoChars = str => {
  return typeof str === 'string' ? str.substring(0, 2) : ''
}

/**
 * Gelen string'in ilk karakterini döndürür
 * @param str
 * @returns {string}
 */
export const getFirstChar = str => {
  return typeof str === 'string' ? str.substring(0, 1) : ''
}

/**
 * Convert string to slug
 * @param string
 * @returns {string}
 */
export const slugify = (string) => {
  const trMap = {
    'çÇ': 'c', // eslint-disable-line
    'ğĞ': 'g', // eslint-disable-line
    'şŞ': 's', // eslint-disable-line
    'üÜ': 'u', // eslint-disable-line
    'ıİ': 'i', // eslint-disable-line
    'öÖ': 'o' // eslint-disable-line
  }
  for (const key in trMap) {
    string = string.replace(new RegExp('[' + key + ']', 'g'), trMap[key])
  }
  return string.replace(/[^-a-zA-Z0-9.\s]+/ig, '') // remove non-alphanumeric chars
    .replace(/\s/gi, '-') // convert spaces to dashes
    .replace(/[-]+/gi, '-') // trim repeated dashes
    .toLowerCase()
}

/**
 * @param almostJson
 * @returns {any}
 */
const jsonify = (almostJson) => {
  try {
    return JSON.parse(almostJson)
  } catch (e) {
    almostJson = almostJson.replace(/([a-zA-Z0-9_$]+\s*):/g, '"$1":').replace(/'([^']+?)'([\s,\]\}])/g, '"$1"$2') // eslint-disable-line
    return JSON.parse(almostJson)
  }
}

/**
 * @type {{"[": string, "{": string}}
 */
const chars = {
  '[': ']',
  '{': '}'
}

/**
 * @param iteree
 * @param iterator
 * @returns {*}
 */
const any = (iteree, iterator) => {
  let result
  for (let i = 0; i < iteree.length; i++) {
    result = iterator(iteree[i], i, iteree)
    if (result) {
      break
    }
  }
  return result
}

/**
 * @param str
 * @returns {string|null}
 */
const extract = (str) => {
  if (typeof str !== 'string') { return null }

  const startIndex = str.search(/[\{\[]/) // eslint-disable-line

  if (startIndex === -1) { return null }

  const openingChar = str[startIndex]
  const closingChar = chars[openingChar]
  let endIndex = -1
  let count = 0

  str = str.substring(startIndex)
  any(str, (letter, i) => {
    if (letter === openingChar) {
      count++
    } else if (letter === closingChar) {
      count--
    }

    if (!count) {
      endIndex = i
      return true
    }
  })

  if (endIndex === -1) {
    return null
  }

  const obj = str.substring(0, endIndex + 1)
  return obj
}

/**
 * @param str
 * @returns {{string: *, objects: (*|*[])}}
 */
export const extractJSON = (str) => {
  if (typeof str !== 'string') { return { objects: [], string: '' } }

  let result
  const objects = []

  while ((result = extract(str)) !== null) {
    try {
      const obj = jsonify(result)
      objects.push(obj)
    } catch (e) {
      // Do nothing
    }
    str = str.replace(result, '')
  }

  const string = str.replace(JSON.stringify(objects), '')

  return {
    objects: Array.isArray(objects) ? objects[0] : [],
    string
  }
  /**
   * Default hata mesajını gösterir
   * @param toast
   * @param error
   * @param _t
   */
}

export const throwDefaultError = (toast, error = null) => {
  // console.log('_t', _t)
  // console.log('_t(\'There was an unexpected error\')', _t('There was an unexpected error'))
  let message = error?.response?.data?.header?.error?.message || i18n.t('There was an unexpected error')
  const invalidTokenMessage = i18n.t('Your session has ended. Please login again.')

  if (message === 'invalid login-token') {
    message = invalidTokenMessage
  }

  if (localStorage.getItem('showMessage') !== '0') {

    toast({
      component: ToastificationContent,
      props: {
        title: message,
        variant: 'danger',
      },
    })

    if (message === invalidTokenMessage) {
      localStorage.setItem('showMessage', '0')

      setTimeout(() => {
        localStorage.setItem('showMessage', '1')
      }, 2000)
    }
  }
}

/**
 * Default hata mesajını gösterir
 * @param toast
 * @param error
 * @param _t
 */
export const throwPaymentError = (toast, _t, error = null) => {
  let message = error?.response?.data?.body?.responseMessage || i18n.t('There was an unexpected error')

  toast({
    component: ToastificationContent,
    props: {
      title: message,
      variant: 'danger',
    },
  })
}

/**
 * Positive hata mesajını gösterir
 * @param toast
 * @param message
 * @param type
 */
export const showNotify = (toast, message, type = 'danger') => {
  toast({
    component: ToastificationContent,
    props: {
      title: message,
      variant: type,
    },
  })
}

/**
 * GetRandomColor
 * @returns {string}
 */
export const getRandomColor = (letters = '0123456789ABCDEF') => {
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

// export const getEnv = (key, defaultValue = null) => {
//   try {
//     const ENVIRONMENT = require('../../env.json')
//
//     return typeof ENVIRONMENT[key] !== 'undefined' ? ENVIRONMENT[key] : defaultValue
//   } catch (e) {
//     return defaultValue
//   }
// }

/**
 * Çıkış yapma
 * @param ability
 * @param router
 * @param store
 */
export const logout = (ability, router, store = null) => {
  useJwt.postLogout()
    .finally(() => {
      // Remove user from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      if (store) {
        store.dispatch('auth/logout')
      }

      // Remove user from localStorage
      localStorage.removeItem('user')

      // Reset ability
      ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login' })
    })
}

/**
 * GTM Track New Event
 * @param event
 * @param data
 */
export const gtmTrackNewEvent = (event, data) => {
  if (!window.dataLayer)
    window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event,
    ...data,
    noninteraction: true,
  })
}

/**
 * GTM Push User ID
 * @param id
 */
export const gtmPushUserId = id => {
  if (!window.dataLayer)
    window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    user_id: id,
  })
}

export const settingsRegister = (token = null, playerId = null) => {
  useJwt.settingsRegister({
    deviceToken: token,
    playerId: playerId,
  })
      .then(response => {
        console.log('settingsRegister', response)
      })
      .catch(error => {
        console.log('error settingsRegister', error)
      })
}

/**
 * Türkçe kararkterleri ingilizce karaktere çevirir
 * @returns {*}
 */
export const convertTrChars = value => {
  return value.replaceAll('Ğ','G')
    .replaceAll('Ü','U')
    .replaceAll('Ş','S')
    .replaceAll('İ','I')
    .replaceAll('Ö','O')
    .replaceAll('Ç','C')
    .replaceAll('ğ','g')
    .replaceAll('ü','u')
    .replaceAll('ş','s')
    .replaceAll('ı','i')
    .replaceAll('ö','o')
    .replaceAll('ç','c');
};

/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
export const isValidIBANNumber = input => {
  const CODE_LENGTHS = {
    AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
    CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
    FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
    HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
    LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
    MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
    RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
    AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
    SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
  }
  let iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
    digits
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55
  })
  // final check
  return mod97(digits)
}

function mod97(string) {
  let checksum = string.slice(0, 2), fragment
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7)
    checksum = parseInt(fragment, 10) % 97
  }
  return checksum
}

export const numberFormat = value => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

/**
 * @param n
 * @returns {boolean}
 */
export const isInt = n => {
  return Number(n) === n && n % 1 === 0
}

/**
 * @param n
 * @returns {boolean}
 */
export const isFloat = n => {
  return Number(n) === n && n % 1 !== 0
}

/**
 * @param number
 * @returns {string}
 */
export const numberFormatter = number => {
  if (!isFloat(number) && !isInt(number)) {
    if (number instanceof String) {
      number = number.replace(',', '.')
    }
    number = parseFloat(number)
  }

  return new Intl.NumberFormat('tr-TR').format(number)
}

