import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { sha256 } from 'js-sha256'

// Routes
import {canNavigate} from '@/libs/acl/routeProtection'
import {isUserLoggedIn, getUser, getHomeRouteForLoggedInUser} from '@/auth/utils'
// import apps from './routes/apps'
// import dashboard from './routes/dashboard'
// import uiElements from './routes/ui-elements/index'
// import pages from './routes/pages'
// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
// import others from './routes/others'

import SettingsProfileInformation from '@/views/settings/components/ProfileInformation'
import SettingsUpdatePassword from '@/views/settings/components/UpdatePassword'
import SettingsNotifications from '@/views/settings/components/Notifications'
import SettingsInterests from '@/views/settings/components/Interests'
import SettingsFilters from '@/views/settings/components/Filters'
import SettingsAboutUs from '@/views/settings/components/AboutUs'
import SettingsContact from '@/views/settings/components/Contact'
import SettingsContracts from '@/views/settings/components/Contracts'
import SettingsRemoveAccount from '@/views/settings/components/RemoveAccount'
import SettingsSSS from '@/views/settings/components/SSS'
import SettingsCalling from '@/views/settings/components/Calling'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {path: '/kayit-ol', redirect: {name: 'auth-login'}},
        {path: '/kesfet', redirect: {name: 'new-users'}},
        {path: '/uye-arama', redirect: {name: 'search-users'}},
        {path: '/yeni-uyeler', redirect: {name: 'new-users'}},
        {path: '/populer-uyeler', redirect: {name: 'popular-users'}},
        {path: '/goruntulu-uyeler', redirect: {name: 'video-call-users'}},
        {path: '/sana-ozel', redirect: {name: 'for-you'}},
        {path: '/yakindakiler', redirect: {name: 'near-by'}},
        {path: '/bagli-kullanicilar', redirect: {name: 'online-users'}},
        {path: '/mesajlas/:userId?', redirect: {name: 'chat'}},
        {path: '/etkilesim', redirect: {name: 'interaction.likes'}},
        {path: '/aramalar', redirect: {name: 'calls.voice'}},
        {path: '/hediyelerim', redirect: {name: 'gifts'}},
        {path: '/satinalinanmedya', redirect: {name: 'buying-contents'}},
        {path: '/kazanimlar', redirect: {name: 'gains'}},
        {path: '/ayarlar', redirect: {name: 'settings'}},
        {path: '/ayarlar/profil-bilgileri', redirect: {name: 'settings.profile-information'}},
        {path: '/ayarlar/sifre-degistirme', redirect: {name: 'settings.update-password'}},
        {path: '/ayarlar/hakkimizda', redirect: {name: 'settings.about-us'}},
        {path: '/ayarlar/iletisim', redirect: {name: 'settings.contact'}},
        {path: '/ayarlar/uyelik-sozlesmeleri', redirect: {name: 'settings.contracts'}},
        {path: '/ayarlar/ilgi-alanlari', redirect: {name: 'settings.interests'}},
        {path: '/ayarlar/filtreleme', redirect: {name: 'settings.filters'}},
        {path: '/ayarlar/hesap-silme', redirect: {name: 'settings.remove-account'}},
        {path: '/ayarlar/sss', redirect: {name: 'settings.faq'}},
        {path: '/ayarlar/bildirimler', redirect: {name: 'settings.notifications'}},
        {path: '/ayarlar/aranma-ayarlari', redirect: {name: 'settings.calling'}},
        {path: '/profil/:id', redirect: {name: 'users.show'}},
        {path: '/profil', redirect: {name: 'profile.index'}},
        {path: '/uyeler', redirect: {name: 'near'}},
        {path: '/bakim-modu', redirect: {name: 'maintenance-mode'}},

        {path: '/', name: 'index'},
        {
            path: '/search',
            name: 'search-users',
            component: () => import('@/views/users/SearchUsers.vue'),
        },
        {
            path: '/new-users',
            name: 'new-users',
            component: () => import('@/views/users/NewUsers.vue'),
        },
        {
            path: '/popular-users',
            name: 'popular-users',
            component: () => import('@/views/users/PopularUsers.vue'),
        },
        {
            path: '/video-call-users',
            name: 'video-call-users',
            component: () => import('@/views/users/VideoCallUsers.vue'),
        },
        {
            path: '/for-you',
            name: 'for-you',
            component: () => import('@/views/users/ForYouUsers.vue'),
        },
        {
            path: '/nearby',
            name: 'near-by',
            component: () => import('@/views/users/NearByUsers.vue'),
        },
        {
            path: '/online-users',
            name: 'online-users',
            component: () => import('@/views/discover/OnlineUsers.vue'),
        },
        {
            path: '/chat/:userId?',
            name: 'chat',
            component: () => import('@/views/chat/Index.vue'),
            meta: {
                contentRenderer: 'sidebar-left',
                contentClass: 'chat-application',
            },
        },
        {
            path: '/payment/products/:productId',
            name: 'buy',
            component: () => import('@/views/market/Buy.vue'),
        },
        {
            path: '/payment/credit-card/finalize',
            name: 'payment.finalize',
            component: () => import('@/views/market/Finalize2.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/market',
            name: 'market',
            component: () => import('@/views/market/Index.vue'),
        },
        // {
        //     path: '/interaction',
        //     name: 'interaction',
        //     component: () => import('@/views/interaction/Index.vue'),
        // },
        {
            path: '/likes',
            name: 'interaction.likes',
            component: () => import('@/views/interaction/Likes.vue'),
        },
        {
            path: '/liked',
            name: 'interaction.liked',
            component: () => import('@/views/interaction/Liked.vue'),
        },
        {
            path: '/blocked',
            name: 'interaction.blocked',
            component: () => import('@/views/interaction/Blocked.vue'),
        },
        // {
        //     path: '/calls',
        //     name: 'calls',
        //     component: () => import('@/views/calls/Index.vue'),
        // },
        {
            path: '/voice-calls',
            name: 'calls.voice',
            component: () => import('@/views/calls/Voice.vue'),
        },
        {
            path: '/video-calls',
            name: 'calls.video',
            component: () => import('@/views/calls/Video.vue'),
        },
        {
            path: '/gifts',
            name: 'gifts',
            component: () => import('@/views/gifts/Index.vue'),
        },
        {
            path: '/purchased-media',
            name: 'buying-contents',
            component: () => import('@/views/buying-contents/Index.vue'),
        },
        {
            path: '/gains',
            name: 'gains',
            component: () => import('@/views/gains/Index.vue'),
        },
        {
            path: '/moments',
            name: 'moments',
            component: () => import('@/views/moments/Index.vue'),
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import('@/views/settings/Index.vue'),
            redirect: {name: 'settings.profile-information'},
            children: [
                {
                    path: 'profile-information',
                    name: 'settings.profile-information',
                    component: SettingsProfileInformation
                },
                {
                    path: 'update-password',
                    name: 'settings.update-password',
                    component: SettingsUpdatePassword
                },
                {
                    path: 'about-us',
                    name: 'settings.about-us',
                    component: SettingsAboutUs
                },
                {
                    path: 'contact',
                    name: 'settings.contact',
                    component: SettingsContact
                },
                {
                    path: 'membership-agreements',
                    name: 'settings.contracts',
                    component: SettingsContracts
                },
                {
                    path: 'interests',
                    name: 'settings.interests',
                    component: SettingsInterests
                },
                {
                    path: 'filters',
                    name: 'settings.filters',
                    component: SettingsFilters
                },
                {
                    path: 'remove-account',
                    name: 'settings.remove-account',
                    component: SettingsRemoveAccount
                },
                {
                    path: 'faq',
                    name: 'settings.faq',
                    component: SettingsSSS
                },
                {
                    path: 'notifications',
                    name: 'settings.notifications',
                    component: SettingsNotifications
                },
                {
                    path: 'calling',
                    name: 'settings.calling',
                    component: SettingsCalling
                }
            ],
        },
        {
            path: '/profile/:type?/:id',
            name: 'users.show',
            component: () => import('@/views/users/Show.vue'),
        },
        {
            path: '/profile',
            name: 'profile.index',
            component: () => import('@/views/profile/Index.vue'),
        },
        {
            path: '/login',
            name: 'auth-login',
            component: () => import('@/views/authentication/Login.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,
            },
        },
        {
            path: '/users',
            name: 'public-users',
            component: () => import('@/views/users/Public.vue'),
            meta: {
                layout: 'full-container',
                resource: 'Auth',
                redirectIfLoggedIn: true,
            },
        },
        {
            path: '/maintenance-mode',
            name: 'misc-under-maintenance',
            component: () => import('@/views/miscellaneous/UnderMaintenance.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/error',
            name: 'misc-error',
            component: () => import('@/views/miscellaneous/Error.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/not-authorized',
            name: 'misc-not-authorized',
            component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                action: 'read',
            },
        },
        {
            path: '/rive',
            name: 'rive',
            component: () => import('@/views/Rive.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

let tawkToOnLoaded = false

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()

    // if (!isLoggedIn) return next({ name: 'auth-login' })

    if (!isLoggedIn && to.name === 'index') {
        return next({ name: 'auth-login' })
    } else if (isLoggedIn && to.name === 'index') {
        return next({ name: 'new-users' })
    }

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn && to.name !== 'auth-login') {
            localStorage.setItem('loginRedirect', to.name)
            return next({name: 'auth-login', query: { lang: to.params.lang }})
        }

        // If logged in => not authorized
        return next({name: 'misc-not-authorized'})
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const user = getUser()
        next(getHomeRouteForLoggedInUser(user ? user.role : null))
        next({name: 'new-users', query: { lang: to.params.lang }})
    } else if (!to.meta.redirectIfLoggedIn && !isLoggedIn && to.name !== 'auth-login') {
        localStorage.setItem('loginRedirect', to.name)
        next({name: 'auth-login', query: { lang: to.params.lang }})
    }

    // Kullanıcı kazanımlar sayfasına gitmek istiyor ama gitmesine yetkisi yoksa
    if (to.name === 'gains' && store.getters['auth/userGender'] !== 'female' && !store.getters['auth/freeCallUser']) {
        next({name: 'new-users'})
    }

    // Kullanıcı yeni-uyeler sayfasına gitmek istiyor ama gitmesine yetkisi yoksa
    // if (to.name === 'new-users' && store.getters['auth/userGender'] !== 'female') {
    //     next({name: 'new-users'})
    // }

    // Kullanıcı bagli-uyeler sayfasına gitmek istiyor ama gitmesine yetkisi yoksa
    if (to.name === 'online-users' && store.getters['auth/userGender'] !== 'female') {
        next({name: 'new-users'})
    }

    // Kullanıcı market sayfasına gitmek istiyor ama gitmesine yetkisi yoksa
    if (to.name === 'market' && store.getters['auth/userGender'] === 'female') {
        next({name: 'new-users'})
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to) => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }

    const user = getUser()

    if (user) {

        if (tawkToOnLoaded) {

        } else {
            // window.Tawk_API.onLoad = function() {
            //     tawkToOnLoaded = true
            // };
        }
    }

    let tawkToData = {}
    if (user) {
        const tawkToApiKey = process.env.VUE_APP_TAWKTO_API_KEY || '58c155d1e79c1935a0df0a6af68767f732eb0fea'
        const hash =  sha256.hmac(tawkToApiKey, user.email || '')
        tawkToData = {
            name: user.userName,
            username: user.userName,
            email: user.email || '',
            hash: hash
        }
    }

    if (tawkToOnLoaded) {
        if (tawkToData) {
            // Kullanıcı verilerini tawk.to'ya ata
            window.Tawk_API.setAttributes(tawkToData, error => {
                console.log(error)
            })
        }

        // Eğer chat veya payment.finalize sayfasındaysak tawk.to'yu gizle
        if (to.name === 'chat' || to.name === 'payment.finalize') {
            window.Tawk_API.hideWidget()
        } else {
            window.Tawk_API.showWidget()
        }
    } else {
        // window.Tawk_API.onLoad = function() {
        //     tawkToOnLoaded = true
        //
        //     if (tawkToData) {
        //         // Kullanıcı verilerini tawk.to'ya ata
        //         window.Tawk_API.setAttributes(tawkToData, error => {
        //             console.log(error)
        //         })
        //     }
        //
        //     // Eğer chat veya payment.finalize sayfasındaysak tawk.to'yu gizle
        //     if (to.name === 'chat' || to.name === 'payment.finalize') {
        //         window.Tawk_API.hideWidget()
        //     } else {
        //         window.Tawk_API.showWidget()
        //     }
        // };
    }
})

export default router
