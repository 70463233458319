export function user (state) {
  return state.user
}

export function tempUser2 (state) {
  return state.tempUser2
}

export function appleUser (state) {
  return state.appleUser
}

export function authType (state) {
  return state.authType
}

export function facebookUser (state) {
  return state.facebookUser
}

export function googleUser (state) {
  return state.googleUser
}

export function tempUser (state) {
  return state.tempUser
}

export function socialRegistering (state) {
  return state.socialRegistering
}

export function userId (state) {
  return state?.user?.id
}

export function userIdentifier (state) {
  return state?.user?.identifier
}

export function userName (state) {
  return state?.user?.userName
}

export function profileImageId (state) {
  return state?.user?.profile?.imageUuid
}

export function profileImage (state) {
  return state?.user?.profile?.image
}

export function token (state) {
  return state?.token
}

export function credits (state) {
  return state?.user?.account?.credits
}

export function fullname (state) {
  return state?.user && state.user?.firstName ? state.user?.firstName + ' ' + state.user?.lastName : ''
}

export function freeCallUser (state) {
  return state.user?.freeCallUser
}

export function userPhoneNumber (state) {
  return state.user?.phoneNumber
}

export function userEmail (state) {
  return state.user?.email
}

export function userGender (state) {
  return state.user?.profile?.gender
}

export function userOnlineStatus (state) {
  return state.user?.profile?.onlineStatus
}
