<template>
  <div class="settings-filters">
    <b-overlay :show="loading" variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <div v-if="filter" class="user-filters">
        <b-row>
          <b-col>
            <h2 class="settings-title">
              {{ $t('Filtering Settings') }}
            </h2>
          </b-col>
        </b-row>
        <b-row class="mb-3 mt-3">
          <b-col class="filter-column" xl="6" lg="8" md="12">
            <div class="filter-field">
              <p class="filter-field-title">
                {{ $t('Looking for Gender') }}
              </p>
              <b-row>
                <b-col class="text-center cursor-pointer" sm="4" cols="4" @click="filter.gender = 'both'">
                  <b-img thumbnail rounded="circle" :src="imageGenderBoth" :alt="$t('Both')"
                         :class="{active: filter && filter.gender === 'both'}" />
                  <br>
                  {{ $t('Both') }}
                </b-col>
                <b-col class="text-center cursor-pointer" sm="4" cols="4" @click="filter.gender = 'female'">
                  <b-img thumbnail rounded="circle" :src="imageGenderFemale" :alt="$t('Female')"
                         :class="{active: filter && filter.gender === 'female'}" />
                  <br>
                  {{ $t('Female') }}
                </b-col>
                <b-col class="text-center cursor-pointer" sm="4" cols="4" @click="filter.gender = 'male'">
                  <b-img thumbnail rounded="circle" :src="imageGenderMale" :alt="$t('Male')"
                         :class="{active: filter && filter.gender === 'male'}"/>
                  <br>
                  {{ $t('Male') }}
                </b-col>
                <b-col sm="12">
                  <div class="hr mt-1"></div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="filter-column" xl="6" lg="8" md="12">
            <div class="filter-field">
              <p class="filter-field-title">
                {{ $t('Age Range') }}
              </p>
              <b-row>
                <b-col sm="12">
                  <vue-slider
                      v-model="filter.ageRange"
                      v-bind="rangeOptions"
                  />
                  <p class="age-range-text mt-1">
                    {{ filter.ageRange.join(' - ') }}
                  </p>
                </b-col>
                <b-col sm="12">
                  <div class="hr mt-1"></div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt--05">
          <b-col class="filter-column" xl="6" lg="8" md="12">
            <div class="filter-field">
              <p class="filter-field-title">
                {{ $t('Living country') }}
              </p>
              <b-row>
                <b-col>
                  <v-select
                      v-model="filter.country"
                      :clearable="false"
                      :filter="countrySearch"
                      :reduce="country => country.code"
                      :options="countries"
                  >
                    <template v-slot:option="option">
                    <span class="mr-1">
                      {{ option.emoji }}
                    </span>
                      {{ $t(option.name) }}
                    </template>
                    <template v-slot:selected-option="option">
                    <span class="mr-1">
                      {{ option.emoji }}
                    </span>
                      {{ $t(option.name) }}
                    </template>
                  </v-select>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-button
              variant="primary"
              class="px-4"
              @click="updateSearchCriteria"
          >
            <b-spinner v-if="loading" small></b-spinner>
            {{ $t('Save') }}
          </b-button>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { BButton, BCol, BImg, BOverlay, BRow, BSpinner } from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import useJwt from "@/auth/jwt/useJwt";
import {throwDefaultError, showNotify, gtmTrackNewEvent} from "@/helpers/helpers";
import countries from '@/data/countries.json'
import vSelect from 'vue-select'
import _ from "lodash";

export default {
  components: {
    BButton,
    BCol,
    BImg,
    BOverlay,
    BRow,
    BSpinner,

    vSelect,
    VueSlider
  },
  data() {
    return {
      countries: _.sortBy(countries, 'name').map(c => ({ ...c, name: this.$t(c.name) })),
      filter: null,
      imageGenderBoth: require('@/assets/images/genders/both.png'),
      imageGenderFemale: require('@/assets/images/genders/female.png'),
      imageGenderMale: require('@/assets/images/genders/male.png'),
      initialFilter: {
        ageRange: [25, 40],
        ageFrom: null,
        ageTo: null,
        country: 'TR',
        gender: 'female',
      },
      loading: false,
      rangeOptions: {
        min: 18,
        max: 100,
        enableCross: false,
        dotOptions: {
          style: {
            backgroundColor: '#F9447A',
            border: '2px solid #fff',
            boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.35)'
          }
        },
      },
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    countrySearch(options, search) {
      return options.filter(country => {
        return country.name.toLowerCase().includes(search.toLowerCase())
            || country.code.toLowerCase().includes(search.toLowerCase())
      })
    },
    getMe () {
      this.loading = true
      useJwt.getMe()
          .then(response => {
            try {
              const searchCriteria = response.data.body.searchCriteria

              const ageRange = [
                searchCriteria.ageFrom || this.initialFilter.ageRange[0],
                searchCriteria.ageTo || this.initialFilter.ageRange[1]
              ]

              this.filter = {
                ageRange: ageRange,
                ageFrom: searchCriteria.ageFrom,
                ageTo: searchCriteria.ageTo,
                country: searchCriteria.country || 'TR',
                gender: searchCriteria.gender,
              }
              this.saveFilter(this.filter)
            } catch (e) {
              throwDefaultError(this.$toast)
            }
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    saveFilter(data) {
      this.$store.dispatch('app/setSearchFilter', data) // save filter to store
    },
    updateSearchCriteria() {
      this.loading = true
      this.$store.dispatch('app/setSearchFilter', this.filter) // save filter to store
      const _data = {
        ageFrom: this.filter.ageRange[0],
        ageTo: this.filter.ageRange[1],
        country: this.filter.country,
        gender: this.filter.gender,
      }
      useJwt.updateSearchCriteria(_data)
          .then(response => {
            const searchCriteria = response.data.body

            const ageRange = [
              searchCriteria.ageFrom || this.initialFilter.ageRange[0],
              searchCriteria.ageTo || this.initialFilter.ageRange[1]
            ]
            const data = {
              ageRange: ageRange,
              ageFrom: searchCriteria.ageFrom,
              ageTo: searchCriteria.ageTo,
              country: searchCriteria.country || 'TR',
              gender: searchCriteria.gender,
            }
            this.saveFilter(data)

            showNotify(this.$toast, this.$t('Update successful'), 'success')

            gtmTrackNewEvent('updatesearchcriterias', {
              category: 'Hesap',
              action: 'updateSearchCriterias',
            })
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    }
  }
}
</script>

<style lang="scss">
/* Set the theme color of the component */
$themeColor: #F9447A;

/* import theme style */
@import '~vue-slider-component/lib/theme/default.scss';
</style>

<style lang="scss" scoped>
.user-filters {
  border: none;
}
</style>
