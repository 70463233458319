<template>
  <div class="calling">
    <b-overlay :show="loading" variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner/>
      </template>
      <b-row>
        <b-col md="6" sm="12">
          <h3 class="mb-2">
            {{ $t('Calling Settings') }}
          </h3>
          <div v-if="user">
            <div v-if="false" class="d-flex justify-content-between align-content-center mt-05 mb-2 gap-12">
              <div>
                {{ $t('Call through the app') }} ({{ $t('Suggested') }})
                <p class="font-small color-aaa">
                  {{ $t('You can use this option to receive calls through the Boho Live mobile app') }}
                </p>
              </div>
              <b-form-checkbox
                  v-model="callApp"
                  class="custom-control-dark"
                  name="check-button"
                  switch
              />
            </div>
            <div v-if="false" class="d-flex justify-content-between align-content-center mt-05 mb-2 gap-12">
              <div>
                {{ $t('Call via Boho Live Number 0216') }}
                <p class="font-small color-aaa">
                  {{ $t('With this setting, calls will be forwarded to the phone line when a call is received on our phone number 0216 240 0 240') }}
                </p>
              </div>
              <b-form-checkbox
                  v-model="callGsm"
                  class="custom-control-dark"
                  name="check-button"
                  switch
              />
            </div>
            <div class="d-flex justify-content-between align-content-center mt-05 mb-2 gap-12">
              <div>
                {{ $t('Video call') }}
                <p class="font-small color-aaa">
                  {{ $t('You can use this setting to be able to make a video call via the mobile application') }}
                </p>
              </div>
              <b-form-checkbox
                  v-model="cameraOn"
                  class="custom-control-dark"
                  name="check-button"
                  switch
              />
            </div>
            <b-button
                variant="primary"
                class="mt-2 px-4"
                @click="update"
            >
              {{ $t('Save') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {BButton, BCol, BFormCheckbox, BOverlay, BRow} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import LoadingSpinner from '@/components/LoadingSpinner'
import {showNotify, throwDefaultError} from "@/helpers/helpers";
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'settings.calling',
  components: {
    BButton,
    BCol,
    BFormCheckbox,
    BOverlay,
    BRow,

    LoadingSpinner
  },
  data() {
    return {
      callApp: false,
      callGsm: false,
      cameraOn: false,
      loading: false,
      user: null,
    }
  },
  created() {
    this.getMe()
  },
  watch: {
    cameraOn(value) {
      if (value && !this.callApp) {
        this.callApp = true
      }
    },
    callApp(value) {
      if (!value && !this.callGsm) {
        this.$nextTick(() => {
          this.callApp = true
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'En az 1 arama tipi seçmelisin',
            variant: 'info',
          },
        })
      }
    },
    callGsm(value) {
      if (!value && !this.callApp) {
        this.$nextTick(() => {
          this.callGsm = true
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'En az 1 arama tipi seçmelisin',
            variant: 'info',
          },
        })
      }
    }
  },
  methods: {
    getMe() {
      this.loading = true
      useJwt.getMe()
          .then(response => {
            this.user = response.data.body
            this.$store.dispatch('auth/updateUser', this.user)

            const callType = this.user.profile.callType
            if (callType == 0) {
              this.callApp = true
              this.callGsm = true
            } else if (callType == 1) {
              this.callApp = false
              this.callGsm = true
            } else if (callType == 2) {
              this.callApp = true
              this.callGsm = false
            }

            this.cameraOn = !!this.user.profile?.cameraOn
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => {
            this.loading = false
          })
    },
    update() {
      let _type = 2
      if (this.callApp && this.callGsm) {
        _type = 0
      }
      if (!this.callApp && this.callGsm) {
        _type = 1
      }
      this.loading = true

      // const _updateCallType = useJwt.updateCallType(_type)
      const _updateCameraStatus = useJwt.updateCameraStatus(this.cameraOn ? 'true' : 'false')

      Promise.all([_updateCameraStatus])
          .then(values => {
            showNotify(this.$toast, 'Güncellenme başarılı', 'success')
            this.getMe()
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => {
            this.loading = false
          })
    }
  }
}
</script>
