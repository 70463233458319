export default function () {
  return {
    agoraCalling: false,
    agoraCallUser: null,
    agoraVideoCallUser: null,
    agoraCallType: 'outgoing',
    agoraChannelParameters: {
      // A variable to hold a local audio track.
      localAudioTrack: null,
      // A variable to hold a remote audio track.
      remoteAudioTrack: null,
      // A variable to hold the remote user id.
      remoteUid: null,
    },
    agoraClient: null,
    agoraOptions: {
      // Pass your App ID here.
      appId: process.env.VUE_APP_AGORA_ID,
      // Set the channel name.
      channel: null,
      // Pass your temp token here.
      token: null,
      // Set the user ID.
      uid: null,
    },
    callRequestInfo: null,
    showAgoraCall: false,
    showAgoraVideoCall: false,
  }
}
