<template>
  <div class="notifications">
    <b-overlay :show="loading" variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <b-row>
        <b-col md="6" sm="12">
          <h3 class="mb-2">
            {{ $t('Notification Settings') }}
          </h3>
          <div v-if="notifications">
            <div class="d-flex justify-content-between align-content-center mt-05 mb-2">
              {{ $t('Push Notifications') }}
              <b-form-checkbox
                  v-model="notifications.pushNotifications"
                  class="custom-control-dark"
                  name="check-button"
                  switch
              />
            </div>
            <div class="d-flex justify-content-between align-content-center mt-1 mb-2">
              {{ $t('SMS Notifications') }}
              <b-form-checkbox
                  v-model="notifications.smsNotifications"
                  class="custom-control-dark"
                  name="check-button"
                  switch
              />
            </div>
            <div class="d-flex justify-content-between align-content-center mt-1 mb-2">
              {{ $t('Email Notifications') }}
              <b-form-checkbox
                  v-model="notifications.emailNotifications"
                  class="custom-control-dark"
                  name="check-button"
                  switch
              />
            </div>
            <b-button
                variant="primary"
                class="mt-2 px-4"
                @click="update"
            >
              {{ $t('Save') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { BButton, BCol, BFormCheckbox, BOverlay, BRow } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import LoadingSpinner from '@/components/LoadingSpinner'
import {gtmTrackNewEvent, showNotify, throwDefaultError} from "@/helpers/helpers";

export default {
  name: 'settings.notifications',
  components: {
    BButton,
    BCol,
    BFormCheckbox,
    BOverlay,
    BRow,

    LoadingSpinner
  },
  data() {
    return {
      loading: false,
      notifications: null
    }
  },
  created() {
    this.getNotifications()
  },
  methods: {
    getNotifications() {
      this.loading = true
      useJwt.getSettings()
          .then(response => {
            this.notifications = response.data.body
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    update() {
      const data = {
        pushNotifications: this.notifications.pushNotifications,
        smsNotifications: this.notifications.smsNotifications,
        emailNotifications: this.notifications.emailNotifications
      }
      this.loading = true
      useJwt.updateSettings(data)
          .then(response => {
            showNotify(this.$toast, this.$t('Update successful'), 'success')

            gtmTrackNewEvent('savenotificationsettings', {
              category: 'Hesap',
              action: 'SaveNotificationSettings',
            })
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    }
  }
}
</script>
