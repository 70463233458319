<template>
  <div class="page-about-us">
    <h2 class="settings-title mb-2">
      {{ $t('Membership Agreements') }}
    </h2>
    <div v-if="currentLanguage === 'tr'">
      <h3 class="settings-sub-title" id="uyelik-sozlesmesi">
        Üyelik Sözleşmesi
      </h3>
      <div class="settings-content mb-3">
        <p>
          İşbu Üyelik Sözleşmesi (“Sözleşme”), www.boholive.app uzantılı web sitesinin işletmecisi olan Arasana İnternet
          İletişim Ve Telekomünikasyon Limited Şirketi ile işbu Sözleşme koşullarını okuyup, elektronik olarak onaylayarak
          kabul
          eden ve de Site tarafından gönderilen elektronik postayı onaylamak suretiyle üye olan kişi (“Üye”) arasında
          gönderilen
          elektronik postanın onayı ile birlikte akdedilerek yürürlüğe girmiştir.
        </p>
        <h6>
          2. Konu
        </h6>
        <p>
          İşbu Sözleşme, Sitede sunulan hizmetler, Üye’nin bu hizmetlerden yararlanma şartları ve tarafların hak ve
          yükümlülüklerini düzenlemektedir.
        </p>

        <h6>
          3. Hak ve Yükümlülükler:
        </h6>

        <h6>
          3.1. Üye’nin Hak ve Yükümlülükleri
        </h6>

        <p>
          3.1.1. Üye, işbu Sözleşme’yi akdetmekle, Site içinde yer alan
          <a href="#gizlilik-ve-kullanim-kosullari">kullanım koşulları</a>,
          Site’nin nasıl çalıştığına dair açıklamalar/bilgiler, üyeliğe ve hizmetlere ilişkin Site tarafından açıklanan
          her türlü sair üyelik, kullanım şart ve koşullarını da kabul etmektedir. Üye bahsi geçen açıklamalar
          ve/veya koşullarda belirtilen her türlü hususa uygun olarak davranacağını kabul, beyan ve taahhüt eder.
        </p>
        <p>
          3.1.2. Üye, Site’ye ve kendisi tarafından verilen bilgilerle oluşturulan, eklediği fotoğrafı ile diğer
          üyeler tarafından herhangi bir kısıtlama olmaksızın görüntülenebilen her bir üyeye özel tanıtım sayfası olan
          profiline (“Profil”) kendisi tarafından sağlanan bilgilerin ve içeriklerin doğru ve hukuka uygun olduğunu kabul
          ve taahhüt etmektedir. Üye, Profiline hiçbir koşulda kişisel iletişim bilgilerini (e-posta
          adresi, ikamet adresi, telefon numarası vb.) ekleyemez. Site Profiline Üye’nin kişisel iletişim
          bilgilerinin eklenmesi durumunda Site’nin Üye’nin üyeliğini iptal etme hakkı saklıdır. Üyenin vermiş olduğu
          bilgilerin hatalı veya noksan olmasından (şifre ve/veya Pin’in de unutulması da dahil olmak üzere) doğacak
          zararlardan dolayı Üye kendi sorumlu olup, bu nedenle üyeliğinin sona ermesinden de sadece Üye sorumludur.
        </p>
        <p>
          3.1.3. Üye, hiçbir şekilde diğer üyelere ya da Site ortamında tehditkar, ahlak dışı, Türkiye Cumhuriyeti
          yasalarına
          aykırı bir şekilde mesajlar göndermeyecek ve bu tür iletişimlerde bulunmayacaktır. Üye, Site üzerinde
          gerçekleştireceği tüm iletişimlerde genel ahlak, görgü ve yasal kurallara uygun davranacak ve de hiçbir şekilde
          diğer
          üyeleri taciz etmeyecek, üyelerin Site’yi kullanmamasını etkileyecek şekilde hareket etmeyecektir. Üye, Site’ye
          ya da
          diğer üyelere, 3. gerçek veya tüzel kişileri kötüleyici, haksız rekabet oluşturabilecek açıklamalar da
          bulunmayacak ve
          bu türden materyaller yayınlayıp, dağıtmayacaktır.
        </p>
        <p>
          3.1.4. Üye, Site kapsamında edineceği bilgiler ve de diğer üyelerin bilgilerini hiçbir şekilde Site kapsamında
          gerçekleştirilmesine izin verilen faaliyetler dışında kullanmayacak ve de hiçbir koşulda ilgili bilgi sahibinin
          önceden verilmiş onayı olmaksızın kullanmayacaktır.
        </p>
        <p>
          3.1.5. Üye, Site aracılığıyla diğer üyeler ile yaptığı özel mesajlaşmalar ve de görüşmeler,
          elektronik ortamda anında ifa edilen hizmetler, anında teslim edilen gayrimaddi mallar ve dijital kabul edilen
          tüm ürünler
          de dahil olmak üzere, Site’de ve/veya Site aracılığıyla yaptığı her türlü işlem ve eylemden kaynaklanan hukuki
          ve cezai
          sorumluluktan münhasıran kendisi sorumludur. Üye bu kapsamda Site’nin hiçbir sorumluluğu olmadığını kabul, beyan
          ve taahhüt eder
          ve de Site’nin uğrayabileceği her türlü zarara karşılık Üye’ye rücu edebileceğini kabul eder. Üye, diğer üyeler
          ile
          gerçekleştireceği görüşmelerin her türlü hukuki ve cezai sonucundan münhasıran kendisi sorumludur.
        </p>
        <p>
          3.1.6. Üye hiçbir koşulda Site’yi ticari amaçla kullanamaz, Site aracılığıyla reklam yapamaz.
        </p>
        <p>
          3.1.7. Üye, Site’de bulunan resimleri, metinleri, görsel ve işitsel imgeleri, dosyaları, veri
          tabanları,
          yazılımlar ve her türlü sair kopyalanabilir eser veya eser benzeri unsurları çoğaltmayacağını,
          kopyalamayacağını,
          dağıtmayacağını, işlemeyeceğini kabul, beyan ve taahhüt eder.
        </p>
        <p>
          3.1.8. Üye, Site’nin resmi makamlarca yürürlükteki mevzuat hükümleri uyarınca Üyeye ait kişisel bilgilerin talep
          edilmesi halinde Site’nin ilgili mevzuat gereği Üye’ye ilişkin bilgileri paylaşmaya yetkili olduğunu kabul
          ve
          taahhüt eder.
        </p>
        <p>
          3.1.9. Üye, Site’nin önceden verilmiş yazılı onayı olmadan işbu Sözleşme’yi veya bu Sözleşme
          kapsamındaki
          hak ve yükümlülüklerini ve/veya herhangi bir şekilde üyeliğini kısmen veya tamamen herhangi bir üçüncü kişiye
          devredemez; üyelik üyenin kendisinden başka kişilerin kullanımına açılamaz. Bu tür devirler veya
          yetkisiz
          kullanımlar sonucu üçüncü kişilerin uğradıkları veya uğrayabilecekleri zararlardan dolayı Site doğrudan ve/veya
          dolaylı olarak hiçbir şekilde sorumlu tutulamaz. Üyeliğini başkasına kullandırttığı veya devrettiği tespit
          edilen üyenin üyeliği
          iptal edilir.
        </p>
        <p>
          3.1.10. Üye işbu Sözleşme’de yer alan hükümlere uymadığı takdirde üyeliğinin Site tarafından tek taraflı olarak
          ve de
          herhangi bir bildirime gerek olmaksızın tazminatsız olarak sona erdirilebileceğini kabul, beyan ve taahhüt eder.
          Üyelik iptali halinde üyenin o tarihte sahip sahip olduğu tüm elmaslar iade edilmeden iptal edilecektir. Üye’nin
          bu
          konuda herhangi bir talep hakkı yoktur.
        </p>
        <p>
          3.1.11. Üye, üyeliğini iptal etmek istemesi halinde üyelik iptaline ilişkin “arasana nasıl çalışır” bölümünde
          belirtildiği şekilde üyelik iptal talebinde bulunacaktır.
        </p>
        <p>
          3.1.12. Üye, işbu Üyelik Sözleşmesi’nin onaylayarak kabul etmekle, Site’ye ait
          <a href="#gizlilik-ve-kullanim-kosullari">Kullanım Koşulları ve Gizlilik Sözleşmesi</a>
          ile
          <a href="#kisisel-verilerin-kullanimi-politikasi">Kişisel Verilerin Korunmasına Dair Politikayı</a>
          da kabul etmiş sayılır.
        </p>

        <h6>
          3.2. Site’nin Hak ve Yükümlülükleri
        </h6>

        <p>
          3.2.1. Site, her zaman ve de herhangi bir bildirimde bulunmaksızın tek taraflı olarak Site içeriğini,
          kullanım/üyelik
          koşullarını, ücretleri ve de sunulan hizmetleri tek taraflı olarak değiştirebilir. Bu kapsamda Site, Üye’nin
          Profilini
          ve/veya Üye tarafından Site’ye eklenen yazışmaları da tek taraflı olarak değiştirebilir, silebilir, Üye’den
          değişiklik
          talep edebilir, Üye’nin Site kullanımını tek taraflı olarak sınırlama getirebilir.
        </p>
        <p>
          3.2.2. Site her zaman Site’yi geçici olarak ya da tamamen durdurabilir. Site’nin bu hakkı kullanmasından dolayı
          Site’nin Üye’ya ya da 3. kişilere karşı hiçbir sorumluluğu olmayacaktır. Site hiçbir şekilde Site’nin devamlı
          ve/veya
          sorunsuz olarak çalışacağını taahhüt etmemektedir.
        </p>
        <p>
          3.2.3. Site hiçbir şekilde Üye’nin diğer üyeler tarafından aranılacağı ya da aranılması halinde herhangi bir
          beklentiye karşılık verileceğini taahhüt etmemektedir. Site hiçbir şekilde Üyeler arasında kurulacak iletişimden
          sorumlu değildir. Site, Üyeler ile diğer üyeler arasında doğabilecek iletişim neticesinde meydana gelebilecek
          hukuki
          ve/veya ceza ihtilaflardan sorumlu değildir.
        </p>
        <p>
          3.2.4. Site, Üye’nin yönlendirmeler aracılığıyla diğer websitelerine geçişini sağlaması halinde, Üye’nin geçiş
          yapacağı websitesi içeriğinden, bu websitelerinde yer alan hizmet ve/veya ürünler nedeniyle hiçbir şekilde
          sorumlu
          tutulamaz.
        </p>
        <p>
          3.2.5. Üye’ye karşı Site, Site’de sağlanan hizmetler nedeniyle ya da diğer üyeler sebebiyle uğrayabilecekleri
          maddi
          ve/veya manevi zararlardan hiçbir şekilde sorumlu olmayacaktır. Site aynı zamanda hiçbir koşulda Üye ile diğer
          üyelerin hukuka aykırı fiil ve eylemlerinden dolayı hukuki ve cezai olarak sorumlu olmayacaktır.
        </p>
        <p>
          3.2.6. Site, işbu Üyelik Sözleşmesi’nin onaylayarak üye olan kişilere ait her türlü kişisel bilgiyi ve veriyi,
          6698
          Sayılı Kişisel Verilerin Korunması Kanunu ve ilgili mevzuata uygun kullanmayı kabul, beyan ve taahhüt eder.
          Üyelere
          ait kişisel verilerin kullanımı konusunda detaylar Site’ye ait
          <a href="#gizlilik-ve-kullanim-kosullari">Kullanım Koşulları ve Gizlilik Sözleşmesi</a>
          ile
          <a href="#kisisel-verilerin-kullanimi-politikasi">
            Kişisel Verilerin Korunmasına Dair Politika
          </a>
          da yer almaktadır.
        </p>

        <h6>
          4. Mücbir Sebepler
        </h6>

        <p>
          Hukuken mücbir sebep sayılan tüm durumlarda, Site işbu Sözleşme ile belirlenen edimlerinden herhangi
          birini geç
          veya eksik ifa etme veya ifa etmeme nedeniyle Üye’ye karşı herhangi bir sorumluluğu olmayacaktır.  Herhangi
          bir
          mücbir sebep halinde Üye gecikme, eksik ifa veya ifa etmeme nedeniyle Site’den herhangi bir nam altında
          tazminat
          talep edemez.
        </p>
        <p>
          "Mücbir sebep" terimi, doğal afet, isyan, savaş, grev, iletişim sorunları, altyapı ve internet arızaları,
          elektrik
          kesintisi ve kötü hava koşulları da dahil ve fakat bunlarla sınırlı olmamak kaydıyla ilgili tarafın makul
          kontrolü
          haricinde ve Site’nin gerekli özeni göstermesine rağmen önleyemediği kaçınılamayacak olaylar olarak
          yorumlanacaktır.
        </p>

        <h6>
          7.  Uygulanacak Hukuk ve Yetkili Mahkemeler
        </h6>
        <p>
          İşbu Sözleşme Türk Hukuku’na tabii olacaktır. İşbu sözleşmeden kaynaklanan her türlü ihtilafta İstanbul
          Mahkemeleri
          ve İcra Daireleri yetkilidir.
        </p>
        <h6>
          8. Sözleşmenin Feshi
        </h6>
        <p>
          İşbu Sözleşme, Üye ya da Site tarafından feshedilmedikçe yürürlükte kalmaya devam edecektir. Üyeliğin belirli
          bir
          süre için olması halinde Sözleşme işbu sürenin dolmasıyla birlikte kendiliğinden sona erecektir. Site işbu
          Sözleşme’yi
          Üye’nin Sözleşme koşulları ya da Site’de yer alan diğer şart ve koşulları ihlal etmesi halinde herhangi bir
          bildirimde
          bulunmaksızın tek taraflı olarak ve de Üye’ye herhangi bir tazminat ödemeksizin feshedebilir.
        </p>
      </div>
      <h3 class="settings-sub-title" id="gizlilik-ve-kullanim-kosullari">
        Gizlilik ve Kullanım Koşulları
      </h3>
      <div class="settings-content mb-3">
        <p>
          http://www.arasana.com uzantılı web sitesinin işletmecisi Arasana İnternet Bilişim ve Telekomünikasyon Limited
          Şirketi’dir. (“Biz”, www.arasana.com veya “Site”). Sitemizi ziyaret eden kullanıcılar (üyeler de dahil olmak
          üzere)
          aşağıdaki koşulları kabul etmiş sayılacaktır.
        </p>
        <br>
        <p>
          Sitede yer alan tüm bilgiler, tablolar, yorum ve tavsiyeler ile buradan verilen tüm linkler, kullanıcıya bilgi
          vermek
          amacıyla sunulmuştur. Site internette yer alan web sitesindeki bilgilerin mevcut durumu yansıtması için makul
          olan tüm
          gayreti göstermiştir. Bu sitede yer alan bilgilerin revize edilmesi ya da düzeltilmesi ile ilgili olarak;
          www.arasana.com önceden haber vermeksizin, istediği zaman sitedeki bilgilerin revize edilmesi, değiştirilmesi,
          düzeltilmesi ve çıkarılması gibi işlemleri yapacak tek yetkili olup bu konudaki tüm haklara sahiptir.
        </p>
        <br>
        <p>Bu sitede kullanılan bazı ifadeler ileriye dönük olarak hazırlanmış olabileceğinden çeşitli belirsizlikler
          içermesi
          mümkündür. Muhtelif nedenlerden dolayı gerçek sonuç ve gelişmeler, bu ifadelerde belirtilen ya da ima
          edilenlerden
          somut bir şekilde farklı olabilir.</p>
        <br>
        <p> Ziyaretçiler, bilgi ve dokümanları sadece bilgi edinmek amacıyla kullanabilir. Burada mevcut olan bilgiler
          www.arasana.com’un önceden yazılı izni alınmaksızın kısmen veya tamamen kopya edilemez, dağıtılamaz,
          kiralanamaz,
          çoğaltılamaz, alt lisansla kullandırılamaz, değiştirilemez, ileride kullanılmak üzere saklanamaz, hiçbir şekilde
          ticari amaçla kullanılamaz veya kullandırılamaz.</p>
        <br>
        <p> Bu siteye erişim serbest ve anonimdir. Bu sitede yer alan herhangi bir bilgiyi, herhangi bir şekilde tahrif
          etmek;
          her türlü cezai ve hukuki takibata neden olabilecektir. Bu web sitesi sadece 18 yaş üstü kişilere yönelik olup,
          site
          üzerinde 18 yaşın altındaki ziyaretçilerin kişisel bilgilerini bilinçli biçimde toplamayız. Sitede 18 yaş altı
          ziyaretçilerden yanlışlıkla alınan kişisel bilgilerin farkına vardığımızda, bu bilgileri kayıtlarımızdan
          sileriz.</p>
        <br>
        <p> www.arasana.com bu web sitesinde yayınlanmış bilgiler ya da bilgilere dayanılarak yapılan işlemlerden veya
          siteye
          ulaşılamamasından doğan ve doğacak zarar ve/veya kayıplardan dolayı hiçbir şekilde sorumluluk kabul etmez. Bu
          web
          sitesinin yayınlanması, hiç bir kişi ve/veya kuruluşa herhangi bir konuda, herhangi bir taahhüt içermemektedir.
          Sitede
          yer alan bilgiler ışığında verilecek her türlü karar ile ilgili tüm sorumluluk, bu siteyi ziyaret eden kişiye
          aittir.</p>
        <br>
        <p> www.arasana.com web sitesine link verilmesi Site’nin önceden vereceği yazılı izne tabidir. Bu web sitesine
          veya bu
          siteden bağlantı yapılarak ulaşılan herhangi bir web sitesine girilmesi veya bunların kullanımından doğabilecek
          doğrudan veya dolaylı kayıp veya zararlardan, www.arasana.com sorumlu tutulamaz.</p>
        <br>
        <p> Sitede yer alan bilgilerin çeşitli nedenlerle doğruluk içerecek şekilde güncelleşmemesi; revizyonda gecikme,
          sitede
          olması muhtemel bir yanlışlık veya eksiklikten ya da değişiklikten ötürü www.arasana.com’a hiç bir nam altında
          sorumluluk yüklenemez. Güncellemeye ilişkin tarih bulunması, belirtilen hususlar aksine www.arasana.com’u hiçbir
          şekilde yükümlülük altına sokmaz. Bu sitede bulunan bilgi veya ifadelerin (tasarımlar, resimler, logo, vb. gibi)
          her
          türlü maddi ve manevi hakkı Site’ye ait olup, Site’nin yazılı izni olmaksızın kullanılamaz.</p>
        <br>
        <p> www.arasana.com, istisnasız tüm kullanıcılara ait kişisel bilgileri, kati suretle gizlilik içerisinde
          almaktadır. Şu
          kadar ki, Site’nin kullanımı kapsamında üyelerin profillerinde yer alan bilgiler diğer üyeler ve/veya 3. Kişiler
          tarafından görüntülenebilecektir. Bununla beraber hizmet sürecinin sorunsuz ilerlemesi kullanıcı bilgilerinin
          saklanması ve kullanımını gerektirebilecektir. Hizmetin en iyi şekilde verilebilmesi için, hizmet sunumuna
          yönelik
          bilgiler www.arasana.com’da saklanır. Hizmetin gerektirdiği ölçüler dışında kullanıcılara ait kişisel bilgiler
          kesinlikle üçüncü şahıslara iletilmemektedir. Site, müşteri bilgilerini yeni hizmetler ve kampanyalar hakkında
          bilgi
          vermek, ürün ve hizmet sunumunu iyileştirmek üzere saklar ve kullanır. Ayrıca gerektiğinde anonim kullanıcı
          profillerini pazar araştırması için kullanabilecektir.</p>
        <br>
        <p> Kullanıcılar tarafından verilen kişisel bilgileri, Site’nin kontrolü dışında veya yasadışı veya yetkisiz
          olarak
          imha, kayıp, değiştirme, erişim, ifşa veya kullanıma karşı korumak için tasarlanmış, uygun yönetimsel, teknik ve
          fiziksel güvenlik düzenekleri kullanıyoruz.</p>
        <br>
        <p> www.arasana.com, ödemeleri esnasında optimum güvenliği sağlanması için, ödeme trafiğini sağlamada işlem
          yapılan
          bankanın alt yapısı ile ‘3D Secure’ sistemleri kullan maya çalışmaktadır. Ödemelerin gerçekleştirilmesi için
          gerekli
          olan bilgiler (kredi kartı numarası vs.) www.arasana.com tarafından saklanmamaktadır. Yapılan işlemlerde, kredi
          kartı
          bilgileriniz ilgili kredi kartı firmasına şifreli bir formatta, bu konuda kabul görmüş güvenli kural ve araçlar
          kullanılarak iletilmektedir. Seçiminize bağlı olarak, kredi kartı bilgilerinizin bir kısmını şifreli bir
          formatta,
          gerekli fiziksel, elektronik ve prosedürel önlemleri aldıktan sonra saklayabiliriz.</p>
        <br>
        <p> Web sitesinde dolaşırken, dolaştığınız sayfalara ve tıkladığınız buton ve bağlantılara ilişkin navigasyon ve
          tıklama
          bilgileri, ziyaret ettiğiniz sayfalar, bir sayfada kaldığınız süre, elmas yükleme süreniz gibi bilgilere
          ulaşabilmek
          için tutulmaktadır. Bu bilgiler sizin kimliğinizi ortaya çıkarmaz ya da direkt olarak şahsınızla
          ilişkilendirilmez. Bu
          bilgiler, web sitesi üzerinden verdiğiniz elmas yüklemenin tamamlanması, analiz edilmesi ve geliştirilmesi, web
          sitesinin kullanımının artırılması, hukuki açıdan gerekli olan durumlarda bilgi sağlanması ve web sitesi ya da
          üçüncü
          kişilere ait web siteleri üzerinde ilginizi çekebilecek kampanya, promosyon ve yönlendirmeler için
          kullanılmaktadır.
          Bu bilgiler, çalışanlarımız ya da web sitesini kullanımınızın analizinde ve desteğinde kullanılabilir.</p>
        <br>
        <p> Çerezler, web sitesini ziyaret ettiğiniz zaman bilgisayarınıza tercihlerinizi kaydeden küçük metin
          dosyalarıdır.
          Çerezler, web sitesine tekrar giriş yaptığınızda sitenin sizi tanıması, ilginizi çekebilecek ilan ve reklamların
          en
          çok giriş yaptığınız sayfalarda yayınlanması gibi işlemlerde size en kaliteli hizmeti sağlamak için
          kullanılmaktadır.
          Herhangi bir kişisel ya da özel bilgi içermezler. Bilgisayarınıza zarar vermezler. Web sitesi üzerinden elmas
          yüklemek
          için bu çerezlere izin vermeniz gerekir. Eğer isterseniz, daha sonra bu çerezleri bilgisayarınızdan silebilir ya
          da
          engelleyebilirsiniz.</p>
        <br>
        <h6> Çerezler hakkında bilmeniz gerekenler:</h6>
        <p> Kimliğinizi tanımlamak ve siteye giriş yaptıktan sonra ilgili ayarları muhafaza etmek için çerezler
          kullanabiliriz.</p>
        <p> Çoğu çerez “oturum çerezi”dir, yani siz ziyaretinizi bitirdikten sonra bilgisayarınızdan silinir.</p>
        <p> Internet tarayıcınız izin veriyorsa, çerezleri kabul etmeyebilirsiniz.</p>
        <p> Web sitesi üzerinde reklamları bulunan üçüncü kişilerin çerezleri ile karşılaşabilirsiniz ancak bunlar bizim
          kontrolümüzde değildir.</p>
        <p> Reklam şirketleri reklamlarının başarı oranını ölçümlemek için çerez ve etiketler kullanabilirler ya da bu ve
          benzeri teknolojileri web sitesi üzerinde veya kendi internet siteleri üzerinde özelleştirilmiş reklam içeriği
          döndürmek için kullanabilirler. Çerezler aracılığı ile üçüncü kişiler tarafından toplanan herhangi bir bilgi
          tamamen
          anonimdir ve herhangi bir kişisel bilgi içermez.</p>
        <br>

        <h6>Bizimle Nasıl İletişim Kurabilirsiniz?</h6>
        <p>Bu Kullanım Koşulları ve Gizlilik konusunda herhangi bir sorunuz veya değerlendirmeniz varsa veya hakkınızdaki
          bilgileri veya tercihlerinizi güncellemek istiyorsanız, lütfen&nbsp;support@boholive.app ile iletişime
          geçiniz.</p></div>
      <h3 class="settings-sub-title" id="kisisel-verilerin-kullanimi-politikasi">
        Kişisel Verilerin Kullanımı Politikası
      </h3>
      <div class="settings-content">
        <p>
          İşbu Kişisel Verilerin Korunması Politikası (“Politika”) ile, www.arasana.com internet sitesi (“Site”)
          üzerinden toplanan veya üçüncü kişilerce verilen kişisel verilerin kullanımına ilişkin koşullar beyan
          edilmektedir.
        </p>

        <p>Site’ye üye olarak işbu Politika hükümlerini kabul etmiş bulunmaktasınız. Politika’yı kabul etmekle,
          aşağıda
          belirtilen kişisel verilerinizin, yine aşağıda belirtilen amaçlarla toplanmasına, saklanmasına, işlenmesine,
          kullanılmasına ve üçüncü şahıslara aktarılmasına izin vermiş bulunmaktasınız.</p>

        <p>Site, Politikayı yürürlükteki mevzuatta yapılabilecek değişiklikler çerçevesinde her zaman güncelleme
          hakkını saklı
          tutar. Bu amaçla yapılacak güncellemeler, Site üzerinden duyurulacaktır.</p>

        <p>İşbu Politika’da tanımlanmayan ifadelerin yorumlanmasında Üyelik Sözleşmesi, Kullanım Koşulları ve Gizlilik
          metinlerinde yer alan tanımlar dikkate alınacaktır.</p>

        <h6>KİŞİSEL VERİLER NELERDİR?</h6>
        <p>Site olarak kullanıcılara en iyi hizmeti verebilmek amacıyla, Site’ye üye olurken, üyeliğinizi
          değiştirirken,
          Site’yi kullanırken, müşteri destek birimi ile irtibata geçerken ve diğer yollarla iletişime geçerken
          sunduğunuz tüm
          bilgileri toplamaktayız. Bu bilgileri doğrudan siz tarafımıza verebileceğiniz gibi, Site’yi kullandığınız
          esnada
          çeşitli yazılım ve teknolojik araçlar vasıtasıyla da toplamaktayız.</p>

        <p>Bu kapsamda, ad, soyad, telefon, adres, e-mail adresi, fatura bilgileri, TC kimlik numarası, kimlik
          bilgileri,
          üyelik bilgileri, üyelik şifre/parolaları, iletişim bilgileri, doğum tarihi, yaş, cinsiyet, meslek, tercih
          edilen
          dil ve benzeri şahsınız ile ilgili tüm bilgiler Kişisel Veri’dir. Ayrıca mobil aygıtınız üzerinden
          topladığımız
          Konum bilgileriniz, Uygulama üzerinden gerçekleştirdiğiniz işlem ve oylama bilgileriniz ile yorumlarınız,
          web sitesi
          tarayıcısında bulunan çerez (cookie) uygulamaları sayesinde tespit ettiğimiz uygulama davranışlarınız,
          alışkanlıklarınız ve tercih bilgileriniz, kullandığınız mobil aygıt hakkında topladığımız bilgiler, çağrı ve
          sms
          verileri de topladığımız verilerinizdir.</p>

        <h6>KİŞİSEL VERİLERİ NE İÇİN KULLANIYORUZ?</h6>
        <p>Site’nin topladığı tüm Kişisel Veriler, 6698 Sayılı Kişisel Verilerin Korunması Kanunu dahil ve bununla
          sınırlı
          olmamak üzere ilgili tüm yasal düzenlemeler uyarınca, yasalarca belirlenen sınırlar çerçevesinde toplanarak
          Site
          tarafından işlenmekte ve saklanmaktadır. İşbu Kişisel Veriler aşağıdaki amaçlarla kullanılacaktır:</p>
        <div>
          <ul>
            <li>
              Kimliğinizi doğrulamak;
            </li>
            <li>
              Hizmetlerimizi sunmak, sürdürmek ve geliştirmek; örneğin, hizmet kalitesini artırmak, ödeme işlemlerini
              kolaylaştırmak, fatura göndermek;
            </li>
            <li>
              Kullanıcılara ve Üçüncü Şahıs Sağlayıcılara hizmet desteği sağlamak, güvenlik özellikleri geliştirmek,
              kullanıcıların kimlik doğrulamasını yapmak ve ürün güncellemeleri ve yönetimsel mesajlar göndermek;
            </li>
            <li>
              Yazılım açığı sorunlarını ve işletim ile ilgili problemleri belirlemek, veri analizi yapmak, test ve
              araştırma
              yapmak, kullanım ve faaliyet eğilimlerini takip ve analiz etmek;
            </li>
            <li>
              Siz ve Üçüncü Şahıs Sağlayıcı arasında iletişim ve bağlantı kurmayı sağlamak;
            </li>
            <li>
              Üyelik sözleşmesinin gereğini yerine getirmek,
            </li>
            <li>
              Site ve hizmetlerimiz, promosyonlar, haberler, etkinliklerimiz, kampanya ya da diğer promosyon
              girişlerini
              işleme koymak ve bunlarla ilgili ödülleri dağıtmak;
            </li>
            <li>
              Özellik, içerik, sosyal bağlantı, yönlendirme ve reklam sağlanması veya önerilmesi de dahil olmak üzere
              hizmetleri kişiselleştirmek ve geliştirmek;
            </li>
            <li>
              Mahkeme kararı veya yasal prosedür gereklerini yerine getirmek.
            </li>
          </ul>
        </div>

        <h6>KİŞİSEL VERİLERİNİZ PAYLAŞILIYOR MU?</h6>
        <p>Kişisel Verileriniz, Site üzerinden hizmetlerimizi sunabilmemiz ve yukarıda belirttiğimiz kullanım
          amaçlarının
          sağlanması için,</p>
        <div>
          <ul>
            <li>
              Site çalışanları, hissedarları, iş ortakları, iştirakleri ile,
            </li>
            <li>
              Veri depolama hizmeti ve/veya elektronik ileti gönderilmesi hizmeti aldığımız yerli yabancı üçüncü
              şahıslarla
              (gerçek veya tüzel),
            </li>
            <li>
              Hizmetlerimize ilişkin anket çalışması, hizmet kalitesi ve istatistiki veri ölçümleri yapan yerli
              yabancı üçüncü
              şahıslarla (gerçek veya tüzel),
            </li>
            <li>
              Üçüncü Şahıs Sağlayıcılarla,
            </li>
            <li>
              Ödeme sistemleri hizmeti aldığımız yerli yabancı üçüncü şahıslarla (gerçek veya tüzel), Bankalararası
              Kart
              Merkezi ve anlaşmalı olduğumuz bankalar ile,
            </li>
            <li>
              Yerli yabancı ajanslar, reklam şirketleri ve anket şirketleri ile,
            </li>
            <li>
              Herhangi bir işbirliği veya promosyon veya kampanya teklifi üzerinden hizmet verebilmemiz için yerli
              yabancı
              üçüncü şahıslarla (gerçek veya tüzel),
            </li>
            <li>
              Bizimle bilgi paylaşmasına onay verdiğiniz yerli yabancı üçüncü şahıslarla (gerçek veya tüzel),
            </li>
            <li>
              Yasal düzenlemeler çerçevesinde mahkemeler ve kamu kurum ve kuruluşları ile,
            </li>
          </ul>
        </div>

        <p>paylaşılmaktadır. Politikayı kabul ederek işbu bilgi paylaşımlarına da onay vermektesiniz.</p>
        <p>Üçüncü kişilere veri aktarımı sırasında hak ihlallerini önlemek için gerekli teknik ve hukuki önlemler
          alınmaktadır. Bununla birlikte, kişisel verileri alan üçüncü kişinin veri koruma politikalarından dolayı ve
          üçüncü
          kişinin sorumluluğundaki risk alanında meydana gelen ihlallerden ve veri aktarımı sırasında internet
          bağlantınıza
          yapılan saldırılardan sorumlu değiliz.</p>

        <h6>KİŞİSEL VERİLERE İLİŞKİN YASAL HAKLARINIZ NELER?</h6>
        <p>6698 Sayılı Kişisel Verilerin Korunması Kanunu uyarınca;</p>
        <p>a) Kişisel veri işlenip işlenmediğini öğrenme, </p>
        <p>b) Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme, </p>
        <p>c) Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</p>
        <p>ç) Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</p>
        <p>d) Kişisel verilerin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme, </p>
        <p>e) İlgili mevzuatta öngörülen şartlar çerçevesinde, kişisel verilerinizin silinmesini veya yok edilmesini
          isteme, </p>
        <p>f) Kişisel verilerinizin düzeltilmesi, silinmesi veya yok edilmesine ilişkin işlemlerin, kişisel verilerin
          aktarıldığı üçüncü kişilere bildirilmesini isteme, </p>
        <p>g) İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir
          sonucun
          ortaya çıkmasına itiraz etme, </p>
        <p>ğ) Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini
          talep
          etme, </p>
        <p>haklarına sahipsiniz. Bu hususlardaki taleplerinizi support@boholive.app e-posta adresine göndermeniz halinde
          yasal
          süre içerisinde tarafınıza elektronik posta ile geri dönüş yapılacaktır.</p>

        <h6>KİŞİSEL VERİLER NE KADAR SÜRE İLE SAKLANACAK?</h6>
        <p>Kişisel Veriler, işbu Politika ve Kullanım Koşulları’nda belirtilen şartlar çerçevesinde ve Site’nin
          mahiyetinden
          kaynaklanan yükümlülükleri yerine getirebilmesi adına işlendikleri amaç için gerekli olan süre kadar Site
          tarafından
          saklanacaktır.</p>
        <p>Buna ek olarak doğabilecek herhangi bir uyuşmazlık durumunda, Site uyuşmazlık kapsamında gerekli
          savunmaların
          gerçekleştirilebilmesi amacıyla sınırlı olmak üzere ve ilgili mevzuat uyarınca belirlenen zamanaşımı
          süreleri
          boyunca kişisel verileri saklayabilecektir.</p>

        <h6>BİZE NASIL ULAŞABİLİRSİNİZ?</h6>
        <p>İşbu Politikaya ilişkin tüm sorularınızı, değişiklik ve sair taleplerinizi support@boholive.app e-posta
          adresinden
          bize dilediğiniz zaman iletebilirsiniz.</p>
      </div>
    </div>
    <div v-if="currentLanguage === 'en'">
      <h3 class="settings-sub-title" id="membership-agreement">
        Membership Agreement
      </h3>
      <div class="settings-content mb-3">
        <p>
          This Membership Agreement (the "Agreement") has been concluded and entered in force with the approval of the electronic mail sent between Arasana İnternet İletişim ve Telekominikasyon Limited Şirketi, the operator of the website with the extension Boho Live, and the person (the "Member") who has read and electronically accepted the terms and conditions of this Agreement hereby and became a member by approving the electronic mail sent by the website.
        </p>
        <h3 class="title">1. SUBJECT</h3>
        <p>
          This Agreement hereby regulates the services offered on the website, conditions for the Member to benefit from these services and rights and obligations of the parties.
        </p>
        <h3 class="title">2. RIGHTS AND OBLIGATIONS</h3>
        <h3 class="title">2.1. Rights and Obligations of the Member</h3>
        <ul>
          <li>
            <b>2.1.1.</b> By concluding this Agreement hereby, the Member accepts the Terms of Use contained in the Agreement, explanations / information about how the website works, any other terms and conditions of membership, terms and conditions of use announced by the website regarding the membership and services.&nbsp;The Member accepts, declares and undertakes to act in accordance with all kinds of matters specified in the aforementioned explanations and/or conditions.
          </li>
          <li>
            <b>2.1.2.</b> The Member accepts and undertakes that the information and content provided by him/her to the website and to his/her profile (the <b>"Profile"</b>), which is a special introduction page for each member, which is created with the information provided by him/her and which can be viewed by other members without any restriction with the photo he/she has added, is correct and in accordance with the law.&nbsp;The Member cannot add personal contact information (e-mail address, residence address, telephone number, etc.) to his/her Profile under any circumstances. In case the Member's personal contact information is added to the website Profile, the website reserves the right to cancel the Member's membership.&nbsp;The Member shall be personally responsible for any damages arising from incorrect or incomplete information provided by the Member (including forgetting the password and/or Pin) and the Member shall be solely responsible for the termination of his/her membership for this reason.
          </li>
          <li>
            <b>2.1.3.</b> The Member shall in no way send messages to other members or in the website environment in a way that is threatening, immoral, contrary to the laws of the Republic of Turkey and legislation of other countries and shall not engage in such communications. The Member shall act in accordance with the public morality, etiquette and legal rules in all communications to be carried out on the website, shall not under any circumstances take actions that may be considered as an attack on the personal rights of other members, shall not harass other members in any way and shall not act in a way that will affect the members’ use of the website. The Member shall not make statements to the website or other members, third natural persons or legal entities which may create unfair competition and shall not publish or distribute such materials.
          </li>
          <li>
            <b>2.1.4.</b> The Member shall not use the information to be obtained within the framework of the website and information of other members in any way other than the activities permitted to be carried out within the framework of the website and shall not use it under any circumstances without the prior consent of the relevant information owner.
          </li>
          <li>
            <b>2.1.5.</b> The Member is exclusively responsible for the legal and criminal liability arising from all kinds of transactions and actions he/she performs on and/or through the website, including the private messaging and conversations with other members through the website, services performed instantly in the electronic environment, instantly delivered intangible goods and all products considered digital. The Member accepts, declares and undertakes that the website has no responsibility in this framework and accepts that all types of damages that may be incurred by the website can be recoursed to the Member. The Member shall be exclusively responsible for all legal and criminal consequences of his/her meetings with other members.
          </li>
          <li>
            <b>2.1.6.</b> The Member cannot use the website for commercial purposes under any circumstances and cannot advertise through the website.
          </li>
          <li>
            <b>2.1.7.</b> The Member accepts, declares and undertakes that he/she will not reproduce, copy, distribute, process the pictures, texts, visual and audio images, files, databases, software and any other copiable work or work-like elements on the website.
          </li>
          <li>
            <b>2.1.8.</b> The Member accepts and undertakes that the website is authorized to share information about the Member in accordance with the relevant legislation in case the personal information of the Member is requested by the official authorities in accordance with the provisions of the legislation in force.
          </li>
          <li>
            <b>2.1.9.</b> The Member cannot transfer this Agreement hereby or his/her rights and obligations under this Agreement hereby and/or his/her membership in any way, in whole or in part, to any third party without the prior written consent of the website and the membership cannot be made available to persons other than the Member himself/herself. The website cannot be held directly and/or indirectly liable in any way whatsoever for any damage which has already been incurred or may be suffered by third parties as a result of such transfers or unauthorized use.&nbsp;The membership of the Member who is found to have used or transferred his/her membership to someone else is cancelled.
          </li>
          <li>
            <b>2.1.10.</b> The Member accepts, declares and undertakes that in case of non-compliance with the provisions of this Agreement hereby, his/her membership can be terminated by the website unilaterally and without any compensation and without any notice. In case of the cancellation of membership, all credits owned by the Member at that time will be canceled without refund. The Member does not have any right to claim in this regard.
          </li>
          <li>
            <b>2.1.11.</b> In case the Member wishes to cancel his/her membership, he/she will request membership cancellation as specified in "how Arasana works" section regarding the membership cancellation.
          </li>
          <li>
            <b>2.1.12.</b> By approving and accepting this Membership Agreement hereby, the Member is deemed to have accepted the Confidentiality Agreement and Policy and Clarification Text on the Protection of Personal Data of the website.
          </li>
        </ul>
        <h3 class="title">2.2. Rights and Obligations of the Website</h3>
        <ul>
          <li>
            <b>2.2.1.</b> The website can unilaterally change the website content, terms of use/membership, fees and services offered at any time and without any notice. In this framework, the website can unilaterally change, delete the Member's Profile and/or the correspondence added to the website by the Member, request changes from the Member and unilaterally limit the Member's use of the website.
          </li>
          <li>
            <b>2.2.2.</b> The website can temporarily or completely be stopped the website at any time. The website will have no liability against the Member or third parties for the exercise of this right by the website. The website in no way guarantees the continuous and/or smooth operation of the website.
          </li>
          <li>
            <b>2.2.3.</b> The website does not in any way undertake that the Member will be called by other members or that any expectation will be met if called. The website is in no way responsible for any communication between the Members. The website is not responsible for any legal and/or criminal disputes that may arise as a result of the communication between Members and other Members.
          </li>
          <li>
            <b>2.2.4.</b> In case the website enables the Member to switch to other websites through redirections, the website shall not be held responsible in any way for the content of the website to which the Member will switch, services and/or products contained on these websites.
          </li>
          <li>
            <b>2.2.5.</b> The website will in no way be responsible against the Member for any material and/or moral damages which may be incurred due to the services provided on the website or other Members. At the same time, the website will under no circumstances be legally and criminally responsible for the unlawful acts and actions of the Member and other Members.
          </li>
          <li>
            <b>2.2.6.</b> The website accepts, declares and undertakes to use all kinds of personal information and data belonging to the Members by approving this Membership Agreement hereby in accordance with the Personal Data Protection Law No. 6698 and relevant legislation. The details on the use of personal data belonging to the Members are included in the Confidentiality Agreement and Policy and Clarification Text on the Protection of Personal Data of the website.
          </li>
        </ul>
        <h3 class="title">3. FORCE MAJEURE</h3>
        <p>
          In all cases legally deemed as force majeure, the website will not have any responsibility against the Member for late or incomplete performance or non-performance of any of its obligations specified in this Agreement hereby.&nbsp; In case of any force majeure, the Member cannot claim any compensation under any title from the website due to delay, incomplete performance or non-performance.
        </p>
        <p>
          The term "force majeure" shall be construed as unavoidable events which are beyond the reasonable control of the relevant party and which the website cannot prevent despite due diligence, including but not limited to natural disasters, riots, wars, strikes, communication problems, infrastructure and internet failures, power outages and bad weather conditions.
        </p>
        <h3 class="title">4. APPLICABLE LAW AND COMPETENT COURT</h3>
        <p>
          This Agreement hereby shall be governed by Turkish Law. In any dispute arising from this Agreement hereby, Istanbul Courts and Enforcement Offices are authorized.
        </p>
        <h3 class="title">5. TERMINATION OF THE AGREEMENT</h3>
        <p>
          This Agreement hereby shall remain in effect unless terminated by the Member or the website. In case the membership is for a certain period of time, the Agreement will automatically terminate upon the expiration of this period. The website can terminate this Agreement hereby unilaterally without any notice and without paying any compensation to the Member in case of the violation of the the terms and conditions of the Agreement or other terms and conditions on the website by the Member.
        </p>
      </div>
      <h3 class="settings-sub-title" id="privacy-policy">
        Privacy Policy
      </h3>
      <div class="settings-content mb-3">
        <h3 class="title">GENERAL TERMS AND CONDITIONS</h3>
        <h3 class="title">INTRODUCTION</h3>
        <p>
          Within the framework of this Privacy Policy, the information collected by Boho Live (The
          Contact Information is stated below) from https://boholive.app / (the "Website") and all
          mobile applications related to the Website (the "Application" or "Boho Live") (the Website
          and Application shall be referred to as “Boho Live" together) and visitors and users and how
          we use and protect them and our rights are explained.
        </p>
        <p>
          Boho Live does not allow users of our services to create accounts if they are a minor or
          under the age of 18 as required by the laws of the country in which you are located.
          Therefore, if you are under the said age requirement please do not use Boho Live.
        </p>
        <p>
          By accessing or using our Boho Live Services you acknowledge that you have read this Privacy
          Policy and understood your rights with respect to your personal data and how we will
          collect, process and use that data. If you do not accept this Privacy Policy or any part
          thereof, you should not use Nikki Services.
        </p>
        <h3 class="title">HOW WE COLLECT YOUR INFORMATION</h3>
        <p>
          When you register to Boho Live account in order to provide Boho Live Services and improve
          the user experience and safety of Boho Live Services, we collect, record, store, adapt, use,
          share or process your personal information.
        </p>
        <p>
          We collect your personal information on the following basis:
        </p>
        <ul>
          <li>
            In order to delivery the contractual services,
          </li>
          <li>
            You need to create an account and before using Boho Live Services, give your explicit
            consent for the processing of your personal data.
          </li>
          <li>
            In order to provide better experiences to you at Boho Live Services, we collect your
            personal data necessary for our legitimate interests.
          </li>
        </ul>
        <h3 class="title">Information Required for the Contractual Services</h3>
        <p>
          We only process the following personal information when it is necessary to deliver the
          contractual services as described in the Terms of Use under the Membership Agreement:
        </p>
        <ul>
          <li>
            Registration information such as your date of birth, phone number, e-mail, password,
            user identifier and
          </li>
        </ul>
        <h3 class="title">Information Consented</h3>
        <p>
          When you create an account in the Application or use Boho Live Services to upload content to
          the Application or contact us for technical support, you consent to provide some or all of
          the following personal information:
        </p>
        <ul>
          <li>
            Profile information such as your nickname, profile pictures, gender, where you live,
            information about yourself, social information, education and career,
          </li>
          <li>
            Comment, text, message, photo, image, video, audio, code and content created by the
            Users such as other data or materials that you upload to the Application and distribute
            or publish in the Application while using Boho Live Services,
          </li>
          <li>
            Your authorized choices and correspondence with us such as information used to verify
            your account or resolve your feedback or complaints,
          </li>
          <li>
            Information you provide when participating in in-app surveys and activities and
          </li>
          <li>
            nformation you provide from third party social media when you create a Nikki account by
            connecting with a third party social media such as YouTube, Facebook, Instagram, Google
            or when you connect a Nikki account to a third party social media account. The
            collection, use and disclosure of your information by a third party will be governed by
            the privacy policy of that third party service. In particular, Nikki's YouTube
            integration uses YouTube API Services and this usage is subject to the Google Privacy
            Policy (http://www.google.com/policies/privacy).&nbsp;On
            https://security.google.com/settings/security/permissions page, you can manage or
            withdraw Nikki YouTube integration's access to your data from Google security settings.
          </li>
        </ul>
        <h3 class="title">Cookies and Other Similar Data Collection Technologies</h3>
        <p>
          We can use cookies and similar technologies (e.g., web beacons, pixels, SDKs) to recognize
          you and/or your devices and allow others to do so. You can read our Cookies Policy to learn
          more about why we use them and how you can better control their usage.
        </p>
        <p>
          Some web browsers (such as Safari, Internet Explorer, Firefox and Chrome) have a "Do Not
          Track" ("DNT") feature that tells a website that the user does not want his/her&nbsp;online
          activity to be tracked. If a website that responds to the DNT signals receives a DNT signal,
          it is possible for the browser to prevent the website from collecting certain information
          about the browser user. Not all browsers offer the DNT option and the DNT signals are not
          yet of a single type. For this reason, many businesses, including Nikki, do not currently
          respond to the DNT signals.
        </p>
        <h3 class="title">HOW WE USE YOUR INFORMATION</h3>
        <p>
          The main reason we use your information is to provide and improve our services. In addition,
          we use your information to keep you safe and serve advertisements that may be of interest to
          you.
        </p>
        <h3 class="title">A. In order to manage your account and provide services to you</h3>
        <ul>
          <li>
            To create and manage your account
          </li>
          <li>
            To provide you with customer support and respond to your requests
          </li>
          <li>
            To complete your transactions
          </li>
          <li>
            To communicate with you about our services
          </li>
        </ul>
        <h3 class="title">B. In order to help you connect with other users</h3>
        <h3 class="title">C. In order to provide the new Nikki services to you</h3>
        <ul>
          <li>
            To register you and view your profile in new Nikki features and applications
          </li>
          <li>
            To manage your account in these new features and applications
          </li>
        </ul>
        <h3 class="title">D. In order to run advertising and marketing campaigns</h3>
        <ul>
          <li>
            To ensure and measure the effectiveness of advertising campaigns on our services and
            marketing campaigns promoting Nikki outside of our services
          </li>
          <li>
            To communicate with you about the products or services that we think may be of interest
            to you
          </li>
        </ul>
        <h3 class="title">E. In order to improve our services and develop new ones</h3>
        <ul>
          <li>
            To manage the focus groups, market researches and surveys
          </li>
          <li>
            To review interactions with the customer service teams to improve our service quality
          </li>
          <li>
            To understand how members typically use the services to improve them (for example, we
            may decide to change the look and feel or even significantly change a particular feature
            depending on how members react)
          </li>
          <li>
            To develop new features and services (for example, we may decide to create a new
            interest-based feature for requests received from members).
          </li>
        </ul>
        <h3 class="title">F. In order to detect, prevent and combat fraud and other illegal or
          unauthorized activity</h3>
        <ul>
          <li>
            To identify and review the existing, suspected or alleged violations of our Terms of
            Use, in particular through the review of notices and interactions between members
          </li>
          <li>
            To more clearly identify the violations of our Terms of Use and design countermeasures
          </li>
          <li>
            To store data related to the violations of our Terms of Use to prevent recurrence
          </li>
          <li>
            To enforce or exercise our rights such as our Terms of Use
          </li>
          <li>
            To communicate to people who submit notices what we do as a result of their notices
          </li>
        </ul>
        <h3 class="title">G. In order to ensure legal compliance</h3>
        <ul>
          <li>
            To comply with the legal requirements
          </li>
          <li>
            To support the law enforcement officers
          </li>
        </ul>
        <p>
          We rely on the following legal bases to process your information as described in this
          Privacy Policy:
        </p>
        <p>
          <b>In order to provide service to you:</b> The reason for processing your information in
          accordance with articles A, B and C above is to fulfill the requirements of your agreement
          with us. For example, when you use our service to make meaningful connections, we use your
          information to maintain your account and profile, make it more visible to others, recommend
          other members to you and provide our free and paid features to you and our other members.
        </p>
        <p>
          <b>Legitimate interests:</b> Based on our legitimate interests, we process your information
          in accordance with articles D, E and F above. For example, we analyze user behavior to
          continuously improve our services, suggest offers that may be of interest to you or help us
          promote our services, process information to help ensure the safety of our members and
          process data to exercise our rights when necessary, assist law enforcement authorities and
          enable us to defend ourselves in a legal situation.
        </p>
        <p>
          <b>In order to comply with the applicable laws and regulations:</b> We process your
          information in accordance with article G above when we are required to comply with the
          applicable laws and regulations and prove our compliance with the applicable laws and
          regulations. For example, we store usage traffic data and transaction-related data in
          accordance with our accounting, tax and other legal data storage obligations and in order to
          respond to valid access requests from the law enforcement authorities. We also store data
          proving the permissions given to us by the members and any decision made by the members to
          disable a particular feature or processing.
        </p>
        <h3 class="title">HOW WE SHARE YOUR INFORMATION</h3>
        <p>
          In order to prevent illegal uses, increase the number of our uses and help the improvement
          and optimization of Boho Live Services for our information development, engineering and
          analysis and internal business purposes, we can share your information with, including but
          not limited to, the following certain authorized third parties :
        </p><ul>
        <li>
          In order for us to improve Boho Live Services, with our business partners,
        </li>
        <li>
          With the third party advertising partners so that they can serve advertisements that
          they believe are relevant to you in other third party applications,
        </li>
        <li>
          With the cloud storage providers in order to store the information you provide,
        </li>
        <li>
          With the analytics and search engine providers helping us for the optimization and
          improvement of the experience of the user in Boho Live Services,
        </li>
        <li>
          With the Information Technology service providers and
        </li>
        <li>
          With any member, subsidiary, parent company or affiliate of the corporate group.
        </li>
      </ul>
        <p>
          Except for the above, we will not share or disclose your personal information with any third
          party unless under the following conditions:
        </p>
        <ul>
          <li>
            If you have explicit consent,
          </li>
          <li>
            In cases where it is mandatory to share with the third parties in order to comply with
            the relevant laws and regulations and government requests,
          </li>
          <li>
            With the necessity of protecting the public interest in good faith,
          </li>
          <li>
            In cases where it is necessary to share data in order to protect the safety and
            fundamental rights of another person,
          </li>
          <li>
            If deemed necessary to address fraud, security or technical issues,
          </li>
          <li>
            In cases where deemed necessary in order to enforce our terms and conditions and ensure
            the ordinary activity of Boho Live Services.
          </li>
        </ul>
        <h3 class="title">THIRD PARTY APPLICATIONS</h3>
        <p>
          We can allow you to access content, products or services offered by the relevant third
          parties through Nikki Services, hyperlinks, APIs (such as by means of a hashtag, banner,
          channel or other ways) or websites of third parties (such as YouTube, Twitter, Instagram,
          etc.). You are advised to read the terms and conditions and/or privacy policy of such
          websites before using the websites. Nikki's integration with YouTube uses YouTube API
          Services. By using YouTube integration, you agree to be bound by YouTube Terms of Service
          (https://www.youtube.com/t/terms) and that such use is subject to Google Privacy Policy
          (http://www.google.com/policies/privacy). You can manage and withdraw access of YouTube
          integration to your data through Google security settings page at
          https://security.google.com/settings/security/permissions.
        </p>
        <p>
          You agree that Nikki does not have any control over the websites of the relevant third
          parties, does not monitor the relevant websites and shall not be responsible or liable to
          anyone for the relevant websites or any content, products or services offered on or through
          the relevant websites.
        </p>
        <h3 class="title">YOUR RIGHTS</h3>
        <p>
          It is one of our main purposes to make sure that you have control of your information.
          Therefore, we want to remind you of the options and tools available:
        </p>
        <ul>
          <li>
            <b>&gt;Access/Update tools in the Service.</b> The tools and account settings can help you
            access, correct or delete information that you have shared with us and that is
            associated with your account directly from within the service.
          </li>
          <li>
            <b>Device permissions.</b> The mobile platforms can have permission systems for specific
            device data and types of notifications such as phone contacts, photos, location services
            and instant message notifications and advertising identifiers. You can change your
            settings on your device to allow or oppose the collection and processing of information
            concerning the aforementioned and display of relevant notifications. Please note that in
            such a case, some services may lose their full functionality.
          </li>
          <li>
            <b>Uninstallation.</b> You can end the collection of all information by uninstalling the
            application by using your device's standard uninstallation process. Keep in mind that
            uninstalling an application DOES NOT close your account. To close your account, please
            use the relevant function in the service.
          </li>
          <li>
            <b>Account closure.</b> You can close your account using the relevant function offered
            in the service.
          </li>
        </ul>
        <p>
          We also want you to be aware of your privacy rights. Some important things to remember:
        </p>
        <ul>
          <li>
            <b>Review your information.</b> The applicable privacy laws can entitle you with the
            right to review the personal information we have about you (depending on the
            jurisdiction, this right can be referred to as the right to access, right to
            transportability, right to know or other).
          </li>
          <li>
            <b>Update your information.</b> If you believe that the information we hold about you is
            inaccurate or that we are no longer authorized to use them, if you request them to be
            corrected or deleted or if you object or oppose to their processing, please contact us.
          </li>
        </ul>
        <p>
          Please note that we may reject these requests for a variety of reasons including inability
          to verify your identity, illegal or invalid request or violation of the trade secrets or
          intellectual property or confidentiality of others or other rights.
        </p>
        <p>
          In addition, in case of the elimination of our possibility to provide service to you due to
          the requests for objection or blocking with respect to the processing of personal data, we
          may not be able to fulfill these requests. For example, if we do not have your date of
          birth, we cannot be sure that you are at least 18 years old or older and therefore we cannot
          provide our service.
        </p>
        <p>
          In some countries, including the European Economic Area and the United Kingdom, if you have
          concerns about how we process your personal information, you have the right to file a
          complaint to the appropriate data protection authority. The data protection authority to
          which you can file a complaint can be your residence, place you work or place where the
          alleged violation occurred.
        </p>
        <h3 class="title">HOW WE TRANSFER YOUR INFORMATION</h3>
        <p>
          We use servers worldwide and your information collected by us is initially stored and
          processed on servers close to your region, but in various cases, your information can be
          stored and processed in all other countries where we carry out the data collection
          transactions. In terms of our data transfer practices, compliance with the relevant data
          localization rules has been ensured. We take appropriate steps to ensure the processing of
          the information we have collected in accordance with the privacy policy and applicable laws
          and regulations. In case we share any information about you with any legal entity, we take
          the appropriate measures to ensure that the relevant receiving entities adequately protect
          your information in accordance with this Privacy Policy and applicable laws.
        </p>
        <h3 class="title">HOW LONG WE KEEP YOUR INFORMATION</h3>
        <p>
          We keep your information as long as it is required to provide Boho Live Services in order to
          fulfil our contractual liabilities and rights in relation to such information or to comply
          with the legal liabilities, settle disputes and enforce our agreements and for other
          legitimate purposes. Since these needs vary according to different types of information, the
          scope of interactions with you or use of Boho Live Services results in different storage
          periods for different types of information. When we do not need your information in order to
          provide Boho Live Services, we only store them as long as we have reasonable business
          purposes for storing such data.
        </p>
        <h3 class="title">HOW YOUR INFORMATION IS MANAGED</h3>
        <p>
          The privacy settings allow you to control whether certain information is shared and whether
          you want to benefit from certain functions.
        </p>
        <p>
          You can also delete your account. You can use the "Delete Account" function in the Settings
          to delete your account. Once your account is deleted, you will not be able to recover your
          account and all of your historical data in Nikki Services will be deleted after a certain
          period of time (usually within 24 hours), except for certain behavioral data that will be
          anonymized for statistical research purposes.
        </p>
        <p>
          Keep in mind that certain third parties may keep copies of your information even after your
          account is deleted.
        </p>
        <h3 class="title">SECURITY OF YOUR INFORMATION</h3>
        <p>
          We take appropriate administrative, technical and physical security measures to protect your
          personal information from unauthorized access and disclosure. For example, only our
          authorized employees are allowed to access your personal information and can only do so for
          permitted business purposes. We also use encryption in the transmission of certain personal
          information between your system and ours and we use firewalls to help prevent unauthorized
          persons from gaining access to your personal information. Nevertheless, we inform you that
          we are unable to completely eliminate the security risks associated with the storage and
          transmission of your personal information. When sending information through Boho Live
          Services, you should be careful and when deciding which information to provide, you should
          pay special attention.
        </p>
        <p>
          You are always responsible for maintaining the confidentiality of your password and account
          information.
        </p>
        <h3 class="title">PRIVACY AND PROTECTION OF CHILDREN</h3>
        <p>
          We have no tolerance for any form of child abuse or misconduct. Your data can be shared with
          legal entities (including private messages) if we believe in our own good faith that you are
          involved in child abuse or if we are asked to do so by the relevant legal entities.
        </p>
        <p>
          We have no tolerance for any form of child abuse or misconduct. Your data can be shared with
          legal entities (including private messages) if we believe in our own good faith that you are
          involved in child abuse or if we are asked to do so by the relevant legal entities.
        </p>
        <p>
          Our services are limited to individuals aged 18 or older. We do not allow people under the
          age of 18 to use our platform. If you suspect that a member is under the age of 18, please
          use the reporting mechanism included in the service.
        </p>
        <h3 class="title">PRIVACY POLICY CHANGES</h3>
        <p>
          This policy can be changed in time as we look for new and innovative ways to ensure that you
          always make meaningful connections and to ensure that descriptions about our data practices
          remain up to date. In order for you to have time to review the changes, we will notify you
          before any material changes enter in force.
        </p>
        <h3 class="title">AMENDMENTS</h3>
        <p>
          We can amend any term of this Privacy Policy by publishing the relevant term. We will also
          update the "Last Update" date specified at the top of this Policy reflecting the effective
          date of this Policy. In case of the continuation of your access to Boho Live Services or use
          of these services after the date of updated policy, it is deemed as your acceptance of the
          updated policy. In case you do not agree to the updated policy, you should cease your access
          to Boho Live Services or use of these services.
        </p>
        <h3 class="title">CONTACT INFORMATION</h3>
        <p>
          (Dikilitas Mah. Hakki Yeten Cad. Selenium Plaza No:10 İç kapı no 8 34349 Besiktas / ISTANBUL
          - TURKEY)
        </p>
        <p>
          If you have any questions about this Policy or our practices regarding the use of
          information, please contact us from support @boholive.app
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },
  }
};
</script>
