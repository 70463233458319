export function chatConnection (state) {
  return state.chatConnection
}
export function connection (state) {
  return state.connection
}
export function connecting (state) {
  return state.connecting
}
export function connected (state) {
  return state.connected
}
