<template>
  <div id="loading-spinner">
    <b-img :src="loadingImage" :width="width" :height="height"/>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  props: {
    width: {
      type: Number,
      default: 72
    },
    height: {
      type: Number,
      default: 72
    }
  },
  components: {
    BImg,
  },
  data () {
    return {
      loadingImage: require('@/assets/images/loading/loading-transparent.gif')
    }
  }
}
</script>

<style lang="scss" scoped>
#loading-spinner {
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
}
</style>
