export function setAgoraCalling ({ commit }, payload) {
  commit('setAgoraCalling', payload)
}
export function setAgoraCallUser ({ commit }, payload) {
  commit('setAgoraCallUser', payload)
}
export function setAgoraVideoCallUser ({ commit }, payload) {
  commit('setAgoraCallUser', payload)
}
export function setAgoraCallType ({ commit }, payload) {
  commit('setAgoraCallType', payload)
}
export function setAgoraClient ({ commit }, payload) {
  commit('setAgoraClient', payload)
}
export function setAgoraOptions ({ commit }, payload) {
  commit('setAgoraOptions', payload)
}
export function setAgoraChannelParameters ({ commit }, payload) {
  commit('setAgoraChannelParameters', payload)
}
export function setCallRequestInfo ({ commit }, payload) {
  commit('setCallRequestInfo', payload)
}
export function setShowAgoraCall ({ commit }, payload) {
  commit('setShowAgoraCall', payload)
}
export function setShowAgoraVideoCall ({ commit }, payload) {
  commit('setShowAgoraVideoCall', payload)
}
