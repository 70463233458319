export function setLoading (state, payload) {
  state.loading = payload
}
export function setPaymentData (state, payload) {
  state.paymentData = payload
}
export function setPaymentHtml (state, payload) {
  state.paymentHtml = payload
}
export function setStatus (state, payload) {
  state.status = payload
}
