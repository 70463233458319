export function updateConnection ({ commit }, payload) {
  commit('updateConnection', payload)
}
export function updateChatConnection ({ commit }, payload) {
  commit('updateChatConnection', payload)
}
export function updateConnecting ({ commit }, payload) {
  commit('updateConnecting', payload)
}
export function updateConnected ({ commit }, payload) {
  commit('updateConnected', payload)
}
