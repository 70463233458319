<template>
  <div class="remove-account">
    <b-overlay :show="loading" variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <b-row>
        <b-col md="8" sm="12">
          <h3 class="mb-2">
            {{ $t('Delete Account') }}
          </h3>
          <p class="remove-account-information">
            {{ $t('pages.deleteAccount.description') }}
          </p>
          <validation-observer
              v-if="reasons.length"
              ref="loginForm"
              #default="{invalid}"
          >
            <b-form
                class="mt-2"
                @submit.prevent
            >

              <b-form-group
                  label-for="reason"
                  :label="$t('Account deleting reason')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Account deleting reason')"
                    vid="reason"
                    rules="required"
                >
                  <v-select
                      v-model="reason"
                      :clearable="false"
                      label="name"
                      :options="reasons"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                  variant="primary"
                  class="mt-2 px-4"
                  :disabled="!reason"
                  @click="showRemoveModal"
              >
                {{ $t('Delete My Account') }}
              </b-button>
            </b-form>
          </validation-observer>
          <p class="remove-account-information mt-3">
            {{ $t('pages.deleteAccount.information') }}
          </p>
        </b-col>
      </b-row>

      <b-modal v-model="removeModal" centered hide-header-close hide-footer size="md">
        <div class="mb-1 fs-22 fw-700 color-black">
          {{ $t('pages.deleteAccount.confirmTitle') }}
        </div>
        <div class="mb-3 fs-16 color-gray-2" style="font-style: italic">
          {{ $t('pages.deleteAccount.confirmDescription') }}
        </div>
        <b-button class="px-4 mr-2" variant="light" size="sm" @click="hideRemoveModal">
          {{ $t('Cancel') }}
        </b-button>
        <b-button class="px-4" variant="primary" size="sm" @click="remove">
          {{ $t('Delete') }}
        </b-button>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BButton, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BOverlay, BRow } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import LoadingSpinner from '@/components/LoadingSpinner'
import { gtmTrackNewEvent, showNotify, throwDefaultError } from "@/helpers/helpers";
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,

    LoadingSpinner,

    ValidationObserver,
    ValidationProvider,

    vSelect,
  },
  data() {
    return {
      loading: false,
      reason: null,
      reasons: [],
      removeModal: false,
    }
  },
  created() {
    this.getReasons()
  },
  methods: {
    getReasons() {
      useJwt.getReasons()
          .then(response => {
            this.reasons = response.data.body
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    hideRemoveModal() {
      this.removeModal = false

      gtmTrackNewEvent('accountremovecancel', {
        category: 'HesapSilme',
        action: 'AccountRemoveCancel',
      })
    },
    remove() {
      if (this.reason && this.reason.id) {
        this.loading = true
        useJwt.removeAccount(this.reason.id)
            .then(response => {
              showNotify(this.$toast, this.$t('The account has been deleted'), 'success')

              gtmTrackNewEvent('accountremove', {
                category: 'HesapSilme',
                action: 'AccountRemove',
              })

              this.$router.push({name: 'auth-login'})
            })
            .catch(error => {
              throwDefaultError(this.$toast, error)
            })
            .finally(() => { this.loading = false })
      }
    },
    showRemoveModal() {
      this.removeModal = true

      gtmTrackNewEvent('accountremoveopen', {
        category: 'HesapSilme',
        action: 'AccountRemoveOpen',
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.remove-account-information {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #8893A4;

  a {
    color: #F9447A;
    text-decoration: underline;
  }
}
</style>
