import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import agora from './agora'
import app from './app'
import appConfig from './app-config'
import auth from './auth'
import payment from './payment'
import search from './search'
import socket from './socket'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    agora,
    app,
    appConfig,
    auth,
    payment,
    search,
    socket,
    verticalMenu
  },
  strict: process.env.DEV,
})
