<template>
  <div class="settings-interests">
    <b-overlay :show="loading" variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <div v-if="interests">
        <b-row>
          <b-col>
            <h2 class="settings-title">
              {{ $t('Interest Settings') }}
            </h2>
          </b-col>
        </b-row>

        <validation-observer
            ref="form"
            #default="{invalid}"
        >
          <b-form
              class="auth-login-form mt-2"
              @submit.prevent="update"
          >
            <b-row>
              <b-col md="6">
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Interests')"
                    vid="hobbies"
                    rules="required"
                >
                  <b-form-group
                      :label="$t('Choose your interests')"
                      label-for="hobbies"
                      :state="errors.length > 0 ? false:null"
                  >
                    <v-select
                        id="city"
                        multiple
                        v-model="myInterests"
                        :clearable="false"
                        item-value="code"
                        :get-option-label="(option) => option.name"
                        :options="interests"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="12">
                <b-button
                    class="mt-2  px-4"
                    type="submit"
                    variant="primary"
                    :disabled="loading || invalid"
                >
                  <b-spinner v-if="loading" small></b-spinner>
                  {{ $t('Save') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BButton, BCol, BForm, BFormGroup, BImg, BOverlay, BRow, BSpinner } from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import useJwt from "@/auth/jwt/useJwt";
import { throwDefaultError, showNotify } from "@/helpers/helpers";
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BImg,
    BOverlay,
    BRow,
    BSpinner,

    vSelect,
    VueSlider,

    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      interests: [],
      myInterests: [],
      loading: false,
    }
  },
  created() {
    this.getInterests()
    this.getMyInterests()
  },
  methods: {
    getInterests () {
      useJwt.getInterests()
          .then(response => {
            try {
              this.interests = response.data.body
            } catch (e) {
              showNotify(this.$toast, this.$t('Interests could not be loaded. Please try again.'), 'danger')
            }
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
    },
    getMyInterests () {
      this.loading = true
      useJwt.getMyInterests()
          .then(response => {
            try {
              this.myInterests = response.data.body
            } catch (e) {
              showNotify(this.$toast, this.$t('Interests could not be loaded. Please try again.'), 'danger')
            }
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    update() {
      this.loading = true
      useJwt.updateMyInterests(this.myInterests.map(i => i.code))
          .then(response => {
            showNotify(this.$toast, this.$t('Update successful'), 'success')
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    }
  }
}
</script>
