export function agoraCalling (state) {
  return state.agoraCalling
}
export function agoraCallUser (state) {
  return state.agoraCallUser
}
export function agoraVideoCallUser (state) {
  return state.agoraVideoCallUser
}
export function agoraCallType (state) {
  return state.agoraCallType
}
export function agoraClient(state) {
  return state.agoraClient
}
export function agoraOptions (state) {
  return state.agoraOptions
}
export function agoraChannelParameters (state) {
  return state.agoraChannelParameters
}
export function callRequestInfo (state) {
  return state.callRequestInfo
}
export function showAgoraCall (state) {
  return state.showAgoraCall
}
export function showAgoraVideoCall (state) {
  return state.showAgoraVideoCall
}
