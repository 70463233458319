<template>
  <b-modal v-model="status" :centered="centered" hide-header-close hide-footer hide-header no-close-on-backdrop
           :size="size">
    <div class="payment-modal">
      <div>
        <div class="loading-spinner">
          <loading-spinner />
        </div>
        <slot>
          <iframe :srcdoc="paymentData" width="100%" height="100%" id="paymentIframe"></iframe>
        </slot>
      </div>
      <hr>
      <div class="text-right">
        <b-button variant="outline-dark" size="sm" @click="status = false;" :disabled="loading">
          {{ $t('Close') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BButton, BModal, BOverlay } from 'bootstrap-vue'

export default {
  name: 'Payment',
  props: {
    centered: {
      type: Boolean,
      default: true,
    },
    paymentData: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'lg',
    },
  },
  components: {
    BButton,
    BModal,
    BOverlay,
  },
  data() {
    return {
      view: true,
    }
  },
  computed: {
    loading() {
      return this.$store.getters['payment/loading']
    },
    status: {
      get() {
        return this.$store.getters['payment/status']
      },
      set(value) {
        this.$store.dispatch('payment/setStatus', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.loading-spinner {
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  z-index: -1;
}
.payment-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;

  div:first-child {
    height: 540px;
  }

  iframe {
    border: none;
  }
}
</style>