<template>
  <div id="agora-video-call">
    <b-overlay :show="loading" variant="transparent" rounded="sm">
      <b-modal v-model="expanded" centered hide-header-close hide-footer hide-header size="lg">
        <div class="d-flex justify-content-end fs-12 color-gray cursor-pointer" @click="expanded = false">
          {{ $t('Minimize') }}
        </div>
        <div id="video-container">
          <div ref="remotePlayerContainer" id="remotePlayerContainer" :class="{blur: hasNudity}">
            <b-img v-if="agoraCallUser" :src="agoraCallUser.profile.image" />
          </div>
          <div ref="localePlayerContainer" id="localePlayerContainer" :class="{blur: hasNudity}">
            <b-img v-if="profileImage" :src="profileImage" />
          </div>
          <div class="call-information" v-if="agoraCallUser">
            <h1>
              <b-avatar
                  size="24"
                  :src="agoraCallUser.profile.image"
              />
              {{ agoraCallUser.userName }}
            </h1>
            <p class="status">
              {{ message }}
            </p>
          </div>
          <div class="agora-call-buttons">
            <div class="incoming-buttons">
              <b-img v-if="agoraCallType === 'incoming' && callStatus !== 'talking'" :src="iconCallAccept" width="32" @click="accept" />
              <b-img v-if="agoraCallType === 'incoming' && callStatus !== 'talking'" :src="iconCallReject" width="32" @click="reject" />
            </div>
            <feather-icon v-if="callStatus === 'talking' && localeMuted" icon="MicOffIcon" @click="localeMuteToggle"
                          style="width: 26px; height: 26px; color: red; margin: 3px 4px 0 0;" />
            <feather-icon v-if="callStatus === 'talking' && !localeMuted" icon="MicIcon" @click="localeMuteToggle"
                          style="width: 26px; height: 26px; color: green; margin: 3px 4px 0 0;" />
            <feather-icon v-if="callStatus === 'talking' && localeVideoPaused" icon="VideoOffIcon" @click="localeVideoToggle"
                          style="width: 26px; height: 26px; color: red; margin: 3px 4px 0 0;" />
            <feather-icon v-if="callStatus === 'talking' && !localeVideoPaused" icon="VideoIcon" @click="localeVideoToggle"
                          style="width: 26px; height: 26px; color: green; margin: 3px 4px 0 0;" />
            <b-img v-if="callStatus === 'talking' || (agoraCallType === 'outgoing' && callStatus === 'calling')" :src="iconCallReject" width="32" @click="leave" />
          </div>
          <div class="video-information">
            Hey
          </div>
        </div>
      </b-modal>
      <div v-if="!expanded" class="not-expanded" @click="expanded = true">
        <div v-if="callStatus === 'calling'" class="blink"></div>
        <div v-if="callStatus === 'talking'" class="static-blink"></div>
        <b-avatar
            v-if="agoraCallUser"
            size="64"
            :src="agoraCallUser.profile.image"
        />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { BAvatar, BButton, BImg, BOverlay } from 'bootstrap-vue'
import AgoraRTC from 'agora-rtc-sdk-ng'
import useJwt from '@/auth/jwt/useJwt'
import {showNotify, throwDefaultError} from "@/helpers/helpers";

export default {
  name: 'AgoraVideoCall',
  components: {
    BAvatar,
    BButton,
    BImg,
    BOverlay,
  },
  data() {
    return {
      base64ImageType: 'image/jpeg',
      callStatus: 'idle',
      channelId: null,
      expanded: false,
      hasNudity: false,
      heartBeatStatus: 1,
      iconCallAccept: require('@/assets/images/icons/call-accept.png'),
      iconCallReject: require('@/assets/images/icons/call-reject.png'),
      intervalForTime: null,
      intervalForHeartBeat: null,
      localeMuted: false,
      localPlayerContainer: null,
      localeVideoPaused: false,
      loading: false,
      message: '',
      remotePlayerContainer: null,
      remoteUser: null,
      remoteUserMuted: false,
      sendHeartBeat: true,
      soundEndCall: new Audio(require('@/assets/sounds/agora-end-call.wav')),
      soundIncomingCall: new Audio(require('@/assets/sounds/agora-incoming-call.wav')),
      soundOutgoingCall: new Audio(require('@/assets/sounds/agora-outgoing-call.wav')),
      stream: null,
      time: 0,
      timeCall: 0,
      timeCallInterval: null,
      timeIncomingCallTimeout: 30,
      timeOutgoingCallTimeout: 31,
      userAgoraInformation: null,
      videoWidth: 720,
      videoHeight: 1280,
    }
  },
  async created() {
    this.message = this.$t('Connecting') + '...'
    await this.startAgoraClient()
    this.message = this.$t('Connected')

    if (this.agoraClient && this.agoraCallUser) {
      if (this.agoraCallType === 'outgoing') {
        this.expanded = true
        this.message = this.$t('Calling') + '...'
        await this.startCallingTime()
        await this.startCall()
      } else if (this.agoraCallType === 'incoming') {
        this.callStatus = 'calling'
        this.message = this.$t('Incoming call') + '...'
        await this.startCallingTime()
        // Heartbeat göndermeyi başlat
        this.heartBeatStatus = 6
        this.startSendHeartBeat(this.callRequestInfo.agoraCall.callId, 5)
      }
    }

    // Cancel call gelmesini dinle
    if (this.connection) {
      this.connection.on('cancelCallRequest', async (data, ack) => {
        ack('cancelCallRequestACK')

        await this.leave()
      })
    }
  },
  destroyed() {
    // Interval leri temizle
    this.clearIntervals()

    this.stopAllSounds()

    setTimeout(() => {
      window.location.reload()
    }, 1000)
  },
  watch: {
    callStatus() {
      this.stopAllSounds()

      if (this.callStatus === 'calling') {
        if (this.agoraCallType === 'outgoing' && this.soundOutgoingCall) {
          this.soundOutgoingCall.loop = true;
          this.soundOutgoingCall.play()
        } else if (this.agoraCallType === 'incoming' && this.soundOutgoingCall) {
          this.soundOutgoingCall.loop = true;
          this.soundIncomingCall.play()
        }
      } else if (this.callStatus === 'ended' && this.soundEndCall) {
        this.soundEndCall.play()
      }
    },
    connecting(newValue, oldValue) {},
    expanded(value) {
      if (value) {
        setTimeout(() => {
          if (this.agoraChannelParameters.localVideoTrack) {
            this.agoraChannelParameters.localVideoTrack.play(this.$refs.localePlayerContainer);
          }
          if (this.agoraChannelParameters.remoteVideoTrack) {
            this.agoraChannelParameters.remoteVideoTrack.play(this.$refs.remotePlayerContainer);
          }
        }, 33)
      }
    },
    timeCall(val) {
      if (this.agoraCallType === 'incoming') {
        if (val >= this.timeIncomingCallTimeout) {
          this.reject()
        }
      } else if (this.agoraCallType === 'outgoing') {
        if (val >= this.timeOutgoingCallTimeout) {
          showNotify(this.$toast, this.$t('User didn\'t answer the call'), 'info')
          this.leave()
        }
      }
    }
  },
  computed: {
    agoraCallUser() {
      return this.$store.getters['agora/agoraCallUser']
    },
    agoraCallType() {
      return this.$store.getters['agora/agoraCallType']
    },
    agoraChannelParameters: {
      get() {
        return this.$store.getters['agora/agoraChannelParameters']
      },
      set(value) {
        this.$store.dispatch('agora/setAgoraChannelParameters', value)
      }
    },
    agoraClient() {
      return this.$store.getters['agora/agoraClient']
    },
    agoraOptions() {
      return this.$store.getters['agora/agoraOptions']
    },
    callRequestInfo() {
      return this.$store.getters['agora/callRequestInfo']
    },
    connection() {
      return this.$store.getters['socket/connection']
    },
    connecting() {
      return this.$store.getters['socket/connecting']
    },
    profileImage() {
      return this.$store.getters['auth/profileImage']
    },
  },
  methods: {
    async accept() {
      if (this.callRequestInfo) {
        await this.$store.dispatch('agora/setAgoraOptions', {
          channel: this.callRequestInfo.agoraCall.agoraChannelName,
          token: this.callRequestInfo.agoraCall.agoraToken,
          uid: this.callRequestInfo.agoraCall.agoraUid,
        })
        await this.join()
      }
    },
    async cancelCall() {
      if (this.agoraCallType === 'outgoing' && this.userAgoraInformation) {
        if (this.sendHeartBeat) {
          useJwt.appCallCancel({callId: this.userAgoraInformation.callId})
        }
      }

      if (this.agoraCallType === 'incoming' && this.callRequestInfo) {
        useJwt.appCallCancel({callId: this.callRequestInfo.agoraCall.callId})
      }
    },
    async clearIntervals() {
      await this.stopCallingTime()

      if (this.intervalForTime) {
        clearInterval(this.intervalForTime)
        this.intervalForTime = null
      }
      if (this.intervalForHeartBeat) {
        clearInterval(this.intervalForHeartBeat)
        this.intervalForHeartBeat = null
      }

      // Destroy the local video track.
      if (this.agoraChannelParameters?.localVideoTrack) {
        await this.agoraChannelParameters.localVideoTrack.stop();
        await this.agoraChannelParameters.localVideoTrack.close();
        this.agoraChannelParameters.localVideoTrack = null
      }

      // Destroy the local audio track.
      if (this.agoraChannelParameters?.localAudioTrack) {
        await this.agoraChannelParameters.localAudioTrack.stop();
        await this.agoraChannelParameters.localAudioTrack.close();
        this.agoraChannelParameters.localAudioTrack = null
      }

      // Destroy the remote video track.
      if (this.agoraChannelParameters?.remoteVideoTrack) {
        await this.agoraChannelParameters.remoteVideoTrack.stop();
        await this.agoraChannelParameters.remoteVideoTrack.close();
        this.agoraChannelParameters.remoteVideoTrack = null
      }
    },
    async closeAgoraCall(callId, sendCompletedHeartBeat = true) {
      if (sendCompletedHeartBeat && callId) {
        // Backend e tamamlandı olarak heart beat gönder
        await this.heartBeat({callId: callId, status: 3})
      }

      this.callStatus = 'ended'

      // Interval leri temizle
      await this.clearIntervals()

      // Leave the channel
      await this.agoraClient.leave()
      this.message = this.$t('Closed')
      this.callStatus = 'idle'

      await this.$store.dispatch('agora/setAgoraCalling', false)
      await this.$store.dispatch('agora/setAgoraCallUser', null)
      await this.$store.dispatch('agora/setCallRequestInfo', null)
      await this.$store.dispatch('agora/setShowAgoraCall', false)
      await this.$store.dispatch('agora/setShowAgoraVideoCall', false)
    },
    getDateForHuman(timeLeft) {
      if (timeLeft <= 0) {
        return '00:00'
      }

      let result = ''
      let totalSeconds = timeLeft;

      const hours = Math.floor(totalSeconds / (60 * 60))
      totalSeconds -= hours * (60 * 60)

      const minutes = Math.floor(totalSeconds / (60))
      totalSeconds -= minutes * 60

      const seconds = Math.floor(totalSeconds % 60)

      result = minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')

      if (hours > 0) {
        result = hours.toString().padStart(2, '0') + ':' + result
      }

      return result
    },
    heartBeat(payload) {
      if (this.sendHeartBeat) {
        useJwt.heartBeat(payload)
            .then(response => {
              const { nudity, callerNudity, calleeNudity } = response.data.body

              this.hasNudity = nudity
            })
            .catch(error => {
              const errorResponse = error.response
              if (errorResponse && errorResponse.status === 400 && errorResponse.data?.header?.error?.errorCode == "514") {
                showNotify(this.$toast, this.$t('User didn\'t answer the call'), 'info')
                this.sendHeartBeat = false
                if (this.agoraCallType === 'outgoing') {
                  this.leave()
                } else if (this.agoraCallType === 'incoming') {
                  this.reject()
                }
              } else if (errorResponse && errorResponse.status === 400 && errorResponse.data?.header?.error?.errorCode == "517") {
                showNotify(this.$toast, this.$t('Your credit is over.'), 'info')
                this.sendHeartBeat = false
                if (this.agoraCallType === 'outgoing') {
                  this.leave()
                } else if (this.agoraCallType === 'incoming') {
                  this.reject()
                }
              }
              // else if (errorResponse && errorResponse.status === 400 && errorResponse.data?.header?.error?.errorCode == "532") {
              //   showNotify(this.$toast, this.$t(errorResponse.data?.header?.error?.message) + '. ' + this.$t('Conversation ended.'), 'info')
              //   this.sendHeartBeat = false
              //   if (this.agoraCallType === 'outgoing') {
              //     this.leave()
              //   } else if (this.agoraCallType === 'incoming') {
              //     this.reject()
              //   }
              // }
            })
      }
    },
    async join() {
      const uid = this.agoraCallType === 'incoming' ? this.agoraOptions.uid.toString() : this.agoraOptions.uid
      this.channelId = await this.agoraClient.join(this.agoraOptions.appId, this.agoraOptions.channel, this.agoraOptions.token, parseInt(this.agoraOptions.uid))

      // Create a local audio track from the audio sampled by a microphone.
      this.agoraChannelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      // Create a local video track from the video captured by a camera.
      await AgoraRTC.createCameraVideoTrack().then(track => {
        this.agoraChannelParameters.localVideoTrack = track
        // Play the local video track.
        this.agoraChannelParameters.localVideoTrack.play(this.$refs.localePlayerContainer);
      });
    },
    async leave() {
      // Süreyi durdur
      clearInterval(this.intervalForTime)

      // Store'da çağrı durumunu false yap
      await this.$store.dispatch('agora/setAgoraCalling', false)

      // Arama yapılıyor aşamasındaysa backend e arama bitirme isteği gönder
      if (this.callStatus === 'calling') {
        await this.cancelCall()
      }

      setTimeout(() => {
        if (this.agoraCallType === 'outgoing') {
          this.closeAgoraCall(this.userAgoraInformation.callId)
        } else  if (this.agoraCallType === 'incoming') {
          this.closeAgoraCall(null)
        }
      }, 1)

      // Refresh the page for reuse
      // window.location.reload();
    },
    async localeMuteToggle() {
      if (this.agoraChannelParameters?.localAudioTrack) {
        if (this.localeMuted) {
          await this.agoraChannelParameters.localAudioTrack.setMuted(false)
          this.localeMuted = false
        } else {
          await this.agoraChannelParameters.localAudioTrack.setMuted(true)
          this.localeMuted = true
        }
      } else {
        if (this.localeMuted) {
          try {
            // Create a local audio track from the audio sampled by a microphone.
            this.agoraChannelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            await this.agoraChannelParameters.localAudioTrack.setEnabled(true)
            // Create a local video track from the video captured by a camera.
            // Create a local video track from the video captured by a camera.
            await AgoraRTC.createCameraVideoTrack().then(track => {
              this.agoraChannelParameters.localVideoTrack = track
            });
            await this.agoraChannelParameters.localVideoTrack.setEnabled(true)

            this.localeMuted = false
          } catch (e) {
            this.localeMuted = true
          }
        }
      }
    },
    async localeVideoToggle() {
      if (this.agoraChannelParameters?.localVideoTrack) {
        if (this.localeVideoPaused) {
          await this.agoraChannelParameters.localVideoTrack.setEnabled(true)
          await this.agoraChannelParameters.localVideoTrack.play(this.$refs.localePlayerContainer)
          await this.agoraClient.publish(this.agoraChannelParameters.localVideoTrack)
          this.localeVideoPaused = false
        } else {
          await this.agoraClient.unpublish()
              .then(() => {
                this.agoraChannelParameters.localVideoTrack.setEnabled(false)
                this.agoraChannelParameters.localVideoTrack.stop()
                this.localeVideoPaused = true
                if (this.agoraChannelParameters?.localAudioTrack && !this.localeMuted) {
                  setTimeout(() => {
                    this.agoraClient.publish(this.agoraChannelParameters.localAudioTrack)
                  }, 111)
                }
              })
              .catch(error => {})
        }
      } else {
        if (this.localeVideoPaused) {
          try {
            // Create a local audio track from the audio sampled by a microphone.
            this.agoraChannelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            await this.agoraChannelParameters.localAudioTrack.setEnabled(true)
            // Create a local video track from the video captured by a camera.
            // Create a local video track from the video captured by a camera.
            await AgoraRTC.createCameraVideoTrack().then(track => {
              this.agoraChannelParameters.localVideoTrack = track
            });
            await this.agoraChannelParameters.localVideoTrack.setEnabled(true)
            // Publish the local audio and video tracks in the channel.
            await this.agoraClient.publish([this.agoraChannelParameters.localAudioTrack, this.agoraChannelParameters.localVideoTrack]);

            this.localeVideoPaused = false
          } catch (e) {
            this.localeVideoPaused = true
          }
        }
      }
    },
    nudityDetection() {
      if (this.$refs.localePlayerContainer) {
        const videoElement = this.$refs.localePlayerContainer.querySelector('video')

        if (videoElement) {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          const base64Data = canvas.toDataURL(this.base64ImageType);

          let callId = null;
          if (this.agoraCallType === 'outgoing' && this.userAgoraInformation) {
            callId = this.userAgoraInformation.callId
          }

          if (this.agoraCallType === 'incoming' && this.callRequestInfo) {
            callId = this.callRequestInfo.agoraCall.callId
          }

          if (callId) {
            useJwt.checkNudity({
              mime_type: this.base64ImageType,
              user_id: this.$store.getters['auth/userIdentifier'],
              call_id: parseInt(callId),
              image: base64Data,
            }).then(response => {
              if (!!response?.data?.body?.is_nudity_exists) {
                showNotify(this.$toast, this.$t('Nudity detected. If it continues, the call will be terminated.'), 'info')
              }

              // Ergen algılanırsa yayını kapat
              if (!!response?.data?.body?.is_child_exists) {
                showNotify(this.$toast, this.$t('isChildWarningText'), 'danger')
                showNotify(this.$toast, this.$t('isChildWarningText2'), 'danger')

                this.sendHeartBeat = false
                if (this.agoraCallType === 'outgoing') {
                  this.leave()
                } else if (this.agoraCallType === 'incoming') {
                  this.reject()
                }
              }
            }).catch(error => {})
          }
        }
      }
    },
    async reject() {
      await this.cancelCall()
      if (this.callRequestInfo && this.callRequestInfo?.agoraCall?.callId) {
        await this.closeAgoraCall(this.callRequestInfo?.agoraCall?.callId, false)
      }
    },
    async startAgoraClient() {
      // Create an instance of the Agora Engine
      AgoraRTC.setLogLevel(3)
      await this.$store.dispatch('agora/setAgoraClient', AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' }))
      // this.agoraClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })

      this.sendHeartBeat = true

      // Karşı taraf görüşmeye katıldı
      this.agoraClient.on('user-published', async (user, mediaType) => {
        // Kullanıcının verilerini değişkene ata
        this.remoteUser = user

        if (user?._videoTrack?._videoHeight && user?._videoTrack?._videoWidth) {
          this.videoHeight = user._videoTrack._videoHeight
          this.videoWidth = user._videoTrack._videoWidth
        }

        // Subscribe to the remote user when the SDK triggers the "user-published" event.
        await this.agoraClient.subscribe(user, mediaType)
        this.callStatus = 'talking'

        // Subscribe and play the remote audio track.
        if (mediaType === 'audio' && user.audioTrack) {
          this.agoraChannelParameters.remoteUid = user.uid;
          // Get the RemoteAudioTrack object from the AgoraRTCRemoteUser object.
          this.agoraChannelParameters.remoteAudioTrack = user.audioTrack;
          // Play the remote audio track.
          this.agoraChannelParameters.remoteAudioTrack.play();

          this.remoteUserMuted = false
        }

        // Subscribe and play the remote video in the container If the remote user publishes a video track.
        if (mediaType === 'video' && user.videoTrack) {
          // Retrieve the remote video track.
          this.agoraChannelParameters.remoteVideoTrack = user.videoTrack;
          // Retrieve the remote audio track.
          this.agoraChannelParameters.remoteAudioTrack = user.audioTrack;
          // Save the remote user id for reuse.
          this.agoraChannelParameters.remoteUid = user.uid.toString();

          // Play the remote video track.
          this.agoraChannelParameters.remoteVideoTrack.play(this.$refs.remotePlayerContainer);
        }
      })

      // Listen for the "user-unpublished" event.
      this.agoraClient.on('user-unpublished', user => {
        this.remoteUserMuted = true;
        if (this.agoraChannelParameters.remoteAudioTrack && user._audio_muted_) {
          this.agoraChannelParameters.remoteAudioTrack.stop();
        }
        if (this.agoraChannelParameters.remoteVideoTrack && user._video_muted_) {
          this.agoraChannelParameters.remoteVideoTrack.stop();
        }
      })

      // Listen for the "user-joined" event.
      this.agoraClient.on('user-joined', async (user) => {
        // Backend e başladı olarak heart beat gönder
        if (this.agoraCallType === 'outgoing') {
          this.heartBeat({callId: this.userAgoraInformation.callId, status: 1})
        }

        this.callStatus = 'talking'

        // Heart beat durumunu değiştir
        this.heartBeatStatus = 2

        // Süreyi başlat
        this.startTime()
      })

      // Listen for the "user-joined" event.
      this.agoraClient.on('user-left', async (user) => {
        // Store'da çağrı durumunu false yap
        this.$store.dispatch('agora/setAgoraCalling', false)

        // Kullanıcı görüşmeden ayrıldı
        showNotify(this.$toast, this.$t('User has left the call'), 'info')

        if (this.agoraCallType === 'outgoing') {
          await this.closeAgoraCall(this.userAgoraInformation.callId)
        } else  if (this.agoraCallType === 'incoming') {
          await this.closeAgoraCall(null)
        }
      })

      // Bağlantı durum değişkliğini dinle
      this.agoraClient.on("connection-state-change", async (e) => {
        if (e === 'CONNECTED') {
          try {
            // Create a local audio track from the microphone audio.
            this.agoraChannelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()
            await this.agoraChannelParameters.localAudioTrack.setEnabled(true)
            await this.agoraChannelParameters.localAudioTrack.setMuted(false)
            // Create a local video track from the video captured by a camera.
            // Create a local video track from the video captured by a camera.
            await AgoraRTC.createCameraVideoTrack().then(track => {
              this.agoraChannelParameters.localVideoTrack = track
            });
            await this.agoraChannelParameters.localVideoTrack.setEnabled(true)
            // Publish the local audio and video tracks in the channel.
            await this.agoraClient.publish([this.agoraChannelParameters.localAudioTrack, this.agoraChannelParameters.localVideoTrack]);

            this.localeMuted = false
          } catch (e) {
            this.localeMuted = true
          }
        } else if (e === 'DISCONNECTED') {
          if (this.callStatus === 'talking') {
            await this.leave()
          } else if (this.callStatus === 'calling') {
            if (this.agoraCallType === 'outgoing') {
              await this.leave()
            } else if (this.agoraCallType === 'incoming') {
              await this.reject()
            }
          }
        }
      })
    },
    startCall() {
      if (this.agoraClient && this.agoraCallUser) {
        // Store'da çağrı durumunu true yap
        this.$store.dispatch('agora/setAgoraCalling', true)

        this.loading = true
        useJwt.appVideoCall(this.agoraCallUser.id)
            .then(async (response) => {
              if (response.data.body.callType === 'sippy') {
                showNotify(this.$toast, this.$t('Call information : agora not available, sippy is available'), 'info')
                await this.closeAgoraCall(null)
              } else {
                this.userAgoraInformation = response.data.body
                await this.$store.dispatch('agora/setAgoraOptions', {
                  channel: this.userAgoraInformation.channelName,
                  token: this.userAgoraInformation.token,
                  uid: this.userAgoraInformation.uid
                })
                await this.join()
                if (this.agoraCallType === 'outgoing') {
                  // Heartbeat göndermeyi başlat
                  this.heartBeatStatus = 6
                  this.startSendHeartBeat(this.userAgoraInformation.callId, this.userAgoraInformation.heartbeatInterval)
                }
                this.callStatus = 'calling'
              }
            })
            .catch(error => {
              // Store'da çağrı durumunu false yap
              this.$store.dispatch('agora/setAgoraCalling', false)

              this.message = this.$t('Call failed')
              throwDefaultError(this.$toast, error)

              this.closeAgoraCall(null)
            })
            .finally(() => this.loading = false)
      }
    },
    async startCallingTime() {
      await this.stopCallingTime()

      this.timeCallInterval = setInterval(() => {
        this.timeCall++
      }, 1000)
    },
    async stopCallingTime() {
      if (this.timeCallInterval) {
        clearInterval(this.timeCallInterval)
        this.timeCallInterval = null
        this.timeCall = 0
      }
    },
    startTime() {
      this.stopCallingTime()

      showNotify(this.$toast, this.$t('videoCallWarningText'), 'info')
      showNotify(this.$toast, this.$t('videoCallWarningText2'), 'info')

      if (this.intervalForTime) {
        clearInterval(this.intervalForTime)
      }
      this.time = 0
      this.intervalForTime = setInterval(() => {
        this.time++
        this.message = this.getDateForHuman(this.time)

        if (this.time % 30 === 0) {
          this.nudityDetection()
        }
      }, 1000)
    },
    stopAllSounds() {
      if (this.soundIncomingCall) {
        this.soundIncomingCall.pause()
        this.soundIncomingCall.currentTime = 0
      }
      if (this.soundOutgoingCall) {
        this.soundOutgoingCall.pause()
        this.soundOutgoingCall.currentTime = 0
      }
    },
    startSendHeartBeat(callId, heartbeatInterval) {
      if (this.intervalForHeartBeat) {
        clearInterval(this.intervalForHeartBeat)
        this.intervalForHeartBeat = null
      }

      // Backend heartbeat gönder
      this.intervalForHeartBeat = setInterval(() => {
        this.heartBeat({callId: callId, status: this.heartBeatStatus}) // status: Integer {1=started, 2=in_progress, 3=completed}
      }, heartbeatInterval * 1000)
    }
  },
}
</script>

<style lang="scss" scoped>
#agora-video-call {
  position: fixed;
  left: 24px;
  bottom: 24px;
  z-index: 9999999999 !important;

  .expanded {
    padding: 16px;
    background-color: #E7F6F2;
    border-radius: 16px;
    width: 280px;

    .call-information {
      h1 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 8px;
        color: #2C3333;
      }
      p.status {
        font-size: 13px;
        color: #395B64;
      }
    }

    .agora-call-buttons {
      display: flex;
      justify-content: end;
      margin-top: 16px;
      gap: 8px;

      * {
        cursor: pointer;
      }
    }

    .window-actions {
      display: flex;
      align-items: start;
      position: absolute;
      right: 16px;
      top: 20px;

      .minus {
        font-size: 20px;
        line-height: 4px;
        cursor: pointer;
        -webkit-transform: scale(2.0, 1.4);
        -moz-transform: scale(2.0, 1.4);
        -ms-transform: scale(2.0, 1.4);
        -o-transform: scale(2.0, 1.4);
        transform: scale(2.0,1.4);
      }
    }
  }

  .not-expanded {
    position: relative;
    width: 84px;
    height: 84px;
    border-radius: 50%;
    overflow: clip;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .static-blink {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      left: 2px;
      top: 2px;
      outline: 2px solid #a9ead9;
    }

    .blink {
      width: 84px;
      height: 84px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      outline: 2px solid #a9ead9;
      animation: blink-animation 1s steps(20, start) infinite;
      -webkit-animation: blink-animation 1s steps(20, start) infinite;
    }
    @keyframes blink-animation {
      to {
        width: 64px;
        height: 64px;
        left: 10px;
        top: 10px;
      }
    }
    @-webkit-keyframes blink-animation {
      to {
        width: 84px;
        height: 84px;
        left: 0;
        top: 0;
      }
    }
  }
}
</style>

<style lang="scss">
#video-container {
  position: relative;

  .agora-call-buttons {
    display: flex;
    justify-content: end;
    margin-top: 16px;
    gap: 8px;

    .incoming-buttons {
      display: flex;
      width: 100%;
      justify-content: center !important;
      gap: 16px;
    }

    * {
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    .agora-call-buttons {
      justify-content: start !important;
    }
  }

  #remotePlayerContainer {
    width: 100%;
    height: 600px;
    position: relative;
    overflow: hidden;

    &.blur {
      overflow: hidden;
      -webkit-filter: blur(10px);
      -moz-filter: blur(05px);
      -o-filter: blur(10px);
      -ms-filter: blur(10px);
      filter: blur(10px);
    }

    div {
      z-index: 2;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain !important;
      z-index: 2;
    }

    img {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  #localePlayerContainer {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 100px;
    height: 100px;
    background: #000;

    &.blur {
      overflow: hidden;
      -webkit-filter: blur(10px);
      -moz-filter: blur(10px);
      -o-filter: blur(10px);
      -ms-filter: blur(10px);
      filter: blur(10px);
    }

    div {
      z-index: 5;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain !important;
      z-index: 6;
    }

    img {
      position: absolute;
      z-index: 4;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
