<template>
  <div class="update-email">
    <b-overlay :show="loading" variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <b-row>
        <b-col lg="8" md="12" sm="12">
          <h3 class="mb-2">
            {{ $t('Profile') }}
          </h3>
          <validation-observer
              v-if="user"
              ref="form"
              #default="{invalid}"
          >
            <b-form
                class="mt-2"
                @submit.prevent
            >
              <b-form-group
                  label-for="email"
                  :label="$t('Email')"
              >
                <b-input-group>
                  <b-form-input
                      id="email"
                      v-model="user.email"
                      name="email"
                      disabled
                      readonly
                  />
                  <template #append>
                    <b-button
                        size="sm"
                        variant="primary"
                        @click="modalUpdateEmail = true"
                        style="border-bottom-right-radius: 6px; border-top-right-radius: 6px;"
                    >
                      {{ $t('Change') }}
                    </b-button>
                  </template>
                </b-input-group>
              </b-form-group>

              <b-form-group
                  label-for="phone"
                  :label="$t('Phone number')"
              >
                <b-input-group>
                  <b-form-input
                      id="phone"
                      v-model="user.phoneNumber"
                      name="phone"
                      disabled
                      readonly
                  />
                  <template #append>
                    <b-button
                        size="sm"
                        variant="primary"
                        @click="modalUpdatePhone = true"
                        style="border-bottom-right-radius: 6px; border-top-right-radius: 6px;"
                    >
                      {{ $t('Change') }}
                    </b-button>
                  </template>
                </b-input-group>
              </b-form-group>

              <b-form-group
                  label-for="userName"
                  :label="$t('Username')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Username')"
                    vid="userName"
                >
                  <b-form-input
                      id="userName"
                      v-model="user.userName"
                      name="userName"
                      readonly
                      disabled
                      @focusout="checkUsername"
                      :disabled="userNameChecking"
                  />
                  <!--                  :state="errors.length > 0 ? false : (userNameAvailable || null)"-->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                  label-for="firstName"
                  :label="$t('Firstname')"
              >
                <validation-provider
                    #default="{ errors }"
                    name="$t('Firstname')"
                    vid="firstName"
                >
                  <b-form-input
                      id="firstName"
                      v-model="user.firstName"
                      name="firstName"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                  label-for="lastName"
                  :label="$t('Lastname')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Lastname')"
                    vid="lastName"
                >
                  <b-form-input
                      id="lastName"
                      v-model="user.lastName"
                      name="firstName"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                  label-for="description"
                  :label="$t('About me')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('About me')"
                    vid="description"
                >
                  <b-form-input
                      id="firstName"
                      v-model="user.profile.description"
                      name="firstName"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <validation-provider
                  v-if="false"
                  #default="{ errors }"
                  :name="$t('Living country')"
                  vid="country"
              >
                <b-form-group
                    label-for="country"
                    :label="$t('Living country')"
                    :state="errors.length > 0 ? false:null"
                >
                  <v-select
                      v-model="user.profile.country"
                      :clearable="false"
                      :filter="countrySearch"
                      :reduce="country => country.code"
                      :options="countries"
                  >
                    <template v-slot:option="option">
                    <span class="mr-1">
                      {{ option.emoji }}
                    </span>
                      {{ $t(option.name) }}
                    </template>
                    <template v-slot:selected-option="option">
                    <span class="mr-1">
                      {{ option.emoji }}
                    </span>
                      {{ $t(option.name) }}
                    </template>
                  </v-select>
                </b-form-group>
              </validation-provider>

              <b-form-group
                  :label="$t('Gender')"
                  label-for="gender"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Gender')"
                    vid="gender"
                >
                  <div class="demo-inline-spacing">
                    <b-form-radio
                        v-model="user.profile.gender"
                        name="gender"
                        value="male"
                        readonly
                        disabled
                    >
                      {{ $t('Male') }}
                    </b-form-radio>
                    <b-form-radio
                        v-model="user.profile.gender"
                        name="gender"
                        value="female"
                        readonly
                        disabled
                    >
                      {{ $t('Female') }}
                    </b-form-radio>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-row>
                <b-col md="12">
                  <b-form-group
                      :label="$t('Birthdate')"
                      label-for="birth-date"
                  ></b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                      :label="$t('Day')"
                      label-for="date"
                  >
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('Day')"
                        vid="date"
                        rules="required"
                    >
                      <v-select
                          id="date"
                          v-model="user.profile.date"
                          :clearable="false"
                          :options="dates"
                          :selectable="option => ! option.includes('Gün')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                      :label="$t('Month')"
                      label-for="month"
                  >
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('Month')"
                        vid="month"
                        rules="required"
                    >
                      <v-select
                          id="month"
                          v-model="user.profile.month"
                          :clearable="false"
                          :options="months"
                          :selectable="option => !option.value.includes('Ay')"
                          label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                      :label="$t('Year')"
                      label-for="year"
                  >
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('Year')"
                        vid="year"
                        rules="required"
                    >
                      <v-select
                          id="year"
                          v-model="user.profile.year"
                          :clearable="false"
                          :options="years"
                          :selectable="option => ! option.includes('Yıl')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-button
                  variant="primary"
                  class="mt-2 px-4"
                  @click="update"
              >
                {{ $t('Save') }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-overlay>

    <b-modal v-model="modalUpdateEmail" centered hide-header-close hide-footer hide-header size="md">
      <div class="d-flex justify-content-end fs-12 color-gray cursor-pointer" @click="modalUpdateEmail = false">
        {{ $t('Close') }}
      </div>
      <b-row>
        <b-col md="12">
          <div class="d-flex justify-content-center align-items-center flex-column pt-3 pl-3 pr-3">
            <h5 class="fs-22 fw-600 text-center color-black">
              {{ $t('Updating email address') }}
            </h5>
          </div>
        </b-col>
        <b-col md="12" class="p-3">
          <!-- form -->
          <validation-observer
              ref="emailForm"
              #default="{invalid}">
            <b-form
                class="auth-login-form"
                @submit.prevent="emailUpdateVerification">
              <b-form-group
                  label-for="login-phone"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('New email address')"
                    rules="required|email"
                >
                  <b-form-input
                      id="forgot-password-email"
                      v-model="newEmail"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('New email address')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                  class="mt-2"
                  type="submit"
                  variant="primary"
                  block
                  :disabled="loading || invalid"
              >
                <b-spinner v-if="loading" small></b-spinner>
                {{ $t('Continue') }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="modalUpdatePhone" centered hide-header-close hide-footer hide-header size="md">
      <div class="d-flex justify-content-end fs-12 color-gray cursor-pointer" @click="modalUpdatePhone = false">
        {{ $t('Close') }}
      </div>
      <b-row>
        <b-col md="12">
          <div class="d-flex justify-content-center align-items-center flex-column pt-3 pl-3 pr-3">
            <h5 class="fs-22 fw-600 text-center color-black">
             {{ $t('Updating phone number') }}
            </h5>
          </div>
        </b-col>
        <b-col md="12" class="p-3">
          <!-- form -->
          <validation-observer
              ref="phoneForm"
              #default="{invalid}">
            <b-form
                class="auth-login-form"
                @submit.prevent="phoneNumberVerification">
              <b-form-group
                  label-for="login-phone"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Mobile phone number')"
                    vid="phone"
                    rules="required"
                >
                  <vue-tel-input
                      v-model="newPhone"
                      @open="onDropdownOpen(true)"
                      @close="onDropdownOpen(false)"
                      @country-changed="countryChanged"
                      mode="auto"
                      @validate="phoneValidate"
                      :inputOptions="{placeholder: $t('Mobile phone number')}"
                      validCharactersOnly
                      :styleClasses="['phone-input']"
                  >
                    <template v-slot:arrow-icon>
                      <span class="font-small-3 mr-05">
                        {{ country ? '+' + country.dialCode : '' }}
                      </span>
                      <font-awesome-icon v-if="open" icon="fa-solid fa-chevron-up"/>
                      <font-awesome-icon v-else icon="fa-solid fa-chevron-down"/>
                    </template>
                  </vue-tel-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                  class="mt-2"
                  type="submit"
                  variant="primary"
                  block
                  :disabled="loading || invalid"
              >
                <b-spinner v-if="loading" small></b-spinner>
                {{ $t('Continue') }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="modalPinCode" centered hide-header-close hide-footer hide-header size="md">
      <div class="d-flex justify-content-end fs-12 color-gray cursor-pointer" @click="modalPinCode = false">
        {{ $t('Close') }}
      </div>
      <b-row class="password-information">
        <b-col md="12">
          <div class="d-flex justify-content-center align-items-center flex-column pt-3 pl-3 pr-3">
            <h5 class="fs-22 fw-600 text-center color-black mb-1">
              {{ $t('PIN Code') }}
            </h5>
          </div>
        </b-col>
        <b-col sm="12" class="mt-2">
          <div class="d-flex justify-content-center">
            <validation-provider
                #default="{ errors }"
                :name="$t('PIN Code')"
                vid="password"
            >
              <PincodeInput
                  v-model="pinCode"
              />
              <br>
              <div class="text-danger text-center mt-2">{{ errors[0] }}</div>
            </validation-provider>
          </div>
        </b-col>

        <b-col sm="12">
          <p class="send-again">
            {{ $t('Code not received?') }}
            <span class="link" @click="sendAgainPinCode">
              {{ $t('Send again') }}
            </span>
          </p>
        </b-col>
        <b-col md="12">
          <b-button
              class="mt-2 mb-1"
              type="submit"
              variant="primary"
              block
              :disabled="loading"
              @click="updateEmailOrPhone"
          >
            <b-spinner v-if="loading" small></b-spinner>
            {{ $t('Approve') }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadio, BInputGroup, BInputGroupAppend, BModal,
  BOverlay, BRow, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import LoadingSpinner from '@/components/LoadingSpinner'
import { gtmTrackNewEvent, showNotify, throwDefaultError } from '@/helpers/helpers'
import vSelect from 'vue-select'
import countries from '@/data/countries.json'
import _ from 'lodash'
import PincodeInput from 'vue-pincode-input'

export default {
  components: {
    BButton,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    BOverlay,
    BRow,
    BSpinner,

    LoadingSpinner,

    PincodeInput,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  data() {
    return {
      country: null,
      countries: _.sortBy(countries, 'name').map(c => ({ ...c, name: this.$t(c.name) })),
      currentUsername: null,
      dates: [
        'Gün', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
        '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31',
      ],
      months: [
        { text: this.$t('Month'), value: this.$t('Month') },
        { text: this.$t('January'), value: '1' },
        { text: this.$t('February'), value: '2' },
        { text: this.$t('March'), value: '3' },
        { text: this.$t('April'), value: '4' },
        { text: this.$t('May'), value: '5' },
        { text: this.$t('June'), value: '6' },
        { text: this.$t('July'), value: '7' },
        { text: this.$t('August'), value: '8' },
        { text: this.$t('September'), value: '9' },
        { text: this.$t('October'), value: '10' },
        { text: this.$t('November'), value: '11' },
        { text: this.$t('December'), value: '12' },
      ],
      loading: false,
      modalUpdateEmail: false,
      modalUpdatePhone: false,
      modalPinCode: false,
      newEmail: '',
      newEmailCode: '',
      newPhone: '',
      newPhoneCode: '',
      open: false,
      pinCode: '',
      updateType: 'email',
      user: null,
      userNameAvailable: true,
      userNameChanged: false,
      userNameChecking: false,
      userNameCheckingDelay: 1000,
      userNameCheckingTimeout: null,
      valid: false,
    }
  },
  watch: {
    'user.userName' (val) {
      if (val && val !== '' && !this.userNameChanged && this.currentUsername && this.currentUsername !== val) {
        clearTimeout(this.userNameCheckingTimeout)
        this.userNameCheckingTimeout = setTimeout(() => {
          // this.checkUsername()
        }, this.userNameCheckingDelay)
      }
    },
  },
  computed: {
    phoneWithCountryCode () {
      return this.country ? '+' + this.country.dialCode + this.newPhone.replace(/ /g, "") : this.newPhone.replace(/ /g, "")
    },
    years() {
      const _years = ['Yıl']
      const lastYear = new Date().getFullYear() - 18

      for (let i = 0; i < 100; i++) {
        _years.push((lastYear - i).toString())
      }

      return _years
    },
  },
  created() {
    this.getProfile()
  },
  methods: {
    checkUsername() {
      if (this.user.userName !== '' && this.user.userName !== this.currentUsername) {
        this.userNameChecking = true
        this.userNameChanged = true
        useJwt.checkUsername({ username: this.user.userName, lang: this.$i18n.locale })
            .then(response => {
              this.userNameAvailable = true
            })
            .catch(error => {
              this.userNameAvailable = false
              this.$refs.form.setErrors({
                userName: this.$t('You cannot use this username'),
              })
            })
            .finally(() => {
              this.userNameChanged = false
              this.userNameChecking = false
            })
      }
    },
    countryChanged (country) {
      this.country = country
    },
    countrySearch(options, search) {
      return options.filter(country => {
        return country.name.toLowerCase().includes(search.toLowerCase())
            || country.code.toLowerCase().includes(search.toLowerCase())
      })
    },
    emailUpdate() {
      this.loading = true

      useJwt.emailUpdate({email: this.newEmail, pinCode: this.pinCode})
          .then(response => {
            showNotify(this.$toast, this.$t('Your e-mail address has been updated'), 'success')
            this.updateType = ''
            this.newEmail = ''
            this.pinCode = ''
            this.modalPinCode = false
            this.modalUpdateEmail = false

            this.getProfile()
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => {
            this.loading = false
          })
    },
    emailUpdateVerification() {
      this.$refs.emailForm.validate().then(success => {
        if (success) {
          this.loading = true

          useJwt.emailUpdateVerification({email: this.newEmail})
              .then(response => {
                showNotify(this.$toast, response.data.body, 'success')
                this.updateType = 'email'
                this.pinCode = ''
                this.modalPinCode = true
              })
              .catch(error => {
                throwDefaultError(this.$toast, error)
              })
              .finally(() => {
                this.loading = false
              })
        }
      })
    },
    getProfile() {
      this.loading = true
      useJwt.getMe()
          .then(response => {
            const _data = response.data.body
            const _month = this.months.find(m => _data.profile.month == m.value)
            _data.profile.month = _month
            this.currentUsername = _data.userName
            this.user = _data
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    onDropdownOpen (open) {
      this.open = open
    },
    phoneNumberUpdate() {
      this.loading = true

      useJwt.phoneNumberUpdate({phoneNumber: this.phoneWithCountryCode, pinCode: this.pinCode})
          .then(response => {
            showNotify(this.$toast, this.$t('Your phone number has been updated'), 'success')
            this.updateType = ''
            this.newPhone = ''
            this.pinCode = ''
            this.modalPinCode = false
            this.modalUpdatePhone = false

            this.getProfile()
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => {
            this.loading = false
          })
    },
    phoneValidate(status) {
      this.valid = status ? status.valid : false
      this.$refs.phoneForm.reset()
    },
    phoneNumberVerification() {
      if (!this.valid) {
        this.$refs.phoneForm.setErrors({
          phone: this.$t('Check phone number'),
        })
        return false
      }
      this.$refs.phoneForm.validate().then(success => {
        if (success) {
          this.loading = true

          useJwt.phoneNumberVerification({phoneNumber: this.phoneWithCountryCode})
              .then(response => {
                showNotify(this.$toast, response.data.body, 'success')
                this.updateType = 'phone'
                this.pinCode = ''
                this.modalPinCode = true
              })
              .catch(error => {
                throwDefaultError(this.$toast, error)
              })
              .finally(() => {
                this.loading = false
              })
        }
      })
    },
    sendAgainPinCode() {
      if (this.updateType === 'email') {
        this.emailUpdateVerification()
      } else {
        this.phoneNumberVerification()
      }
    },
    updateEmailOrPhone() {
      if (this.updateType === 'email') {
        this.emailUpdate()
      } else {
        this.phoneNumberUpdate()
      }
    },
    update() {
      this.$refs.form.validate().then(success => {
        if (success) {
          const data = {
            firstName: this.user.firstName,
            lastName: this.user.lastName,
          }
          const profile = {
            // country: this.user.profile.country,
            // gender: this.user.profile.gender,
            description: this.user.profile.description,
            date: this.user.profile.date,
            month: this.user.profile.month.value,
            year: this.user.profile.year,
          }

          this.loading = true

          const _updateMe = useJwt.updateMe(data)
          const _updateProfile = useJwt.updateProfile(profile)

          Promise.all([_updateMe, _updateProfile])
              .then(values => {
                showNotify(this.$toast, this.$t('Update successful'), 'success')
                this.getProfile()

                gtmTrackNewEvent('saveuser', {
                  category: 'Hesap',
                  action: 'SaveUser',
                })
              })
              .catch(error => {
                throwDefaultError(this.$toast, error)
              })
              .finally(() => { this.loading = false })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.password-information {
  font-family: 'Lexend', sans-serif;
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #000000;
    text-align: center;
  }

  p {
    font-family: inherit;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #8893A4;
  }

  .send-again {
    margin-top: 14px;
    font-family: inherit;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #8893A4;

    .link {
      cursor: pointer;
      color: #F9447A;
    }
  }
}
</style>
