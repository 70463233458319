<template>
  <div
      id="app"
      class="h-100"
      :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <agora-call v-if="user && showAgoraCall" />

    <agora-video-call v-if="user && showAgoraVideoCall" />

    <payment
        v-if="paymentStatus"
        :payment-data="paymentHtml"
    />

    <b-modal
        v-model="modalDownloadMobileApp"
        centered
        hide-header-close
        hide-footer
        hide-header
        size="lg"
    >
      <div
          class="d-flex justify-content-end fs-12 color-gray cursor-pointer"
          @click="closeModal"
      >
        {{ $t('Close') }}
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column p-3">
        <div
            class="fs-22 fw-600 text-center color-black"
            v-html="$t('modalDownloadMobileAppTitle')"
        />
        <div class="fs-14 color-gray-2 text-center fw-300 mt-1">
          {{ $t('This user does not accept calls over the telephone line. You can use our mobile apps to search for this user.') }}.
        </div>
        <div class="fs-12 color-gray-2 fw-700 text-center mt-2">
          {{ $t('Download by scanning the QR code') }}
        </div>
        <b-img
            :src="qrCodeImage"
            width="160"
            class="mt-1"
        />
        <div class="fs-12 color-gray-2 fw-700 mt-1">
          {{ $t('Or') }}
        </div>
        <div class="d-flex justify-content-between mt-1 align-center">
          <a
              href="https://play.google.com/store/apps/details?id=com.arasana.arasana&hl=tr&gl=US&pli=1"
              target="_blank"
          >
            <b-img
                :src="badgeGooglePlay"
                height="80"
            />
          </a>
          <a
              href="https://apps.apple.com/tr/app/arasana-canl%C4%B1-sohbet-chat/id1264652539"
              target="_blank"
          >
            <b-img
                :src="badgeAppleStore"
                height="62"
                class="ml-3"
                style="margin-top: 8px"
            />
          </a>
        </div>
      </div>
    </b-modal>

    <b-modal
        v-if="microphoneAllowedResult && ((modalCallType === 'video' && microphoneAllowed) || modalCallType === 'audio')"
        v-model="modalCall"
        centered
        hide-header-close
        hide-footer
        hide-header
        size="md"
    >
      <div
          class="d-flex justify-content-end fs-12 color-gray cursor-pointer"
          @click="modalCall = false"
      >
        {{ $t('Close') }}
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column p-2">
        <div class="fs-16 fw-700 text-center color-black mb-1">
          {{ $t('Are you sure you want to call {name} user?', {name: modalCallUser ? modalCallUser.userName : ''}) }}
        </div>
        <div class="fw-16 fw-400 color-gray-2">
          <span v-if="modalCallUser && (modalCallUser.profile.callType == 2 || modalCallUser.profile.callType == 0) && microphoneAllowed">
            {{ $t('Call user notification text via Agora') }}
          </span>
          <span v-else>
            {{ $t('Call user notification text') }}
          </span>
        </div>
        <b-button
            variant="primary"
            size="sm"
            class="mt-1"
            block
            :disabled="loading"
            @click="call"
        >
          <b-spinner
              v-if="loading"
              small
          />
          {{ $t('Call') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        v-model="modalCallSuggestion"
        centered
        hide-header-close
        hide-footer
        hide-header
        size="md"
    >
      <div
          class="d-flex justify-content-end fs-12 color-gray cursor-pointer"
          @click="modalCallSuggestion = false"
      >
        {{ $t('Close') }}
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column p-2">
        <div class="fs-16 fw-600 text-center color-black">
          {{ $t('Do you want to search for the user you like?') }}
        </div>
        <b-button
            variant="primary"
            size="sm"
            class="mt-1"
            block
            :disabled="loading"
            @click="callRequest"
        >
          <b-spinner
              v-if="loading"
              small
          />
          {{ $t('Call') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        v-model="modalAddPhoneNumber"
        centered
        hide-header-close
        hide-footer
        hide-header
        size="md"
    >
      <div
          class="d-flex justify-content-end fs-12 color-gray cursor-pointer"
          @click="modalAddPhoneNumber = false"
      >
        {{ $t('Close') }}
      </div>
      <b-row>
        <b-col md="12">
          <!--          <div v-if="modalAddPhoneNumberShowDiamond"-->
          <div
              v-if="authType === 'email'"
              class="d-flex justify-content-center align-items-center flex-column pt-3 pl-3 pr-3"
          >
            <b-img
                :src="iconDiamond2"
                width="140"
                class="mb-05"
            />
            <h5
                class="fs-22 fw-600 text-center color-black mb-1"
                v-html="$t('Add phone earn {amount} diamonds', {amount: '300'})"
            />
            <p class="fs-16 fw-300 text-center mb-1 color-gray">
              {{ $t('By registering your phone number, you can win {amount} diamonds and make free calls.', {amount: '300'}) }}
            </p>
          </div>
          <div
              v-else
              class="d-flex justify-content-center align-items-center flex-column pt-3 pl-3 pr-3"
          >
            <h5 class="fs-22 fw-600 text-center color-black mb-1">
              {{ $t('Contact Information') }}
            </h5>
            <p class="fs-16 fw-300 text-center mb-1 color-gray">
              {{ $t('To make and receive calls, you need to fill in your contact information.') }}
            </p>
          </div>
        </b-col>
        <b-col
            md="12"
            class="p-3"
        >
          <!-- form -->
          <validation-observer
              ref="phoneForm"
              #default="{invalid}"
          >
            <b-form
                class="auth-login-form"
                @submit.prevent="phoneNumberVerificationCode"
            >
              <b-form-group
                  label-for="login-phone"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Cep telefonu numarası"
                    vid="phone"
                    rules="required"
                >
                  <vue-tel-input
                      v-model="phone"
                      mode="auto"
                      :input-options="{placeholder: $t('Mobile phone number')}"
                      valid-characters-only
                      :style-classes="['phone-input']"
                      @open="onDropdownOpen(true)"
                      @close="onDropdownOpen(false)"
                      @country-changed="countryChanged"
                      @validate="phoneValidate"
                  >
                    <template v-slot:arrow-icon>
                      <span class="font-small-3 mr-05">
                        {{ country ? '+' + country.dialCode : '' }}
                      </span>
                      <font-awesome-icon
                          v-if="open"
                          icon="fa-solid fa-chevron-up"
                      />
                      <font-awesome-icon
                          v-else
                          icon="fa-solid fa-chevron-down"
                      />
                    </template>
                  </vue-tel-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                  class="mt-2"
                  type="submit"
                  variant="primary"
                  block
                  :disabled="loading || invalid"
              >
                <b-spinner
                    v-if="loading"
                    small
                />
                {{ $t('Continue') }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        v-model="modalAddEmail"
        centered
        hide-header-close
        hide-footer
        hide-header
        size="md"
    >
      <div
          class="d-flex justify-content-end fs-12 color-gray cursor-pointer"
          @click="modalAddEmail = false"
      >
        {{ $t('Close') }}
      </div>
      <b-row>
        <b-col md="12">
          <div class="d-flex justify-content-center align-items-center flex-column pt-3 pl-3 pr-3">
            <h5 class="fs-22 fw-600 text-center color-black mb-1">
              {{ $t('Contact Information') }}
            </h5>
            <p class="fs-16 fw-300 text-center mb-1 color-gray">
              {{ $t('You must complete your contact information so that we can provide you with a better service') }}
            </p>
          </div>
        </b-col>
        <b-col
            md="12"
            class="p-3"
        >
          <!-- form -->
          <validation-observer
              ref="emailForm"
              #default="{invalid}"
          >
            <b-form
                class="auth-login-form"
                @submit.prevent="emailUpdateVerification"
            >
              <b-form-group
                  label-for="forgot-password-email"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Email')"
                    rules="required|email"
                >
                  <b-form-input
                      id="forgot-password-email"
                      v-model="email"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Email')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                  class="mt-2"
                  type="submit"
                  variant="primary"
                  block
                  :disabled="loading || invalid"
              >
                <b-spinner
                    v-if="loading"
                    small
                />
                {{ $t('Continue') }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        v-model="modalPinCode"
        centered
        hide-header-close
        hide-footer
        hide-header
        size="md"
    >
      <div
          class="d-flex justify-content-end fs-12 color-gray cursor-pointer"
          @click="modalPinCode = false"
      >
        {{ $t('Close') }}
      </div>
      <b-row class="password-information">
        <b-col md="12">
          <div class="d-flex justify-content-center align-items-center flex-column pt-3 pl-3 pr-3">
            <h5 class="fs-22 fw-600 text-center color-black mb-1">
              {{ $t('PIN Code') }}
            </h5>
          </div>
        </b-col>
        <b-col
            sm="12"
            class="mt-2"
        >
          <div class="d-flex justify-content-center">
            <validation-provider
                #default="{ errors }"
                :name="$t('Password')"
                vid="password"
            >
              <PincodeInput
                  v-model="password"
              />
              <br>
              <div class="text-danger text-center mt-2">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
        </b-col>

        <b-col sm="12">
          <p class="send-again">
            {{ $t('Code not received?') }}
            <span
                class="link"
                @click="sendAgainPinCode"
            >
              {{ $t('Send again') }}
            </span>
          </p>
        </b-col>
        <b-col md="12">
          <b-button
              class="mt-2 mb-1"
              type="submit"
              variant="primary"
              block
              :disabled="loading"
              @click="verification"
          >
            <b-spinner
                v-if="loading"
                small
            />
            {{ $t('Approve') }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
        v-model="oopsModal"
        centered
        hide-header-close
        hide-footer
        hide-header
        body-class="credits-oops"
        size="md"
    >
      <div class="credits-oops-image">
        <b-img
            :src="imageCreditsOops"
            style="width: 100%;"
        />
        <div class="credit-oops-image-text">
          {{ $t('Oops') }}!
          <b-img :src="imageCreditsOopsSadFace" />
        </div>
      </div>
      <div class="credits-oops-text">
        <h3>
          {{ oopsModalText }}
        </h3>
      </div>
    </b-modal>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import PincodeInput from 'vue-pincode-input'
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import {
  gtmPushUserId, showNotify, throwDefaultError, throwPaymentError, settingsRegister,
} from '@/helpers/helpers'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BImg, BModal, BRow, BSpinner,
} from 'bootstrap-vue'

import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'
import AgoraCall from '@/components/AgoraCall.vue'
import AgoraVideoCall from '@/components/AgoraVideoCall.vue'
import Payment from '@/components/Payment.vue'
import io from 'socket.io-client'
import axios from 'axios'

import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: 'AIzaSyA2EBTR2-XiTrjber-m75uhJeDYQIij_sE',
  authDomain: 'arasana-5eec0.firebaseapp.com',
  databaseURL: 'https://arasana-5eec0.firebaseio.com',
  projectId: 'arasana-5eec0',
  storageBucket: 'arasana-5eec0.appspot.com',
  messagingSenderId: '821636087237',
  appId: '1:821636087237:web:d7b1b62bed6920aa0fdfac',
  measurementId: 'G-KD21YW8JPJ',
}

// Initialize Firebase
// eslint-disable-next-line no-unused-vars
const app = initializeApp(firebaseConfig)

// import LayoutVertical from '@/layouts/vertical/LayoutVertical.vue'
// import LayoutHorizontal from '@/layouts/horizontal/LayoutHorizontal.vue'
// import LayoutFull from '@/layouts/full/LayoutFull.vue'
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
// eslint-disable-next-line import/extensions
const LayoutFullContainer = () => import('@/layouts/full/LayoutFullContainer')

const badgeAppleStore = require('@/assets/images/banner/app-store-badge.png')
const badgeGooglePlay = require('@/assets/images/banner/google-play-badge.png')
const iconDiamond2 = require('@/assets/images/icons/diamonds-2.svg')
const imageCreditsOops = require('@/assets/images/misc/credits-oops.png')
const imageCreditsOopsSadFace = require('@/assets/images/misc/sad-face.png')
const qrCodeImage = require('@/assets/images/banner/mobile-application-qr-code.png')

export default {
  components: {
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BImg,
    BModal,
    BRow,
    BSpinner,

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    LayoutFullContainer,

    AgoraCall,
    AgoraVideoCall,
    Payment,

    PincodeInput,

    ScrollToTop,

    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      country: null,
      email: '',
      microphoneAllowed: false,
      microphoneAllowedResult: false,
      open: false,
      password: '',
      phone: '',
      valid: false,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    agoraCalling() {
      return this.$store.getters['agora/agoraCalling']
    },
    authType() {
      return this.$store.getters['auth/authType']
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      if (this.$route.meta.layout === 'full-container') return 'layout-full-container'
      return `layout-${this.contentLayoutType}`
    },
    modalCall: {
      get() {
        return this.$store.getters['app/modalCall']
      },
      set(value) {
        this.$store.dispatch('app/setModalCall', value)
      },
    },
    modalCallUser() {
      return this.$store.getters['app/modalCallUser']
    },
    modalCallType() {
      return this.$store.getters['app/modalCallType']
    },
    phoneWithCountryCode() {
      return this.country ? `+${this.country.dialCode}${this.phone.replace(/ /g, '')}` : this.phone.replace(/ /g, '')
    },
    userFromStore() {
      return this.$store.getters['auth/user']
    },
    userPhoneNumber2() {
      return this.$store.getters['auth/userPhoneNumber']
    },
  },
  watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler(value) {
        // this.$store.dispatch('app/setSearchBar', false)
      },
      deep: true,
    },
    modalCall: {
      handler(value) {
        const that = this
        if (value) {
          // eslint-disable-next-line no-underscore-dangle
          let _config = { audio: true }

          if (this.$store.getters['app/modalCallType'] === 'video') {
            _config = { audio: true, video: true }
          }

          navigator.mediaDevices.getUserMedia(_config)
              .then(stream => {
                that.microphoneAllowed = true
                stream.getAudioTracks()[0].stop()
                if (stream.getVideoTracks()[0]) {
                  stream.getVideoTracks()[0].stop()
                }
              })
              .catch(() => {
                that.microphoneAllowed = false
                if (store.getters['app/modalCallType'] === 'video') {
                  showNotify(that.$toast, that.$t('You must allow microphone and camera'), 'danger')
                }
              })
              .finally(() => {
                that.microphoneAllowedResult = true
              })
        }
      },
      deep: true,
    },
    userFromStore(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.openSocket()
      }
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  created() {
    localStorage.setItem('showMessage', '1')

    const userId = this.$store.getters['auth/userId']
    if (userId) {
      gtmPushUserId(userId)
    }

    if (this.userFromStore) {
      this.openSocket()
    }

    const that = this
    window.addEventListener('message', event => {
      if (event?.data?.type === 'payment.result') {
        that.$store.dispatch('payment/setLoading', false)
        if (event?.data?.data?.type === 'defaultError') {
          throwDefaultError(that.$toast, event.data.data.error)
        } else if (event?.data?.data?.message) {
          showNotify(that.$toast, event.data.data.message, event.data.data.type)
        }

        // if (event?.data?.data?.type === 'success') {
        //   useJwt.getMe()
        //       .then(response => {
        //         try {
        //           that.$store.dispatch('auth/updateUser', response.data.body)
        //           that.$router.push({ name: 'market' })
        //         } catch (e) {
        //           throwDefaultError(that.$toast)
        //         }
        //       })
        //       .catch(error => {
        //         throwDefaultError(that.$toast, error)
        //       })
        // }

        if (event?.data?.data?.transaction) {
          useJwt.paymentPostnetPerform(event?.data?.data?.transaction.data)
              .then(response => {
                const paymentStatus = response.data.body

                if (paymentStatus.approved) {
                  localStorage.removeItem('LAST_PAYMENT_TRANSACTION_ID_KEY')
                  localStorage.removeItem('LAST_PAYMENT_AMOUNT')
                  localStorage.removeItem('LAST_COUPON_CODE')
                  // that.deleteCookie(event?.data?.data?.transaction?.ykbPosInfoFromServerKey)

                  useJwt.getMe()
                      .then(response2 => {
                        that.$store.dispatch('auth/updateUser', response2.data.body)
                      })
                      .catch(error => {
                        throwDefaultError(that.$toast, error)
                      }).finally(() => {
                    showNotify(that.$toast, that.$t('You have successfully purchased product'), 'success')
                    that.$router.push({ name: 'market' })
                  })
                } else {
                  showNotify(that.$toast, that.$t('An error occurred during the payment process. Please try again.'), 'danger')
                  that.paymentStatus = false
                }
              })
              .catch(error => {
                throwPaymentError(that.$toast, error)
                // that.$router.push({ name: 'market' })
              })
              .finally(() => {
                that.$store.dispatch('payment/setStatus', false)
                that.$store.dispatch('payment/setLoading', false)
                that.$store.dispatch('payment/setPaymentData', null)
                that.$store.dispatch('payment/setPaymentHtml', null)
              })
        }
      }
    })

    this.getFirebaseNotificationToken()
  },
  methods: {
    call() {
      if (this.modalCallUser) {
        if (this.microphoneAllowed) {
          // eslint-disable-next-line eqeqeq
          if (this.modalCallUser.profile?.callType == 2 || this.modalCallUser.profile?.callType == 0) {
            // Agora araması zaten mevcutsa bilgilendirme göster
            if (this.agoraCalling) {
              showNotify(this.$toast, this.$t('You are already calling'), 'info')
              return
            }
            // Kullanıcı app üzerinden arama kabul ettiği için agora üzerinden arama yap
            // store.dispatch('app/setModalDownloadMobileApp', true)
            this.triggerShowAgoraCall(this.modalCallUser, 'outgoing')
          } else {
            this.triggerSippyCall()
          }
        } else {
          this.triggerSippyCall()
        }
      }
    },
    callRequest() {
      store.dispatch('app/setModalCall', true)
    },
    countryChanged(country) {
      this.country = country
    },
    emailUpdateVerification() {
      this.$refs.emailForm.validate().then(success => {
        if (success) {
          this.loading = true
          useJwt.emailUpdateVerification({ email: this.email })
              .then(response => {
                showNotify(this.$toast, response.data.body, 'success')
                this.verificationType = 'email'
                this.modalPinCode = true
                this.modalAddEmail = false
              })
              .catch(error => {
                throwDefaultError(this.$toast, error)
              })
              .finally(() => {
                this.loading = false
              })
        }
      })
    },
    getFirebaseNotificationToken() {
      const messaging = getMessaging()
      const userId = this.$store.getters['auth/userId']
      const vapidKey = 'BAphd6cJb9veJXouEONzCH506k4oC6qi0-VK8gjPGsNgEK_97WY-AyDy3E-_bh6I5nhqW_cKt7qLNoq_bpOGaX0'
      let token = null

      getToken(messaging, { vapidKey })
          .then(currentToken => {
            if (currentToken) {
              token = currentToken
            }
          }).catch(() => {})
          .finally(() => {
            console.log('userId', userId)
            if (userId) {
              settingsRegister(token)
            }
          })
    },
    onDropdownOpen(open) {
      this.open = open
    },
    async openSocket() {
      // eslint-disable-next-line no-underscore-dangle
      const _chatConnection = await io(process.env.VUE_APP_CHAT_SOCKET_URL,
          {
            reconnection: true,
            query: {
              uuid: this.$store.getters['auth/userId'],
              'x-bpsh-connect-api-key': process.env.VUE_APP_API_KEY,
              'x-bpsh-connect-token': this.$store.getters['auth/token'],
            },
            transports: ['websocket', 'polling', 'flashsocket'],
          })

      await this.$store.dispatch('socket/updateChatConnection', _chatConnection)

      // eslint-disable-next-line no-underscore-dangle
      const _connection = await io(process.env.VUE_APP_SOCKET_URL,
          {
            reconnection: true,
            query: {
              uuid: this.$store.getters['auth/userId'],
              'x-bpsh-connect-api-key': process.env.VUE_APP_API_KEY,
              'x-bpsh-connect-token': this.$store.getters['auth/token'],
            },
            transports: ['websocket', 'polling', 'flashsocket'],
          })

      await this.$store.dispatch('socket/updateConnection', _connection)
      // await this.$store.dispatch('socket/updateChatConnection', _connection) // TODO: çoklu socket olunca bu satırı sil!!!

      _connection.on('reconnecting', () => {
        this.$store.dispatch('socket/updateConnecting', true)
      })
      _chatConnection.on('reconnecting', () => {
        this.$store.dispatch('socket/updateConnecting', true)
      })

      _connection.on('connect', () => {
        this.$store.dispatch('socket/updateConnecting', false)
        this.$store.dispatch('socket/updateConnected', true)

        _connection.on('appCallRequest', async (data, ack) => {
          ack('appCallRequestACK')

          await this.$store.dispatch('agora/setCallRequestInfo', data)

          useJwt.getUser(data.from).then(async response => {
            await this.$store.dispatch('app/setModalCallType', data.type === 'APP_CALL_REQUEST' ? 'audio' : 'video')
            this.triggerShowAgoraCall(response.data.body, 'incoming')
          })
        })

        _connection.on('notification', async data => {
          console.log('notification data', data)
          // if (data.title) {
          //   this.$toast({
          //     component: Notification,
          //     props: {
          //       data: data,
          //       router: this.$router,
          //     }
          //   }, {
          //     position: "top-right",
          //     closeButton: false,
          //     timeout: 10000,
          //     pauseOnHover: true,
          //     closeOnClick: true,
          //     hideProgressBar: false,
          //   });
          // }
        })
      })

      _chatConnection.on('connect', () => {
        this.$store.dispatch('socket/updateConnecting', false)
        this.$store.dispatch('socket/updateConnected', true)
      })
    },
    // eslint-disable-next-line consistent-return
    phoneNumberVerificationCode() {
      if (!this.valid) {
        this.$refs.phoneForm.setErrors({
          phone: this.$t('Check phone number'),
        })
        return false
      }
      this.$refs.phoneForm.validate().then(success => {
        if (success) {
          this.loading = true
          useJwt.phoneNumberVerification({phoneNumber: this.phoneWithCountryCode})
              .then(response => {
                showNotify(this.$toast, response.data.body, 'success')
                this.verificationType = 'phone'
                this.modalPinCode = true
                this.modalAddPhoneNumber = false
              })
              .catch(error => {
                throwDefaultError(this.$toast, error)
              })
              .finally(() => {
                this.loading = false
              })
        }
      })
    },
    phoneValidate(status) {
      this.valid = status ? status.valid : false
      this.$refs.phoneForm.reset()
    },
    triggerShowAgoraCall(user, callType = 'outgoing') {
      this.$store.dispatch('agora/setAgoraCallType', callType)
      this.$store.dispatch('agora/setAgoraCallUser', user)
      this.showAgoraCall = false
      this.showAgoraVideoCall = false

      if (this.modalCallType === 'audio') {
        setTimeout(() => {
          this.showAgoraCall = true
        }, 33)
      } else if (this.modalCallType === 'video') {
        setTimeout(() => {
          this.showAgoraVideoCall = true
        }, 33)
      }

      this.modalCall = false
    },
    triggerSippyCall() {
      if (!this.userPhoneNumber2) {
        this.modalAddPhoneNumberShowDiamond = false
        this.modalAddPhoneNumber = true
        this.verificationType = 'phone'
      } else {
        this.loading = true
        useJwt.callUser(this.modalCallUser.id || this.modalCallUser.userId)
            .then(() => {
              showNotify(this.toast, this.$t('Calling successful'), 'success')
            })
            .catch(error => {
              const {response} = error
              throwDefaultError(this.$toast, error)
              // eslint-disable-next-line eqeqeq
              if (response && response.data?.header?.error?.errorCode == 215) {
                store.dispatch('app/setModalAddPhoneNumberShowDiamond', false)
                store.dispatch('app/setModalAddPhoneNumber', true)
                store.dispatch('app/setVerificationType', 'phone')
              }
            })
            .finally(() => {
              this.loading = false;
              this.modalCall = false;
              this.modalCallSuggestion = false
            })
      }
    },
    sendAgainPinCode() {
      // eslint-disable-next-line no-empty
      if (this.verificationType === 'phone') {

        // eslint-disable-next-line no-empty
      } else if (this.verificationType === 'email') {

      }
    },
    verification() {
      if (this.verificationType === 'phone') {
        this.loading = true
        const data = {
          phoneNumber: this.phoneWithCountryCode,
          pinCode: this.password,
        }
        useJwt.phoneNumberUpdate(data)
            .then(response => {
              // eslint-disable-next-line no-underscore-dangle
              const _user = response.data.body
              if (_user.phoneNumber) {
                this.$store.dispatch('auth/updateUser', _user)
                showNotify(this.$toast, this.$t('Adding a phone number is successful'), 'success')
                this.modalAddPhoneNumber = false
                this.modalPinCode = false
                this.phone = ''
              } else {
                throwDefaultError(this.$toast)
              }
            })
            .catch(error => {
              throwDefaultError(this.$toast, error)
            })
            .finally(() => {
              this.loading = false
              this.password = ''
            })
      } else if (this.verificationType === 'email') {
        this.loading = true
        const data = {
          email: this.email,
          pinCode: this.password,
        }
        useJwt.emailUpdate(data)
            .then(response => {
              // eslint-disable-next-line no-underscore-dangle
              const _user = response.data.body
              if (_user.email) {
                this.$store.dispatch('auth/updateUser', _user)
                showNotify(this.$toast, this.$t('Adding a email address is successful'), 'success')
                this.modalAddEmail = false
                this.modalPinCode = false
                this.email = ''
              } else {
                throwDefaultError(this.$toast)
              }
            })
            .catch(error => {
              throwDefaultError(this.$toast, error)
            })
            .finally(() => {
              this.loading = false
              this.password = ''
            })
      }
    },
  },
  setup() {
    const {skin, skinClasses} = useAppConfig()
    const {enableScrollToTop} = $themeConfig.layout

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    const modalDownloadMobileApp = computed({
      get: () => store.getters['app/modalDownloadMobileApp'],
      set: val => {
        store.dispatch('app/setModalDownloadMobileApp', val)
      },
    })

    const oopsModalText = computed(() => store.getters['app/oopsModalText'])
    const oopsModal = computed({
      get: () => store.getters['app/oopsModal'],
      set: val => {
        store.dispatch('app/setOopsModal', val)
      },
    })

    const paymentHtml = computed(() => store.getters['payment/paymentHtml'])
    const paymentData = computed({
      get: () => store.getters['payment/paymentData'],
      set: val => {
        store.dispatch('payment/setPaymentData', val)
      },
    })
    const paymentStatus = computed({
      get: () => store.getters['payment/status'],
      set: val => {
        store.dispatch('payment/setStatus', val)
      },
    })

    const showAgoraCall = computed({
      get: () => store.getters['agora/showAgoraCall'],
      set: val => {
        store.dispatch('agora/setShowAgoraCall', val)
      },
    })

    const showAgoraVideoCall = computed({
      get: () => store.getters['agora/showAgoraVideoCall'],
      set: val => {
        store.dispatch('agora/setShowAgoraVideoCall', val)
      },
    })

    const modalCallSuggestion = computed({
      get: () => store.getters['app/modalCallSuggestion'],
      set: val => {
        store.dispatch('app/setModalCallSuggestion', val)
      },
    })

    const loading = ref(false)

    const modalAddPhoneNumber = computed({
      get: () => store.getters['app/modalAddPhoneNumber'],
      set: val => {
        store.dispatch('app/setModalAddPhoneNumber', val)
      },
    })
    const modalAddPhoneNumberShowDiamond = computed({
      get: () => store.getters['app/modalAddPhoneNumberShowDiamond'],
      set: val => {
        store.dispatch('app/setModalAddPhoneNumberShowDiamond', val)
      },
    })

    const modalAddEmail = computed({
      get: () => store.getters['app/modalAddEmail'],
      set: val => {
        store.dispatch('app/setModalAddEmail', val)
      },
    })

    const modalPinCode = computed({
      get: () => store.getters['app/modalPinCode'],
      set: val => {
        store.dispatch('app/setModalPinCode', val)
      },
    })

    const verificationType = computed({
      get: () => store.getters['app/verificationType'],
      set: val => {
        store.dispatch('app/setVerificationType', val)
      },
    })

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const {width: windowWidth} = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    const closeModal = () => {
      store.dispatch('app/setModalDownloadMobileApp', false)
    }

    /**
     * Kullanıcının telefon numarası yoksa eklemesi için telefon numarası ekleme modal'ını göster
     */
    const user = computed(() => store.getters['auth/user'])
    const userPhoneNumber = computed(() => store.getters['auth/userPhoneNumber'])
    if (user.value && !userPhoneNumber.value) {
      // modalAddPhoneNumberShowDiamond.value = true
      // modalAddPhoneNumber.value = true
      // verificationType.value = 'phone'
    }
    watch(userPhoneNumber, val => {
      if (user.value && !val) {
        // modalAddPhoneNumberShowDiamond.value = true
        // modalAddPhoneNumber.value = true
        // verificationType.value = 'phone'
      }
    })

    /**
     * Kullanıcının emaili yoksa eklemesi için telefon numarası ekleme modal'ını göster
     */
    const userEmail = computed(() => store.getters['auth/userEmail'])

    const checkModalAddEmailShowed = () => {
      const modalAddEmailShowedTime = localStorage.getItem('modalAddEmailShowedTime')
      if (modalAddEmailShowedTime) {
        const now = new Date().getTime()
        // eslint-disable-next-line radix
        const diff = now - parseInt(modalAddEmailShowedTime)
        if (diff < 1000 * 60 * 60 * 24 * 1) { // 1 gün
          return true
        }
      }

      return false
    }

    if (user.value && !userEmail.value && !checkModalAddEmailShowed()) {
      modalAddEmail.value = true
      verificationType.value = 'email'
      localStorage.setItem('modalAddEmailShowedTime', new Date().getTime())
    }

    watch(userEmail, val => {
      if (user.value && !val && !checkModalAddEmailShowed()) {
        modalAddEmail.value = true
        verificationType.value = 'email'
        localStorage.setItem('modalAddEmailShowedTime', new Date().getTime())
      }
    })

    const checkVersion = () => {
      axios.get('/v.json')
          .then(response => {
            try {
              // eslint-disable-next-line radix
              const version = parseInt(response.data.v.replaceAll('.', ''))
              let currentVersion = localStorage.getItem('version') || '1.0.0'
              // eslint-disable-next-line radix
              currentVersion = parseInt(currentVersion.replaceAll('.', ''))

              localStorage.setItem('version', response.data.v)

              if (currentVersion < version) {
                window.location.reload()
              }
            } catch (e) {
              console.log('e', e)
            }
          })
    }
    setInterval(() => {
      checkVersion()
    }, 60 * 1000)

    return {
      badgeAppleStore,
      badgeGooglePlay,
      closeModal,
      email: ref(''),
      enableScrollToTop,
      iconDiamond2,
      imageCreditsOops,
      imageCreditsOopsSadFace,
      loading,
      modalCallSuggestion,
      modalAddEmail,
      modalAddPhoneNumber,
      modalAddPhoneNumberShowDiamond,
      modalDownloadMobileApp,
      modalPinCode,
      oopsModal,
      oopsModalText,
      paymentData,
      paymentHtml,
      paymentStatus,
      qrCodeImage,
      showAgoraCall,
      showAgoraVideoCall,
      skinClasses,
      user,
      verificationType,
    }
  },
}
</script>

<style lang="scss" scoped>
.password-information {
  font-family: 'Lexend', sans-serif;

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #000000;
    text-align: center;
  }

  p {
    font-family: inherit;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #8893A4;
  }

  .send-again {
    margin-top: 14px;
    font-family: inherit;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #8893A4;

    .link {
      cursor: pointer;
      color: #F9447A;
    }
  }
}

.credits-oops {
  .credits-oops-image {
    position: relative;

    .credit-oops-image-text {
      color: #fff;
      font-family: 'Lexend', sans-serif;
      font-size: 32px;
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      text-transform: uppercase;
    }
  }
}
</style>

<style lang="scss">
div.vue-pincode-input-wrapper {
  .vue-pincode-input {
    margin: 0 8px;
    padding: 5px;
    text-align: center;
    border: none;
    box-shadow: 0 0 2px rgb(0 0 0 / 50%);
    border-radius: 12px;
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    color: #000000;

    &:focus {
      box-shadow: none;
      border: 2px solid #000;
    }
  }
}
</style>
