import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { localize } from 'vee-validate'

Vue.use(VueI18n)
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function getQueryStringParameterByName(name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const queryParamLocale = getQueryStringParameterByName('lang')

const locales = ['en', 'tr', 'sa']
const defaultLocale = 'tr'

localize(defaultLocale)

let browserLocale = navigator.language || navigator.userLanguage;
try {
    browserLocale = browserLocale.split('-')[0]
} catch (e) {
    browserLocale = 'tr'
}

let currentLocale = locales.includes(browserLocale) ? browserLocale : defaultLocale

if (locales.includes(queryParamLocale))
    currentLocale = queryParamLocale
else if (locales.includes(localStorage.getItem('locale')))
    currentLocale = localStorage.getItem('locale')

export default new VueI18n({
  locale: currentLocale,
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
})
