// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import router from '@/router'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
    // Will be used by this service for making API calls
    axiosIns = null

    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // For Refreshing Token
    subscribers = []

    constructor(axiosIns, jwtOverrideConfig) {
        this.axiosIns = axiosIns
        this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

        // Request Interceptor
        this.axiosIns.interceptors.request.use(
          config => {
              // Get token from localStorage
              const accessToken = this.getToken()

              // If token is present add it to request's Authorization Header
              if (accessToken) {
                  // eslint-disable-next-line no-param-reassign
                  config.headers['x-bpsh-connect-token'] = accessToken
              }
              // eslint-disable-next-line no-param-reassign
              config.headers['x-bpsh-connect-api-key'] = this.jwtConfig.apiKey
              // eslint-disable-next-line no-param-reassign
              config.headers['Content-Type'] = 'application/json'

              return config
          },
          error => Promise.reject(error),
        )

        // Add request/response interceptor
        this.axiosIns.interceptors.response.use(
          response => response,
          error => {
              // const { config, response: { status } } = error
              const { response } = error

              // if (status === 401) {
              if (response && response.status === 401) {
                  this.logout()
              }
              return Promise.reject(error)
          },
        )
    }

    // onAccessTokenFetched(accessToken) {
    //   this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    // }

    // addSubscriber(callback) {
    //   this.subscribers.push(callback)
    // }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    // getRefreshToken() {
    //   return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    // }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    // setRefreshToken(value) {
    //   localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    // }

    login(...args) {
        return this.axiosIns.post(this.jwtConfig.login, ...args)
    }

    emailLogin(...args) {
        return this.axiosIns.post(this.jwtConfig.emailLogin, ...args)
    }

    forgotPassword(phone, lang = 'tr') {
        return this.axiosIns.get(`${this.jwtConfig.requestPassword.replace('{{phoneNumber}}', phone)}?lang=${lang}`)
    }

    forgotPasswordEmail(data) {
        return this.axiosIns.post(this.jwtConfig.requestPasswordEmail, data)
    }

    checkUsername(...args) {
        return this.axiosIns.post(this.jwtConfig.usernameValidation, ...args)
    }

    phoneVerification(phone, lang = 'tr') {
        return this.axiosIns.post(`${this.jwtConfig.phoneVerification.replace('{{phoneNumber}}', phone)}?lang=${lang}`)
    }

    getInterests() {
        return this.axiosIns.get(this.jwtConfig.interests)
    }

    phoneCheck(phone, lang = 'tr') {
        return this.axiosIns.post(`${this.jwtConfig.phoneCheck.replace('{{phoneNumber}}', phone)}?lang=${lang}`)
    }

    register(...args) {
        return this.axiosIns.post(this.jwtConfig.register, ...args)
    }

    settingsRegister(...args) {
        return this.axiosIns.post(this.jwtConfig.settingsRegister, ...args)
    }

    emailRegister(...args) {
        return this.axiosIns.post(this.jwtConfig.emailRegister, ...args)
    }

    loggedUsers(offset = 0, limit = 100, ...args) {
        const replacedEndpoint = this.jwtConfig.loggedUsers.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
        return this.axiosIns.post(replacedEndpoint, ...args)
    }

    usersList(offset = 0, limit = 100, type = 1, ...args) {
        let replacedEndpoint = this.jwtConfig.usersList
          .replace('{{type}}', type.toString())

        if (offset || offset === 0) replacedEndpoint = replacedEndpoint.replace('{{offset}}', offset.toString())
        else replacedEndpoint = replacedEndpoint.replace('/{{offset}}', '')

        if (limit) replacedEndpoint = replacedEndpoint.replace('{{limit}}', limit.toString())
        else replacedEndpoint = replacedEndpoint.replace('/{{limit}}', '')

        return this.axiosIns.post(replacedEndpoint, ...args)
    }

    nearByRegions() {
        return this.axiosIns.get(this.jwtConfig.nearByRegions)
    }

    usersListAutoComplete(...args) {
        return this.axiosIns.post(this.jwtConfig.usersListAutoComplete, ...args)
    }

    getUser(id) {
        return this.axiosIns.get(this.jwtConfig.getUser.replace('{{id}}', id))
    }

    // refreshToken() {
    //   return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
    //     refreshToken: this.getRefreshToken(),
    //   })
    // }

    // eslint-disable-next-line class-methods-use-this
    logout() {
        // Remove user from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove user from localStorage
        localStorage.removeItem('user')

        // Reset ability
        // this.$ability.update(initialAbility)

        // Redirect to login page
        router.push({ name: 'auth-login' })
        window.location.reload()

        // this.axiosIns.post(this.jwtConfig.logout)
    }

    favoriteUsers(offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.favoriteUsers.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    addFavoriteUser(userId) {
        return this.axiosIns.post(this.jwtConfig.addFavoriteUser.replace('{{userUuid}}', userId))
    }

    removeFavoriteUser(userId) {
        return this.axiosIns.delete(this.jwtConfig.removeFavoriteUser.replace('{{userUuid}}', userId))
    }

    callUser(userId) {
        return this.axiosIns.post(this.jwtConfig.callUser.replace('{{userUuid}}', userId))
    }

    appCall(userId) {
        return this.axiosIns.post(this.jwtConfig.appCall.replace('{{userUuid}}', userId))
    }

    appVideoCall(userId) {
        return this.axiosIns.post(this.jwtConfig.appVideoCall.replace('{{userUuid}}', userId))
    }

    appCallCancel(data) {
        return this.axiosIns.post(this.jwtConfig.appCallCancel, data)
    }

    heartBeat(data) {
        return this.axiosIns.post(this.jwtConfig.heartBeat, data)
    }

    getSettings() {
        return this.axiosIns.get(this.jwtConfig.getSettings)
    }

    updateSettings(data) {
        return this.axiosIns.put(this.jwtConfig.updateSettings, data)
    }

    updateLanguage(lang) {
        return this.axiosIns.put(this.jwtConfig.updateLanguage.replace('{{lang}}', lang))
    }

    getMe(token = null) {
        if (token) {
            return this.axiosIns.get(this.jwtConfig.me, {
                headers: {
                    'x-bpsh-connect-token': token,
                },
            })
        }
        return this.axiosIns.get(this.jwtConfig.me)
    }

    getMyInterests() {
        return this.axiosIns.get(this.jwtConfig.myInterests)
    }

    updateMyInterests(data, token = null) {
        if (token) {
            return this.axiosIns.post(this.jwtConfig.updateMyInterests, data, {
                headers: {
                    'x-bpsh-connect-token': token,
                },
            })
        }
        return this.axiosIns.post(this.jwtConfig.updateMyInterests, data)
    }

    updateMe(data, token = null) {
        if (token) {
            return this.axiosIns.put(this.jwtConfig.updateMe, data, {
                headers: {
                    'x-bpsh-connect-token': token,
                },
            })
        }
        return this.axiosIns.put(this.jwtConfig.updateMe, data)
    }

    updateProfile(data, token = null) {
        if (token) {
            return this.axiosIns.put(this.jwtConfig.updateProfile, data, {
                headers: {
                    'x-bpsh-connect-token': token,
                },
            })
        }
        return this.axiosIns.put(this.jwtConfig.updateProfile, data)
    }

    updatePhone(phone) {
        return this.axiosIns.put(this.jwtConfig.updateUserPhone.replace('{{phoneNumber}}', phone))
    }

    putPendingUser(data) {
        return this.axiosIns.put(this.jwtConfig.putPendingUser, data)
    }

    getNotifications(offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.notifications.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    unReadNotificationsCount() {
        return this.axiosIns.get(this.jwtConfig.unReadNotificationsCount)
    }

    markAllNotificationsAsRead() {
        return this.axiosIns.put(this.jwtConfig.markAllNotificationsAsRead)
    }

    markOneNotificationAsRead(id) {
        return this.axiosIns.put(this.jwtConfig.markOneNotificationAsRead.replace('{{notificationUuid}}', id))
    }

    updateSearchCriteria(data, token = null) {
        if (token) {
            return this.axiosIns.put(this.jwtConfig.updateSearchCriteria, data, {
                headers: {
                    'x-bpsh-connect-token': token,
                },
            })
        }
        return this.axiosIns.put(this.jwtConfig.updateSearchCriteria, data)
    }

    removeAccount(id) {
        return this.axiosIns.delete(this.jwtConfig.removeAccount.replace('{{reasonTypeId}}', id))
    }

    getReasons() {
        return this.axiosIns.get(this.jwtConfig.getReasons)
    }

    discoverList(offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.discoverList.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    getChatUsers(offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.chatUserList.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    getChatUsers2(offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.chatUserList2.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    getChat(userId, offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.getChat.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
          .replace('{{userUuid}}', userId.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    getChat2(userId, offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.getChat2.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
          .replace('{{userUuid}}', userId.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    chatMarkSeen(userId) {
        return this.axiosIns.post(this.jwtConfig.chatMarkSeen, { uuid: userId })
    }

    chatMarkSeen2(userId) {
        return this.axiosIns.post(this.jwtConfig.chatMarkSeen2, { uuid: userId })
    }

    getGifts() {
        return this.axiosIns.get(this.jwtConfig.getGifts)
    }

    getSentGifts(offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.getSentGifts.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    getReceivedGifts(offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.getReceivedGifts.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    getSentGiftMedias(offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.getSentGiftMedias.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    getReceivedGiftMedias(offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.getReceivedGiftMedias.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    getMultimediaCredits() {
        return this.axiosIns.get(this.jwtConfig.getMultimediaCredits)
    }

    accountCredits(offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.accountCredits.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    updateOnlineStatus(status) {
        return this.axiosIns.put(this.jwtConfig.updateOnlineStatus.replace('{{status}}', status))
    }

    getProducts() {
        return this.axiosIns.get(this.jwtConfig.getProducts)
    }

    getChatProducts() {
        return this.axiosIns.get(this.jwtConfig.getChatProducts)
    }

    buyChatProduct(data) {
        return this.axiosIns.post(this.jwtConfig.buyChatProduct, data)
    }

    updateCallType(type) {
        return this.axiosIns.put(this.jwtConfig.updateCallType.replace('{{type}}', type.toString()))
    }

    getBlockedUsers(offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.getBlockedUsers.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    getReverseFavorites(offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.getReverseFavorites.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    getCalls(offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.getCalls.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    getCallHistory(offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.getCallHistory.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())
        return this.axiosIns.get(replacedEndpoint)
    }

    sendGift(data) {
        return this.axiosIns.post(this.jwtConfig.sendGift, data)
    }

    getMedias(userId) {
        return this.axiosIns.get(this.jwtConfig.getMedias.replace('{{ownId}}', userId))
    }

    getImages(userId) {
        return this.axiosIns.get(this.jwtConfig.getImages.replace('{{ownId}}', userId))
    }

    getVideos(userId) {
        return this.axiosIns.get(this.jwtConfig.getVideos.replace('{{ownId}}', userId))
    }

    uploadImage(data) {
        return this.axiosIns.post(this.jwtConfig.uploadImage, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    }

    uploadVideo(data) {
        return this.axiosIns.post(this.jwtConfig.uploadVideo, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    }

    removeImage(id) {
        return this.axiosIns.delete(this.jwtConfig.removeImage.replace('{{imageId}}', id))
    }

    removeVideo(id) {
        return this.axiosIns.delete(this.jwtConfig.removeVideo.replace('{{videoId}}', id))
    }

    toggleImageStatus(id) {
        return this.axiosIns.put(this.jwtConfig.toggleImageStatus.replace('{{imageId}}', id))
    }

    setUserProfileImage(id) {
        return this.axiosIns.put(this.jwtConfig.setUserProfileImage.replace('{{imageId}}', id))
    }

    paymentPostnetInit(data) {
        return this.axiosIns.post(this.jwtConfig.paymentPostnetInit, data)
    }

    paymentPostnetPerform(data) {
        return this.axiosIns.post(this.jwtConfig.paymentPostnetPerform, data)
    }

    blockUser(userId) {
        return this.axiosIns.post(this.jwtConfig.blockUser.replace('{{userUuid}}', userId))
    }

    unBlockUser(userId) {
        return this.axiosIns.delete(this.jwtConfig.unBlockUser.replace('{{userUuid}}', userId))
    }

    reportUser(data) {
        return this.axiosIns.post(this.jwtConfig.reportUser, data)
    }

    support(data) {
        return this.axiosIns.post(this.jwtConfig.support, data)
    }

    loginWithApple(data) {
        return this.axiosIns.post(this.jwtConfig.loginApple, data)
    }

    loginWithFacebook(data) {
        return this.axiosIns.post(this.jwtConfig.loginFacebook, data)
    }

    loginWithGoogle(data) {
        return this.axiosIns.post(this.jwtConfig.loginGoogle, data)
    }

    getCashouts() {
        return this.axiosIns.get(this.jwtConfig.getCashouts)
    }

    postCashout(data = null) {
        return this.axiosIns.post(this.jwtConfig.postCashout, data)
    }

    postSupportCashout(data = null) {
        return this.axiosIns.post(this.jwtConfig.postSupportCashout, data)
    }

    postPaymentsCashout(data = null) {
        return this.axiosIns.post(this.jwtConfig.postPaymentsCashout, data)
    }

    paybymePurchase(data) {
        return this.axiosIns.post(this.jwtConfig.paybymePurchase, data)
    }

    phoneNumberUpdate(data) {
        return this.axiosIns.put(this.jwtConfig.phoneNumberUpdate, data)
    }

    phoneNumberVerification(data) {
        return this.axiosIns.put(this.jwtConfig.phoneNumberVerification, data)
    }

    emailUpdate(data) {
        return this.axiosIns.put(this.jwtConfig.emailUpdate, data)
    }

    emailVerification(data) {
        return this.axiosIns.post(this.jwtConfig.emailVerification, data)
    }

    emailUpdateVerification(data) {
        return this.axiosIns.put(this.jwtConfig.emailUpdateVerification, data)
    }

    postLogout() {
        return this.axiosIns.post(this.jwtConfig.logout)
    }

    updateCameraStatus(status) {
        return this.axiosIns.put(this.jwtConfig.updateCameraStatus.replace('{{status}}', status))
    }

    creditsCharged() {
        return this.axiosIns.get(this.jwtConfig.creditsCharged)
    }

    campaigns() {
        return this.axiosIns.get(this.jwtConfig.campaigns)
    }

    campaignsDisplayed(data) {
        return this.axiosIns.post(this.jwtConfig.campaignsDisplayed, data)
    }

    couponCodeToAddDiamond(data) {
        return this.axiosIns.post(this.jwtConfig.couponCodeToAddDiamond, data)
    }

    couponCodeCheckBeforePurchasing(data) {
        return this.axiosIns.post(this.jwtConfig.couponCodeCheckBeforePurchasing, data)
    }

    // v2 API
    checkNudity(data) {
        return this.axiosIns.post(this.jwtConfig.checkNudity, data)
    }

    getPosts(offset = 0, limit = 100) {
        const replacedEndpoint = this.jwtConfig.posts.replace('{{offset}}', offset.toString())
          .replace('{{limit}}', limit.toString())

        return this.axiosIns.get(replacedEndpoint)
    }
    likePost(data) {
        return this.axiosIns.post(this.jwtConfig.likePost, data)
    }
    likePostDelete(id) {
        return this.axiosIns.delete(this.jwtConfig.likePostDelete.replace('{{id}}', id))
    }
    deletePost(id) {
        return this.axiosIns.delete(this.jwtConfig.deletePost.replace('{{id}}', id))
    }

    newPost(data) {
        const headers = { 'Content-Type': 'multipart/form-data' }

        return this.axiosIns.post(this.jwtConfig.newPost, data, headers)
    }
}
