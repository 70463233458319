export function updateUser ({ commit }, payload) {
  commit('updateUser', payload)
}

export function updateTempUser2 ({ commit }, payload) {
  commit('updateTempUser2', payload)
}

export function setToken ({ commit }, payload) {
  commit('setToken', payload)
}

export function setAppleUser ({ commit }, payload) {
  commit('setAppleUser', payload)
}

export function setAuthType ({ commit }, payload) {
  localStorage.setItem('authType', payload)
  commit('setAuthType', payload)
}

export function setFacebookUser ({ commit }, payload) {
  commit('setFacebookUser', payload)
}

export function setGoogleUser ({ commit }, payload) {
  commit('setGoogleUser', payload)
}

export function setTempUser ({ commit }, payload) {
  commit('setTempUser', payload)
}

export function setSocialRegistering ({ commit }, payload) {
  commit('setSocialRegistering', payload)
}

export function logout ({ commit }) {
  commit('logout')
}
