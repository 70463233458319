const baseUrl = process.env.VUE_APP_BASE_URL
const baseUrl2 = process.env.VUE_APP_BASE_URL2

export default {
  apiKey: process.env.VUE_APP_API_KEY,

  // Endpoints
  loginEndpoint: `${baseUrl}/jwt/login`,
  registerEndpoint: `${baseUrl}/jwt/register`,
  refreshEndpoint: `${baseUrl}/jwt/refresh-token`,
  logoutEndpoint: `${baseUrl}/jwt/logout`,

  login: `${baseUrl}/users/login`, // {"phoneNumber":"{{phoneNumber}}","password":"{{loginPass}}"}
  emailLogin: `${baseUrl}/users/email-login`, // {"email":"{{email}}","password":"{{loginPass}}"}
  googleLogin: `${baseUrl}/users/google-login`, // {"accessToken":"{{googleAccessToken}}","userId":"{{googleUserId}}"}
  appleLogin: `${baseUrl}/users/apple-login`, // {"authorizationCode":"{{appleAuthorizationCode}}"}
  register: `${baseUrl}/users`, // {"firstName":"first_name","lastName":"last_name", "gender": "male", "phoneNumber" :"05321111111", "userName" : "user_name", "password":"pass", "email": "email@email.com", "facebook":"1234567", "city":"Adana", "description":"abcdef", "year":"1980", "month":"1", "date":"1", "lookingForGender":"female"}
  settingsRegister: `${baseUrl}/settings/register`, // {"deviceToken":"deviceToken","playerId":"playerId"}
  emailRegister: `${baseUrl}/users/email-register`,
  usernameValidation: `${baseUrl}/users/username/validation`, // {"username" : "user_name"}
  phoneVerification: `${baseUrl}/users/verification/{{phoneNumber}}`,
  phoneCheck: `${baseUrl}/users/phonecheck/{{phoneNumber}}`,
  emailVerification: `${baseUrl}/users/email-verification`,
  me: `${baseUrl}/users/me`,
  updateMe: `${baseUrl}/users`,
  logout: `${baseUrl}/users/logout`,
  updateProfile: `${baseUrl}/userprofiles`,
  putPendingUser: `${baseUrl}/users/pending-user`,
  nearByRegions: `${baseUrl}/users/list/nearby-regions`,
  usersList: `${baseUrl}/users/list/{{type}}/{{offset}}/{{limit}}`,
  usersListAutoComplete: `${baseUrl}/users/list/auto-complete`,
  loggedUsers: `${baseUrl}/users/logged/{{offset}}/{{limit}}`, // {"searchType":"0", "gender":"female", "ageFrom":"30", "ageTo": "50", "city": "Ankara"} {"searchType":"1"} {"searchType":"2"} {"searchType":"3", "randomSearchType":"2"}
  shortUsers: `${baseUrl}/users/short/{{offset}}/{{limit}}`, // {"searchType":"0", "gender":"female", "ageFrom":"30", "ageTo": "50", "city": "Ankara"}
  randomUsers: `${baseUrl}/users/random`,
  getUser: `${baseUrl}/users/{{id}}`,
  getMatchingUsers: `${baseUrl}/users/matching/{{offset}}/{{limit}}`,
  userMergeFacebook: `${baseUrl}/users/merge-facebook`, // {"phoneNumber":"05321111111", "password":"1234", "facebook":"facebookId"}
  requestPassword: `${baseUrl}/users/request-password/{{phoneNumber}}`,
  requestPasswordEmail: `${baseUrl}/users/email-request-pincode`,
  searchUsersByUsername: `${baseUrl}users/search/username/{{offset}}/{{limit}}`, // {"searchType":"0", "gender":"female", "ageFrom":"30", "ageTo": "50", "city": "Ankara"}
  searchUsers: `${baseUrl}/users/search/{{offset}}/{{limit}}`, // {"searchType":"0", "gender":"female", "ageFrom":"30", "ageTo": "50", "city": "Ankara"}
  updateUserPhone: `${baseUrl}/users/phonenumber/{{phoneNumber}}`,
  userInvalidatePhone: `${baseUrl}/users/invalidate/{{phoneNumber}}`,
  updateUser: `${baseUrl}/users`, // {"firstName":"tom", "lastName":"brown", "email":"tom@brown.com"}
  interests: `${baseUrl}/interest/types?lang=tr`,
  myInterests: `${baseUrl}/interest`,
  updateMyInterests: `${baseUrl}/interest`,
  favoriteUsers: `${baseUrl}/favorites/search/{{offset}}/{{limit}}`,
  addFavoriteUser: `${baseUrl}/favorites/{{userUuid}}`,
  removeFavoriteUser: `${baseUrl}/favorites/{{userUuid}}`,
  callUser: `${baseUrl}/sippy/webcall/{{userUuid}}`,
  getSettings: `${baseUrl}/settings`,
  updateSettings: `${baseUrl}/settings`,
  notifications: `${baseUrl}/notifications/{{offset}}/{{limit}}`,
  unReadNotificationsCount: `${baseUrl}/notifications/notread`,
  markAllNotificationsAsRead: `${baseUrl}/notifications`,
  markOneNotificationAsRead: `${baseUrl}/notifications/{{notificationUuid}}`,
  updateSearchCriteria: `${baseUrl}/searchcriterias`,
  removeAccount: `${baseUrl}/accountdelete/{{reasonTypeId}}`,
  getReasons: `${baseUrl}/accountdelete/reason-types`,
  discoverList: `${baseUrl}/discover/list/{{offset}}/{{limit}}`,
  chatUserList: `${baseUrl}/chat/friends/{{offset}}/{{limit}}`,
  chatUserList2: `${baseUrl2}/chat/friends/{{offset}}/{{limit}}`,
  getChat: `${baseUrl}/chat/total-history/{{userUuid}}/{{offset}}/{{limit}}`,
  getChat2: `${baseUrl2}/chat/total-history/{{userUuid}}/{{offset}}/{{limit}}`,
  chatMarkSeen: `${baseUrl}/chat/markSeen`,
  chatMarkSeen2: `${baseUrl2}/chat/markSeen`,
  accountCredits: `${baseUrl}/accounts/credits/{{offset}}/{{limit}}`,
  getGifts: `${baseUrl}/gift`,
  getSentGifts: `${baseUrl}/gift/sent/{{offset}}/{{limit}}`,
  getSentGiftMedias: `${baseUrl}/gift/sent/multimedia/{{offset}}/{{limit}}`,
  getReceivedGifts: `${baseUrl}/gift/received/{{offset}}/{{limit}}`,
  getReceivedGiftMedias: `${baseUrl}/gift/received/multimedia/{{offset}}/{{limit}}`,
  getMultimediaCredits: `${baseUrl}/gift/multimedia`,
  updateOnlineStatus: `${baseUrl}/userprofiles/onlineStatus/{{status}}`,
  getProducts: `${baseUrl}/product?lang=tr`,
  getChatProducts: `${baseUrl}/chat/products?lang=tr`,
  buyChatProduct: `${baseUrl}/chat/buyProduct`,
  updateCallType: `${baseUrl}/userprofiles/callType/{{type}}`,
  getBlockedUsers: `${baseUrl}/blocks/search/{{offset}}/{{limit}}`,
  getReverseFavorites: `${baseUrl}/favorites/searchby/{{offset}}/{{limit}}`,
  getCalls: `${baseUrl}/accounts/history/{{offset}}/{{limit}}`,
  getCallHistory: `${baseUrl}/accounts/call-history/{{offset}}/{{limit}}`,
  sendGift: `${baseUrl}/gift/send`,
  getMedias: `${baseUrl}/multimedia/user/{{ownId}}`, // GET
  getImages: `${baseUrl}/multimedia/images/user/{{ownId}}`, // GET
  getVideos: `${baseUrl}/multimedia/videos/user/{{ownId}}`, // GET
  uploadImage: `${baseUrl}/multimedia/images`, // POST
  uploadVideo: `${baseUrl}/multimedia/videos`, // POST
  removeImage: `${baseUrl}/multimedia/images/{{imageId}}`, // DELETE
  removeVideo: `${baseUrl}/multimedia/videos/{{videoId}}`, // DELETE
  toggleImageStatus: `${baseUrl}/multimedia/toggleStatus/{{imageId}}`, // PUT
  setUserProfileImage: `${baseUrl}/userprofiles/profileImage/{{imageId}}`, // PUT
  paymentPostnetInit: `${baseUrl}/payments/posnet/init`, // POST
  paymentPostnetPerform: `${baseUrl}/payments/posnet/perform`, // POST
  blockUser: `${baseUrl}/blocks/{{userUuid}}`, // POST
  unBlockUser: `${baseUrl}/blocks/{{userUuid}}`, // DELETE
  reportUser: `${baseUrl}/support/report`, // POST
  support: `${baseUrl}/support`, // POST
  loginGoogle: `${baseUrl}/users/google-login`, // POST
  loginFacebook: `${baseUrl}/users/fb-login`, // POST
  loginApple: `${baseUrl}/users/apple-login`, // POST
  getCashouts: `${baseUrl}/payments/cashouts`, // GET
  postCashout: `${baseUrl}/payments/cashout`, // POST
  postSupportCashout: `${baseUrl}/support/cashout`, // POST
  postPaymentsCashout: `${baseUrl}/payments/cashout-request`, // POST
  paybymePurchase: `${baseUrl}/paybyme/purchase`, // POST
  phoneNumberUpdate: `${baseUrl}/users/phonenumber`, // PUT {"phoneNumber":"05321111111"}
  phoneNumberVerification: `${baseUrl}/users/phonenumber-verification`, // PUT {"phoneNumber":"05321111111", "pinCode":"1234"}
  emailUpdate: `${baseUrl}/users/email-update`, // PUT {"email": "email@email.com"}
  emailUpdateVerification: `${baseUrl}/users/email-update-verification`, // PUT {"email": "email@email.com", "pinCode":"1234"}
  updateLanguage: `${baseUrl}/userprofiles/lang/{{lang}}`,
  appCall: `${baseUrl}/app/call/{{userUuid}}`, // POST
  appVideoCall: `${baseUrl}/app/video-call/{{userUuid}}`, // POST
  appCallCancel: `${baseUrl}/app/cancel`, // POST
  heartBeat: `${baseUrl}/app/heartbeat`, // POST
  updateCameraStatus: `${baseUrl}/userprofiles/camera-on/{{status}}`, // POST
  creditsCharged: `${baseUrl}/app/credits-charged`, // GET
  campaigns: `${baseUrl}/campaigns`, // GET
  campaignsDisplayed: `${baseUrl}/campaigns/displayed`, // POST
  couponCodeToAddDiamond: `${baseUrl}/payments/coupon-code`, // POST { "code": "xyz" }
  couponCodeCheckBeforePurchasing: `${baseUrl}/payments/check-coupon-code`, // POST { "code": "xyz" }

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

  // v2 API
  checkNudity: `${baseUrl2}/check-nudity`,

  // v2 Posts
  newPost: `${baseUrl2}/posts`, // POST -> formData { content: '', file: file }
  posts: `${baseUrl2}/posts/{{offset}}/{{limit}}`, // GET
  userPosts: `${baseUrl2}/posts/user/{{uuid}}/{{offset}}/{{limit}}`, // GET
  deletePost: `${baseUrl2}/posts/{{id}}`, // DELETE
  managePost: `${baseUrl2}/posts/manage`, // POST -> body { post_id: 1, status: 0 }
  likePost: `${baseUrl2}/posts/like`, // POST -> body { post_id: 1 }
  likePostDelete: `${baseUrl2}/posts/like/{{id}}`, // DELETE
}
