import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
  faLeftLong, faArrowLeft, faArrowUp, faEllipsis, faPlus, faMinus, faChevronRight, faPaperPlane, faChevronDown,
  faChevronUp, faInfoCircle, faPlusSquare, faPlusCircle, faPlay,
} from '@fortawesome/free-solid-svg-icons'
import { faComments } from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(
  faLeftLong, faArrowLeft, faArrowUp, faEllipsis, faPlus, faMinus, faChevronRight, faPaperPlane, faComments,
  faChevronDown, faChevronUp, faInfoCircle, faPlusSquare, faPlusCircle, faPlay,
)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

import LoadingSpinner from '@/components/LoadingSpinner'
Vue.component('loading-spinner', LoadingSpinner)

Vue.config.productionTip = false

import { VBToggle } from 'bootstrap-vue'
Vue.directive('b-toggle', VBToggle)

import VueViewer from 'v-viewer'
Vue.use(VueViewer, {
  defaultOptions: {
    inline: false,
    button: true,
    navbar: true,
    title: false,
    toolbar: true,
    tooltip: false,
    movable: false,
    zoomable: true,
    rotatable: false,
    scalable: false,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source',
    filter (image) {
      return !image.dataset.disabled
    }
  }
})

// require("dotenv").config()

import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
Vue.use(VueTelInput)

import { localize } from 'vee-validate'
import tr from 'vee-validate/dist/locale/tr.json'
import en from 'vee-validate/dist/locale/en.json'
localize({tr, en})

import GoogleAuth from '@/config/oAuth/google_oAuth.js'
const gAuthOption = {
  clientId: process.env.VUE_APP_GOOGLE_ID || '821636087237-lotein8an1ln5e0imofqpiillf4f9qs9.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GoogleAuth, gAuthOption)

// Vue cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import Toast from 'vue-toastification'
Vue.use(Toast, {
  maxToasts: 20,
  newestOnTop: true
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
