export function setLoading ({ commit }, payload) {
  commit('setLoading', payload)
}
export function setPaymentData ({ commit }, payload) {
  commit('setPaymentData', payload)
}
export function setPaymentHtml ({ commit }, payload) {
  commit('setPaymentHtml', payload)
}
export function setStatus ({ commit }, payload) {
  commit('setStatus', payload)
}
