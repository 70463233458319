export function setAgoraCalling (state, payload) {
  state.agoraCalling = payload
}
export function setAgoraCallUser (state, payload) {
  state.agoraCallUser = payload
}
export function setAgoraVideoCallUser (state, payload) {
  state.agoraVideoCallUser = payload
}
export function setAgoraCallType (state, payload) {
  state.agoraCallType = payload
}
export function setAgoraClient (state, payload) {
  state.agoraClient = payload
}
export function setAgoraOptions (state, payload) {
  state.agoraOptions = {
    ...state.agoraOptions,
    ...payload,
  }
}
export function setAgoraChannelParameters (state, payload) {
  state.agoraChannelParameters = {
    ...state.agoraChannelParameters,
    ...payload,
  }
}
export function setCallRequestInfo (state, payload) {
  state.callRequestInfo = {
    ...payload,
  }
}
export function setShowAgoraCall (state, payload) {
  state.showAgoraCall = payload
}
export function setShowAgoraVideoCall (state, payload) {
  state.showAgoraVideoCall = payload
}
