export default function () {
  return {
    filter: null,
    initialFilter: {
      ageRange: [25, 40],
      ageFrom: 25,
      ageTo: 40,
      country: 'TR',
      gender: 'female',
    }
  }
}
