export function loading (state) {
  return state.loading
}
export function paymentData (state) {
  return state.paymentData
}
export function paymentHtml (state) {
  return state.paymentHtml
}
export function status (state) {
  return state.status
}
