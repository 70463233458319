import { $themeBreakpoints } from '@themeConfig'
import { SEARCH_FILTER } from '../localStorageKeys'
import i18n from '../../libs/i18n'

const initialFilter = {
  ageRange: [25, 40],
  ageFrom: 25,
  ageTo: 40,
  country: 'TR',
  gender: 'female',
}

export default {
  namespaced: true,
  state: {
    campaigns: [],
    initialFilter: initialFilter,
    modalAddEmail: false,
    modalAddPhoneNumber: false,
    modalAddPhoneNumberShowDiamond: false,
    modalCall: false,
    modalCallSuggestion: false,
    modalCallUser: null,
    modalCallType: 'audio',
    modalDownloadMobileApp: false,
    modalPinCode: false,
    oopsModal: false,
    oopsModalText: '',
    reportReasonTypes: [
      i18n.t('Inappropriate Chat'),
      i18n.t('Inappropriate Photo'),
      i18n.t('Bad Behaviour'),
      i18n.t('Fake Account'),
      i18n.t('Other'),
    ],
    searchBar: false,
    searchFilter: localStorage.getItem(SEARCH_FILTER) ? JSON.parse(localStorage.getItem(SEARCH_FILTER)) : initialFilter,
    shallShowOverlay: false,
    verificationType: 'phone',
    windowWidth: 0,
  },
  getters: {
    campaign: state => {
      if (state.campaigns && state.campaigns.length > 0) return state.campaigns[0]

      return null
    },
    campaigns: state => state.campaigns,
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    modalAddEmail: state => state.modalAddEmail,
    modalAddPhoneNumber: state => state.modalAddPhoneNumber,
    modalAddPhoneNumberShowDiamond: state => state.modalAddPhoneNumberShowDiamond,
    modalCall: state => state.modalCall,
    modalCallSuggestion: state => state.modalCallSuggestion,
    modalCallUser: state => state.modalCallUser,
    modalCallType: state => state.modalCallType,
    modalDownloadMobileApp: state => state.modalDownloadMobileApp,
    modalPinCode: state => state.modalPinCode,
    oopsModal: state => state.oopsModal,
    oopsModalText: state => state.oopsModalText,
    reportReasonTypes: state => state.reportReasonTypes,
    searchBar: state => state.searchBar,
    searchFilter: state => {
      if (state.searchFilter) {
        return {
          ...state.searchFilter
        }
      }
      return state.initialFilter
    },
    verificationType: state => state.verificationType,
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    SET_CAMPAIGNS(state, val) {
      state.campaigns = val
    },
    SET_MODAL_ADD_EMAIL(state, val) {
      state.modalAddEmail = val
    },
    SET_MODAL_ADD_PHONE_NUMBER(state, val) {
      state.modalAddPhoneNumber = val
    },
    SET_MODAL_ADD_PHONE_NUMBER_SHOW_DIAMOND(state, val) {
      state.modalAddPhoneNumberShowDiamond = val
    },
    SET_MODAL_CALL(state, val) {
      state.modalCall = val
    },
    SET_MODAL_CALL_SUGGESTION(state, val) {
      state.modalCallSuggestion = val
    },
    SET_MODAL_CALL_USER(state, val) {
      state.modalCallUser = val
    },
    SET_MODAL_CALL_TYPE(state, val) {
      state.modalCallType = val
    },
    SET_MODAL_DOWNLOAD_MOBILE_APP(state, val) {
      state.modalDownloadMobileApp = val
    },
    SET_MODAL_PIN_CODE(state, val) {
      state.modalPinCode = val
    },
    SET_OOPS_MODAL(state, val) {
        state.oopsModal = val
    },
    SET_OOPS_MODAL_TEXT(state, val) {
        state.oopsModalText = val
    },
    SET_SEARCH_BAR(state, val) {
      state.searchBar = val
    },
    SET_SEARCH_FILTER(state, val) {
      state.searchFilter = val
      localStorage.setItem(SEARCH_FILTER, JSON.stringify(val))
    },
    SET_VERIFICATION_TYPE(state, val) {
      state.verificationType = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {
    setCampaigns({ commit }, val) {
      commit('SET_CAMPAIGNS', val)
    },
    setModalAddEmail({ commit }, val) {
      commit('SET_MODAL_ADD_EMAIL', val)
    },
    setModalAddPhoneNumber({ commit }, val) {
      commit('SET_MODAL_ADD_PHONE_NUMBER', val)
    },
    setModalAddPhoneNumberShowDiamond({ commit }, val) {
      commit('SET_MODAL_ADD_PHONE_NUMBER_SHOW_DIAMOND', val)
    },
    setModalCall({ commit }, val) {
      commit('SET_MODAL_CALL', val)
    },
    setModalCallSuggestion({ commit }, val) {
      commit('SET_MODAL_CALL_SUGGESTION', val)
    },
    setModalCallUser({ commit }, val) {
      commit('SET_MODAL_CALL_USER', val)
    },
    setModalCallType({ commit }, val) {
      commit('SET_MODAL_CALL_TYPE', val)
    },
    setModalDownloadMobileApp({ commit }, val) {
      commit('SET_MODAL_DOWNLOAD_MOBILE_APP', val)
    },
    setModalPinCode({ commit }, val) {
      commit('SET_MODAL_PIN_CODE', val)
    },
    setOopsModal({ commit }, val) {
        commit('SET_OOPS_MODAL', val)
    },
    setOopsModalText({ commit }, val) {
        commit('SET_OOPS_MODAL_TEXT', val)
    },
    setSearchBar({ commit }, val) {
      commit('SET_SEARCH_BAR', val)
    },
    setSearchFilter({ commit }, val) {
      commit('SET_SEARCH_FILTER', val)
    },
    setVerificationType({ commit }, val) {
      commit('SET_VERIFICATION_TYPE', val)
    }
  },
}
