import { KEY_USER } from '../localStorageKeys'

export function updateUser (state, user) {
  state.user = user
  localStorage.setItem(KEY_USER, JSON.stringify(user))
}

export function updateTempUser2 (state, tempUser2) {
  state.tempUser2 = tempUser2
}

export function setToken (state, token) {
  state.token = token
}

export function setAppleUser (state, user) {
  state.appleUser = user
}

export function setAuthType (state, type) {
  state.authType = type
}

export function setFacebookUser (state, user) {
  state.facebookUser = user
}

export function setGoogleUser (state, user) {
  state.googleUser = user
}

export function setTempUser (state, user) {
  state.tempUser = user
}

export function setSocialRegistering (state, status) {
  state.socialRegistering = status
}

export function logout (state) {
  state.user = null
  state.token = null
  state.appleUser = null
  state.facebookUser = null
  state.googleUser = null
  state.tempUser = null
  state.socialRegistering = false
}
