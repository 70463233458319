<template>
  <div class="page-contact">
    <b-overlay :show="loading" variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
    </b-overlay>
    <h2 class="settings-title mb-2">
      {{ $t('pages.contactUs.title') }}
    </h2>
    <div class="settings-content">
      <p>
        {{ $t('pages.contactUs.description') }}
      </p>
      <h3 class="settings-sub-title">
        ARASANA LTD.ŞTİ.
      </h3>
      <address>
        Dikilitas Mah. Hakki Yeten Cad. Selenium Plaza No:10/I K: 5&6 34349 Besiktas / İSTANBUL
      </address>
      <p>
        {{ $t('pages.contactUs.description2') }}
      </p>
    </div>

    <b-row>
      <b-col md="6" sm="12">
        <!-- form -->
        <validation-observer
            ref="form"
            #default="{invalid}">
          <b-form
              class="auth-login-form mt-2"
              @submit.prevent="send">
            <b-form-group
                label-for="email"
                :label="$t('Email')"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Email')"
                  vid="email"
                  rules="required|email"
              >
                <b-form-input
                    id="email"
                    v-model="email"
                    name="email"
                    :placeholder="$t('Email')"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
<!--            <b-form-group-->
<!--                label-for="phone"-->
<!--                :label="$t('Phone number')"-->
<!--            >-->
<!--              <validation-provider-->
<!--                #default="{ errors }"-->
<!--                :name="$t('Phone number')"-->
<!--                vid="phone"-->
<!--            >-->
<!--              <vue-tel-input-->
<!--                  v-model="phone"-->
<!--                  @open="onDropdownOpen(true)"-->
<!--                  @close="onDropdownOpen(false)"-->
<!--                  @country-changed="countryChanged"-->
<!--                  mode="auto"-->
<!--                  @validate="phoneValidate"-->
<!--                  :inputOptions="{placeholder: $t('Phone number')}"-->
<!--                  validCharactersOnly-->
<!--                  :styleClasses="['phone-input']"-->
<!--              >-->
<!--                <template v-slot:arrow-icon>-->
<!--                    <span class="font-small-3 mr-05">-->
<!--                      {{ country ? '+' + country.dialCode : '' }}-->
<!--                    </span>-->
<!--                  <font-awesome-icon v-if="open" icon="fa-solid fa-chevron-up"/>-->
<!--                  <font-awesome-icon v-else icon="fa-solid fa-chevron-down"/>-->
<!--                </template>-->
<!--              </vue-tel-input>-->
<!--              <small class="text-danger">{{ errors[0] }}</small>-->
<!--            </validation-provider>-->
<!--            </b-form-group>-->
            <b-form-group
                label-for="message"
                :label="$t('Your message')"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Your message')"
                  vid="message"
                  rules="required"
              >
                <b-form-textarea
                    id="message"
                    :disabled="loading"
                    v-model="message"
                    :placeholder="$t('Your message')"
                    rows="3"
                    max-rows="6"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
                class="mt-2"
                type="submit"
                variant="primary"
                block
                :disabled="loading || invalid"
            >
              <b-spinner v-if="loading" small></b-spinner>
              {{ $t('Send') }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BCardText, BCardTitle, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BOverlay, BRow, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { throwDefaultError, showNotify } from '@/helpers/helpers'

export default {
  components: {
    BButton,
    BCardText,
    BCardTitle,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BFormTextarea,
    BOverlay,
    BRow,
    BSpinner,

    ValidationProvider,
    ValidationObserver,
  },
  data () {
    return {
      country: null,
      email: '',
      loading: false,
      message: '',
      open: false,
      phone: '',
      valid: true,
      // valid: false,
    }
  },
  created () {
    this.email = this.$store.getters['auth/userEmail']
    this.phone = this.$store.getters['auth/userPhoneNumber']
  },
  computed: {
    phoneWithCountryCode () {
      return this.country && this.phoneWithCountryCode()
          ? '+' + this.country.dialCode + this.phone.replace(/ /g, "")
          : (this.phone ? this.phone.replace(/ /g, "") : '')
    }
  },
  methods: {
    countryChanged (country) {
      this.country = country
    },
    onDropdownOpen (open) {
      this.open = open
    },
    phoneValidate(status) {
      // this.valid = status ? status.valid : false
      // this.$refs.form.reset()
    },
    send() {
      if (!this.valid) {
        this.$refs.form.setErrors({
          phone: this.$t('Check your phone number')
        })
        return false
      }
      this.$refs.form.validate().then(success => {
        if (success) {
          // Formu gönder
          this.loading = true
          const data = {
            phoneNumber: this.phoneWithCountryCode,
            email: this.email,
            body: this.message,
          }
          useJwt.support(data)
              .then(response => {
                showNotify(this.$toast, this.$t('Your message has been sent'), 'success')
                this.email = this.$store.getters['auth/userEmail']
                this.message = ''
                this.phone = this.$store.getters['auth/userPhoneNumber']
                this.$refs.form.reset()
              })
              .catch(error => {
                throwDefaultError(this.$toast, error)
              })
              .finally(() => {
                this.loading = false
              })
        }
      })
    },
  }
}
</script>
