export function updateConnection (state, payload) {
  state.connection = payload
}
export function updateChatConnection (state, payload) {
  state.chatConnection = payload
}
export function updateConnecting (state, payload) {
  state.connecting = payload
}
export function updateConnected (state, payload) {
  state.connected = payload
}
